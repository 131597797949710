import Appsync from "../config"

const client = Appsync()

const gql = require("graphql-tag")

const query = gql`
  query fetchChats($consultId: String!) {
    getChats: allChatsWithIndex(consultId: $consultId) {
      createdAt
      senderRole
      senderId
      receiverId
      message
      url
    }
  }
`

const fetchConsultChats = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl
      .query({ query, variables, fetchPolicy: "no-cache" })
      .then(function logData(data) {
        return data
      })
  })
  return result
}

export default fetchConsultChats
