import React, { Component, Fragment } from "react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import ArrowRight from "@material-ui/icons/ArrowRight"
import ArrowLeft from "@material-ui/icons/ArrowLeft"
import isWithinRange from "date-fns/is_within_range"
import format from "date-fns/format"

import ModalDialog from "../../Modal/ModalDialog"

const ExpansionPanel = withStyles({
  root: {
    margin: "10px 0",
    width: "100%",
  },
  expanded: {
    width: "100%",
  },
})(MuiExpansionPanel)

const styles = theme => ({
  card: {
    minWidth: 275,
    maxHeight: 500,
    overflow: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    display: "flex",
    flexFlow: "column wrap",
  },
  column: {
    flexBasis: "33.33%",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardActionBtn: {
    marginLeft: "auto",
  },
  btn: {
    color: "#36c2ea",
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
  },
})

class PracticeDoctorsForm extends Component {
  state = {
    isModalOpen: false,
    selectedDoctor: null,
    licensedPracticeDocsInState: [],
  }

  componentDidMount = () => {
    const { practiceDetails, queryValues, isPracticeValid, values } = this.props

    if (queryValues.practiceId && isPracticeValid) {
      const { doctors: practiceDoctors } = practiceDetails

      const licensedPracticeDocsInState = practiceDoctors.filter(
        practiceDoctor => {
          return (
            practiceDoctor.enabled
            // &&
            // practiceDoctor.licensedStates.indexOf(values.state) !== -1
          )
        }
      )

      return this.setState({
        licensedPracticeDocsInState,
      })
    }
  }

  onContinuation = () => {
    const { validateForm, setFieldTouched, submitForm } = this.props

    validateForm().then(errors => {
      if (!errors.doctorId) {
        return submitForm()
      } else {
        return setFieldTouched("doctorId", true)
      }
    })
  }

  handleDoctorClick = async (e, doctor) => {
    e.preventDefault()

    this.setState(
      {
        selectedDoctor: doctor,
      },
      () => this.handleModalOpen()
    )
  }

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose = isSubmitting => {
    // Prevent user from closing modal while formik form is submitting.
    // This may not be necessary but done just to be safe.
    if (!isSubmitting) {
      return this.setState({ isModalOpen: false })
    }

    return this.setState({ isModalOpen: true })
  }

  handleDoctorConfirmation = async (doctor, setFieldValue, submitForm) => {
    const { id } = doctor

    setFieldValue("doctorId", id, false)

    setTimeout(() => submitForm(), 250) // Because sometimes the click event fires only on the second click under load.
  }

  render() {
    const {
      isSubmitting,
      classes,
      goBack,
      setFieldValue,
      submitForm,
      practiceDetails,
      values,
    } = this.props

    const {
      isModalOpen,
      selectedDoctor,
      licensedPracticeDocsInState,
    } = this.state

    return (
      <Fragment>
        <Typography gutterBottom>
          Please select a doctor to send your consult to:
        </Typography>
        {licensedPracticeDocsInState.length ? (
          <Card>
            <CardContent>
              {licensedPracticeDocsInState.map((doc, id) => {
                // Must use Fragment here to prevent panels from attaching to each other.
                return (
                  <Fragment key={doc.id}>
                    <ExpansionPanel defaultExpanded={id === 0}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {
                          doc.isAssistant === true ?
                            <Typography className={classes.heading}>
                              {doc.firstName} {doc.lastName} {" - "} {doc.assistantTitle}
                            </Typography>
                            :
                            <Typography className={classes.heading}>
                              Dr. {doc.firstName} {doc.lastName}
                            </Typography>
                        }
                      </ExpansionPanelSummary>
                      <Divider />
                      {/* <ExpansionPanelDetails className={classes.details}>
                        <Typography variant="body1">
                          Bio: {(doc.bio && doc.bio.trim()) || "None"}
                        </Typography>
                      
                      </ExpansionPanelDetails> */}
                      {/* <Divider /> */}
                      <ExpansionPanelActions>
                        <Button
                          type="submit"
                          onClick={e => this.handleDoctorClick(e, doc)}
                          size="small"
                          color="primary"
                          className={classes.btn}
                        >
                          Select
                        </Button>
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  </Fragment>
                )
              })}
            </CardContent>
            <CardActions>
              <Button
                type="button"
                onClick={() => goBack()}
                color="primary"
                disabled={isSubmitting}
                className={classes.btn}
              >
                <ArrowLeft /> Back
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Card className={classes.card}>
            <CardContent>
              <Typography gutterBottom>
                {`Sorry! There are no doctors in ${practiceDetails.name.trim() ||
                  "this"} practice who are licensed to operate in the state ${values.state
                  } which you selected.`}
              </Typography>
              <Typography>
                You can still submit your consult by clicking the submit consult
                button. We will assign your consult to a board-certified
                dermatologist part of the{" "}
                <a
                  href="https://www.dermio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`DERMIO`}
                </a>{" "}
                  team.
              </Typography>
            </CardContent>
            <CardActions className={classes.formActions}>
              <Button
                type="button"
                onClick={() => goBack()}
                color="primary"
                disabled={isSubmitting}
                className={classes.btn}
              >
                <ArrowLeft /> Back
              </Button>
              <Button
                type="button"
                onClick={() => this.onContinuation()}
                color="primary"
                disabled={isSubmitting}
                className={classes.btn}
              >
                Submit Consult <ArrowRight />
              </Button>
            </CardActions>
          </Card>
        )}

        <ModalDialog
          open={isModalOpen}
          handleClose={() => this.handleModalClose(isSubmitting)}
          handleConfirmation={() =>
            this.handleDoctorConfirmation(
              selectedDoctor,
              setFieldValue,
              submitForm
            )
          }
          alertMsg={
            selectedDoctor &&
              selectedDoctor.onVacation &&
              isWithinRange(
                new Date(),
                selectedDoctor.startVacationDate,
                selectedDoctor.endVacationDate
              )
              ? `${selectedDoctor.isAssistant === true ? '' : "Dr."} ${selectedDoctor.firstName} ${selectedDoctor.lastName
              } ${selectedDoctor.isAssistant === true ? selectedDoctor.assistantTitle : ""}  is not available from ${format(
                selectedDoctor.startVacationDate,
                "MMMM Do, YYYY - HH:mm:ss A"
              )} to ${format(
                selectedDoctor.endVacationDate,
                "MMMM Do, YYYY - HH:mm:ss A"
              )}. As such, you might not receive any response from him until he becomes available. Your consult will be created once you click submit. This action cannot be undone.`
              : `Are you sure you want to choose this doctor? Your consult will be created once you click submit. This action cannot be undone.`
          }
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(PracticeDoctorsForm)
