import React, { Component } from "react"

import Uppy from "@uppy/core"
import Webcam from "@uppy/webcam"
import { Dashboard } from "@uppy/react"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "@uppy/webcam/dist/style.css"

class ImageUploader extends Component {
  constructor(props) {
    super(props)

    this.uppy = new Uppy({
      id: "uppy",
      autoProceed: false,
      restrictions: {
        maxFileSize: 52428800,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [".txt", ".pdf", ".excel", ".word" ,".csv"],
      },
      meta: {},
    })

    this.uppy.on("upload", data => {
      const { handleUpload } = this.props

      const files = this.uppy.getFiles().slice()

      const sanitizeIssuePicFiles = files => {
        const sanitizedPics = files.map(file => {
          if (file.data.name) {
            // Image File (From Device)
            const issuePicFile = file.data

            return issuePicFile
          } else if (!file.data.name) {
            // Image Blob (From Webcam)
            const issuePicFile = file.data

            issuePicFile.name = file.name
            issuePicFile.lastModified = Date.now()
            issuePicFile.lastModifiedDate = new Date()

            return issuePicFile
          }
        })

        return sanitizedPics
      }

      return handleUpload(sanitizeIssuePicFiles(files))
    })
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  render() {
    const { goBack } = this.props

    const uploadBtnStyle = {
      background: "#36c2ea",
      color: "#fff",
      border: "0 none",
      padding: "6px 23px",
      marginBottom: "10px",
      marginLeft: "auto",
      borderRadius: "30px",
    }

    return (
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <button style={uploadBtnStyle} onClick={goBack}>
          Back
        </button>
        <Dashboard
          uppy={this.uppy}
          // plugins={["Webcam"]}
          metaFields={[{ id: "name", name: "Name", placeholder: "File name" }]}
          proudlyDisplayPoweredByUppy={false}
          width={"100%"}
          height={"450px"}
          note={
            "Supported formats: .txt, .pdf, .excel, word. Maximum file size allowed is 10MB."
          }
          metaFields={[]}
          locale={{
            strings: {
              dropPasteImport: `You can drop file here, paste, %{browse} or import from`,
              uploadXFiles: {
                0: "Upload",
                1: "Upload",
              },
            },
          }}
        />
      </div>
    )
  }
}

export default ImageUploader
