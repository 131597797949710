import gql from "graphql-tag"
import Appsync from "../config"

const client = Appsync()

const query = gql`
  query getAllPharmacies(
    $zipcode: String
    $name: String
    $pharmacy_NCPDPID: String
  ) {
    allPharmacies(
      zipcode: $zipcode
      name: $name
      pharmacy_NCPDPID: $pharmacy_NCPDPID
    ) {
      NCPDPID
      Zip
      Name
      Address1
      City
      State
    }
  }
`

const fetchPharmicesList = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "no-cache" })
  })
  return result
}

export default fetchPharmicesList
