import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query allVideoCalls($doctorId: String, $scheduledDateString: String) {
    allVideoCalls: allDoctorVideoCalls(
      filter: {
        scheduledDateString: { eq: $scheduledDateString }
        inviteStatus: { eq: "accepted" }
      }
      doctorId: $doctorId
    ) {
      items {
        id
        scheduledDateTime
      }
    }
  }
`

const fetchDoctorApointments = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl
      .query({ query, variables, fetchPolicy: "network-only" })
      .then(function logData(data) {
        return data
      })
  })
  return result
}

export default fetchDoctorApointments
