import React from "react"
import Storage from "@aws-amplify/storage"
import Analytics from "@aws-amplify/analytics"

import DoctorPatientChat from "./DoctorPatientChat"

import fetchDoctor from "../../queries/fetchDoctor"

class ChatContainer extends React.Component {
  state = {
    doctorAvatar:
      "https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg", // Change this to local image,
    doctor: {},
  }

  componentDidMount = async () => {
    const { consult } = this.props

    const { doctorId } = consult

    try {
      const {
        data: { doctor },
      } = await fetchDoctor({ doctorId })

      this.setState({
        doctor,
      })

      let file = doctor && doctor.file && JSON.parse(doctor.file)

      if (file && file.s3 && file.s3.key) {
        try {
          const doctorAvatar = await Storage.get(file.s3.key.split("/")[1])
          return this.setState({ doctorAvatar })
        } catch (e) {
          // console.error(e)
          // Analytics.record({
          //   name: "catchErrors",
          //   attributes: { errorWhile: "Storage.get(docProfilePicStr.s3.." },
          // })
        }
      }
    } catch (e) {
      // console.error(e)
    }
  }

  render() {
    const { consult } = this.props
    const { doctorAvatar, doctor } = this.state

    const initialFetchedMessages = consult.chats ? [...consult.chats] : []

    const { id: consultId, doctorId, status, patientname, archived } = consult

    let isConsultClosed =
      status === "closed_unpaid" || status === "closed_paid" ? true : false

    const chatDetails = {
      consultId,
      doctorId,
      initialFetchedMessages,
      doctorAvatar,
      isConsultClosed,
      patientname,
      doctor,
      archived,
    }

    return <DoctorPatientChat chatDetails={chatDetails} {...this.props} />
  }
}

export default ChatContainer
