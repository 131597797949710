
import Appsync from "../../config"
import gql from "graphql-tag"



const patientPortalClient = Appsync();

const updatePatientTimeQuery = gql`
mutation updatePatientTime($id: String!, $patientStatus: String ) {
  updatePatientTime(id: $id, patientStatus: $patientStatus) {
    id
    time
    patientStatus
  }
}
`

const patientActiveSubscriptionQuery = gql`
subscription onUpdateDoctorTime ($id: String!) {
  onUpdateDoctorTime (id: $id) {
    time
    masterStatus
  }
}
`

export const mutateStatus = (id, status) => {
  patientPortalClient
    .hydrated()
    .then(function (cl) {
      const mutation = cl.mutate({
        mutation: updatePatientTimeQuery,
        variables: { id: id, patientStatus: status },
        fetchPolicy: "no-cache",
      })
    })
}


export const subscribe = (videoCallData, actBasedOnMasterState) => {
patientPortalClient
.subscribe({ query: patientActiveSubscriptionQuery, variables: videoCallData })
.subscribe({
  next: data => {
    actBasedOnMasterState(data);
  },
  error: error => {
    console.error(error)
  },
});
}
