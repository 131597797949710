import React, { Component, Fragment } from "react"

import LinearProgress from "@material-ui/core/LinearProgress"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"

import PharmacyError from "./PharmacyError"
import ModalDialog from "../../Modal/ModalDialog"

const ExpansionPanel = withStyles({
  root: {
    margin: "10px 0",
    width: "100%",
  },
  expanded: {
    width: "100%",
  },
})(MuiExpansionPanel)

const styles = theme => ({
  card: {
    minWidth: 275,
    maxHeight: 500,
    overflow: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardActionBtn: {
    marginLeft: "auto",
    color: "#36c2ea",
  },
  btn: {
    color: "#36c2ea",
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

class PharmacySelectionForm extends Component {
  render() {
    const {
      values,
      searchPharmacyBy,
      setFieldValue,
      submitForm,
      classes,
      allPharmacies,
      errorType,
      loading,
      handlePharmacyClick,
      pharmaciesToLoadInitially: load,
      loadMorePharmacies,
      isModalOpen,
      handleModalClose,
      handleConfirmation,
      selectedPharmacy,
      isSubmitting,
    } = this.props

    if (loading) {
      return (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">
              Searching for pharmacies. Please wait......
            </Typography>
            <LinearProgress
              classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary,
              }}
            />
          </CardContent>
        </Card>
      )
    }

    if (errorType) {
      return (
        <PharmacyError
          errorType={errorType}
          values={values}
          searchPharmacyBy={searchPharmacyBy}
        />
      )
    }

    if (allPharmacies.length) {
      return (
        <Fragment>
          <Card className={classes.card}>
            <CardContent>
              {allPharmacies.slice(0, load).map((pharmacy, id) => {
                // Must use Fragment here to prevent panels from attaching to each other.
                return (
                  <Fragment key={pharmacy.NCPDPID}>
                    <ExpansionPanel defaultExpanded={id === 0}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div className={classes.column}>
                          <Typography className={classes.heading}>
                            {pharmacy.Name}
                          </Typography>
                        </div>
                        <div className={classes.column}>
                          <Typography className={classes.secondaryHeading}>
                            {pharmacy.Address1}
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div className={classes.column}>
                          <Typography variant="body1">
                            City: {pharmacy.City}
                          </Typography>
                          <Typography variant="body1">
                            State: {pharmacy.State}
                          </Typography>
                          <Typography variant="body1">
                            Zipcode: {pharmacy.Zip}
                          </Typography>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                      <ExpansionPanelActions>
                        <Button
                          type="submit"
                          onClick={e =>
                            handlePharmacyClick(e, pharmacy, values)
                          }
                          size="small"
                          color="primary"
                          className={classes.btn}
                        >
                          Select
                        </Button>
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  </Fragment>
                )
              })}
            </CardContent>
            {allPharmacies.length > 10 && (
              <CardActions>
                <Button
                  className={classes.cardActionBtn}
                  onClick={() => loadMorePharmacies()}
                  size="small"
                >
                  Load More
                </Button>
              </CardActions>
            )}
          </Card>
          <ModalDialog
            open={isModalOpen}
            handleClose={() => handleModalClose(isSubmitting)}
            handleConfirmation={() =>
              handleConfirmation(selectedPharmacy, setFieldValue, submitForm)
            }
            alertMsg="Are you sure you want to choose this pharmacy to send your prescription? Click on submit to proceed. This action cannot be undone."
          />
        </Fragment>
      )
    }

    return null
  }
}

export default withStyles(styles)(PharmacySelectionForm)
