import React, { Component, Fragment } from "react"
import { Dashboard } from "@uppy/react"

import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ArrowRight from "@material-ui/icons/ArrowRight"
import ArrowLeft from "@material-ui/icons/ArrowLeft"

const styles = theme => ({
  formActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  btn: {
    color: "#36c2ea",
  },
  imageUpload: {
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
})

class ImageSelectionForm extends Component {
  constructor(props) {
    super(props)

    this.props.uppy.on("file-added", file => {
      const { setFieldValue } = this.props

      const files = this.props.uppy.getFiles()
      // this.compressImage(files, setFieldValue)
      return setFieldValue("issuePics", [...files])
    })

    this.props.uppy.on("file-removed", file => {
      const { setFieldValue } = this.props

      const files = this.props.uppy.getFiles()
      // console.log("files ", files)
      return setFieldValue("issuePics", [...files])
    })
  }

  onContinuation = () => {
    const {
      goNext,
      validateForm,
      setFieldTouched,
      setFieldValue,
      setStatus,
      queryValues,
      isPracticeValid,
      submitForm,
      allConsults,
    } = this.props

    validateForm().then(async errors => {
      const { setFieldValue } = this.props

      if (!errors.issuePics) {
        setStatus({})
        if (queryValues.practiceId && isPracticeValid) {
          if (allConsults.length === 1 && !allConsults[0].url1) {
            setFieldValue("doctorId", allConsults[0].doctorId)
            return submitForm()
          }
          return goNext()
        }
        return submitForm()
      } else {
        setFieldTouched("issuePics", true)

        return setStatus({
          errorType: "Form Error",
        })
      }
    })
  }

  render() {
    const {
      createConsultFor,
      queryValues,
      isPracticeValid,
      errors,
      isSubmitting,
      classes,
      goBack,
      uppy,
      allConsults,
    } = this.props

    let btnWithIcon = (
      <Button
        type="button"
        onClick={() => this.onContinuation()}
        disabled={isSubmitting}
        color="primary"
        className={classes.btn}
      >
        Submit Consult <ArrowRight />
      </Button>
    )

    if (queryValues.practiceId && isPracticeValid) {
      btnWithIcon = (
        <Button
          type="button"
          onClick={() => this.onContinuation()}
          disabled={isSubmitting}
          color="primary"
          className={classes.btn}
        >
          Continue <ArrowRight />
        </Button>
      )

      if (allConsults.length === 1 && !allConsults[0].url1) {
        btnWithIcon = (
          <Button
            type="button"
            onClick={() => this.onContinuation()}
            disabled={isSubmitting}
            color="primary"
            className={classes.btn}
            size="large"
          >
            Update Consult <ArrowRight />
          </Button>
        )
      }
    }

    return (
      <Fragment>
        <Paper elevation={2} className={classes.imageUpload}>
          <Dashboard
            uppy={uppy}
            plugins={["Webcam"]}
            metaFields={[
              { id: "name", name: "Name", placeholder: "File name" },
            ]}
            proudlyDisplayPoweredByUppy={false}
            hideUploadButton={true}
            width={"100%"}
            note={
              "You can select upto 4 images to upload. Supported formats: .jpg, .gif, .png, .jpeg, tiff. Maximum Image size allowed is 10MB."
            }
            metaFields={[]}
            locale={{
              strings: {
                dropPasteImport: `Please select atleast one image of ${
                  createConsultFor === "me" ? "your" : "their"
                } skin issue. You can drop images here, paste, %{browse} or import from`,
              },
            }}
          />
        </Paper>
        {errors.issuePics && (
          <Typography variant="caption" color="error">
            {errors.issuePics}
          </Typography>
        )}
        <Divider className={classes.divider} />
        <div className={classes.formActions}>
          <Button
            type="button"
            onClick={() => goBack()}
            disabled={isSubmitting}
            color="primary"
            className={classes.btn}
          >
            <ArrowLeft /> Back
          </Button>
          {btnWithIcon}
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ImageSelectionForm)
