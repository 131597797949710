import React from "react"
// import transformPhone from "../utils/transformPhone"
import { Auth } from "aws-amplify"
import XRegExp from "xregexp"
import { SignUp } from "aws-amplify-react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

import transformPhone from "../../utils/transformPhone"
// import capitalizeName from "../../utils/capitalizeName"

import dermioLogo from "../../assets/img/dermio-logo.svg"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 250,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey["A700"],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    // marginTop: "1em",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "0 1em 1em 1em",
  },
  linkLabel: {
    marginTop: 15,
  },
  policyContainer: {
    marginBottom: 10
  },
  policyInput: { fontSize: 16, paddingRight: 10 },
  policyLabel: {
    margin: 3
  }
})

class CustomSignUp extends SignUp {
  state = {
    errors: {
      name: "",
      number: "",
      signUp: "",
    },
    hasChecked: false
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: {
        name: "",
        number: "",
        signUp: "",
      },
    })
  }

  componentDidMount = () => { }

  handleSubmit = e => {
    e.preventDefault()

    const PASSWORD = "mD@19911"

    let { patientName, phoneNumber } = this.inputs

    const regexp = XRegExp(
      "^[\\p{L}]([-.']?[ ]?[\\p{L}]+)*( [\\p{L}]([-.']?[ ]?[\\p{L}]+)*)+$"
    )
    const acceptedPhoneNumFormat = /^((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$/g

    let isValidName = regexp.test(patientName.trim())

    const isValidPhoneNumber = acceptedPhoneNumFormat.test(phoneNumber.trim())

    if (!isValidName && !isValidPhoneNumber) {
      return this.setState({
        errors: {
          name: "Please enter a valid full name",
          number: "Invalid phone number format",
          signUp: "",
        },
      })
    }

    if (!isValidName) {
      return this.setState({
        errors: {
          name: "Please enter a valid full name",
          number: "",
          signUp: "",
        },
      })
    }

    if (!isValidPhoneNumber) {
      return this.setState({
        errors: {
          name: "",
          number: "Invalid phone number format",
          signUp: "",
        },
      })
    }

    let phNumber = transformPhone(phoneNumber)

    // let capitalizedPhName = capitalizeName(patientName)

    // console.log("capitalizedPhName", capitalizedPhName.trim())

    Auth.signUp({
      username: phNumber,
      password: PASSWORD,
      attributes: {
        name: patientName.trim(),
        phone_number: phNumber,
      },
    })
      .then(user => {
        this.changeState("confirmSignUp", user)
        this.setState({
          errors: {
            name: "",
            number: "",
            signUp: "",
          },
        })
      })
      .catch(err => {
        console.error(err)
        if (err.code === "InvalidParameterException") {
          this.setState({
            errors: {
              name: "",
              number: "",
              signUp: "Something went wrong. Please try again!",
            },
          })
        }

        if (err.code === "NetworkError") {
          this.setState({
            errors: {
              name: "",
              number: "",
              signUp: "Something went wrong. Please try again!",
            },
          })
        }

        if (err.code === "UsernameExistsException") {
          this.setState({
            errors: {
              name: "",
              number: "",
              signUp:
                "Your phone number is already registered. Please sign in!",
            },
          })
        }
      })
  }

  render() {
    const { classes, authState, onStateChange } = this.props
    return (
      authState === "signUp" && (
        <div className={classes.main}>
          <Card className={classes.card} id="signUp">
            <div
              style={{
                margin: "1em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
              <Typography variant="caption" style={{ color: "#25c3ea" }}>
                (Online dermatology consultation)
              </Typography>
            </div>
            {/* <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            <div className={classes.avatar} style={{color: "#25c3ea"}}>
              <Typography variant="caption" color="textPrimary">
                (Online dermatology consultation)
              </Typography>
            </div> */}
            <form onSubmit={this.handleSubmit}>
              <div className={classes.hint}>CREATE A NEW ACCOUNT</div>
              {this.state.errors.name && (
                <div className={classes.error}>{this.state.errors.name}</div>
              )}
              {this.state.errors.number && (
                <div className={classes.error}>{this.state.errors.number}</div>
              )}
              {this.state.errors.signUp && (
                <div className={classes.error}>{this.state.errors.signUp}</div>
              )}
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="patientName"
                    name="patientName"
                    label="Full Name"
                    margin="normal"
                    type="text"
                    helperText="What is your full name? (eg: John Doe)"
                    fullWidth={true}
                    required
                    error={
                      this.state.errors.name || this.state.errors.signUp
                        ? true
                        : false
                    }
                    onChange={this.handleChange}
                  />
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    margin="normal"
                    type="text"
                    helperText="10 digit phone number (eg: 3122063653)"
                    fullWidth={true}
                    required
                    error={
                      this.state.errors.number || this.state.errors.signUp
                        ? true
                        : false
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <div className={classes.policyContainer}>
                  <input
                    type="checkbox"
                    className={classes.policyInput}
                    value={this.state.hasChecked}
                    onChange={(event) => this.setState({ hasChecked: event.target.checked })}
                  />
                  <label className={classes.policyLabel}>By agreeing this you are allowing Dermio to send you one-time password and consultation related sms to your phone number.</label>
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  onChange={this.handleInputChange}
                  disabled={!this.state.hasChecked}
                >
                  CREATE ACCOUNT
                </Button>
                <Link
                  component="button"
                  variant="body1"
                  color="secondary"
                  underline="always"
                  className={classes.linkLabel}
                  onClick={() => {
                    onStateChange("signIn", {})
                    this.setState({
                      errors: {
                        name: "",
                        number: "",
                        signUp: "",
                      },
                    })
                  }}
                >
                  Already have an account? Login here.
                </Link>
                {/* <Typography variant="caption" color="primary">
                  Have an account?{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    fullWidth
                    onClick={() => {
                      onStateChange("signIn", {})
                      this.setState({
                        errors: {
                          name: "",
                          number: "",
                          signUp: "",
                        },
                      })
                    }}
                  >
                    Sign In
                  </Button>
                </Typography> */}
              </CardActions>
            </form>
          </Card>
        </div>
      )
    )
  }
}

export default withStyles(styles)(CustomSignUp)
