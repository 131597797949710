import React, { Component } from "react"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Slide from "@material-ui/core/Slide"

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = theme => ({
  btn: {
    color: "#36c2ea",
  },
})

class AlertDialogSlide extends Component {
  render() {
    const {
      classes,
      handleClose,
      handleConfirmation,
      alertMsg,
      additionalContent,
      open,
    } = this.props

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {alertMsg}
            <br/>
            <h4> {additionalContent} </h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="primary"
            className={classes.btn}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmation()}
            color="primary"
            className={classes.btn}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AlertDialogSlide)
