export default phone => {
  console.log(phone)
  let phone_number = phone.trim().replace(/[^0-9\+]/g, "")

  //   US NUMBERS ONLY
  if (phone_number.length === 10 && !/^(\+1)/.test(phone_number)) {
    phone_number = `+1${phone_number}`
  } else if (phone_number.length === 11 && /^1/.test(phone_number)) {
    phone_number = `+${phone_number}`
  }

  return phone_number
}
