import React, { useState, useEffect } from "react"

import { Card, CardContent } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import { Typography } from "@material-ui/core"
import moment from "moment"

import fetchVideoCallDetails from "../../queries/fetchVideoCallDetails"
import fetchDoctor from "../../queries/fetchDoctor"

export default function(props) {
  // console.log(props)

  useEffect(() => {
    const { match } = props
    fetchVideoCallDetails(match.params.consultId)
      .then(res => {
        // console.log(res)
        const {
          data: { VideoCall },
        } = res

        let videocall = VideoCall
        if (videocall && videocall.doctorId) {
          fetchDoctor({ doctorId: videocall.doctorId })
            .then(response => {
              const { data } = response
              if (data && data.doctor && data.doctor.id) {
                videocall.doctorName = data.doctor.name
                setData(videocall)
              }
            })
            .catch(err => console.log(err))
        }
        setData(videocall)
      })
      .catch(err => console.log(err))
  }, [])

  const [data, setData] = useState(null)
  // console.log("data", data)
  return (
    <Container maxWidth="sm">
      {data ? (
        data.callStatus == "completed" ? (
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                <h1>Your consult is completed.</h1>
              </Typography>
              <br />
              <Typography color="primary">
                Doctor Name :{" "}
                <Typography color="textSecondary">{data.doctorName}</Typography>
              </Typography>
              <br />
              <Typography color="primary">
                Date :{" "}
                <Typography color="textSecondary">
                  {data.scheduledDateString}
                </Typography>
              </Typography>
              <br />
              <Typography color="primary">
                Start time :{" "}
                <Typography color="textSecondary">
                  {moment(data.startTime).format("LT")}
                </Typography>
              </Typography>
              <br />
              <Typography color="primary">
                End time :{" "}
                <Typography color="textSecondary">
                  {moment(data.endTime).format("LT")}
                </Typography>
              </Typography>
              <br />
              <Typography color="primary">
                Duration :{" "}
                <Typography color="textSecondary">
                  {moment(
                    data.endTime || props.consultRecordObj.record.lastActive
                  ).diff(moment(data.startTime), "minutes")}{" "}
                  minutes
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        ) : (
            <Card>
              <CardContent>
                <Typography>You disconnected the call.</Typography>
              </CardContent>
            </Card>
          )
      ) : (
          <h1> Loading ... </h1>
        )}
    </Container>
  )
}
