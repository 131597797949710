import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query fetchPractice($id: ID!) {
    practiceDetails: Practice(id: $id) {
      id
      name
      website
      consultationFee
      disabledPrescription
      doctors {
        id
        name
        firstName
        lastName
        phone
        email
        bio
        enabled
        file
        licensedStates
        onVacation
        startVacationDate
        endVacationDate
        isAssistant
	      assistantTitle
      }
      file {
        key
        bucket
      }
    }
  }
`

const fetchPractice = async practiceId => {
  const variables = { id: practiceId }

  const practiceDetails = await client.hydrated().then(function (cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return practiceDetails
}

export default fetchPractice
