import gql from "graphql-tag"
import Appsync from "../config"
import encryptConsult from "../utils/encryptConsult"

const client = Appsync()

const mutationUpdateConsults = gql`
  mutation Create($pharmacy_NCPDPID: String!, $id: ID!, $patientDob: String!, $pharmacy_Name: String!) {
    updateConsult(
      pharmacy_Name: $pharmacy_Name
      pharmacy_NCPDPID: $pharmacy_NCPDPID
      id: $id
      patientDob: $patientDob
    ) {
      id
      pharmacy_NCPDPID
      patientDob
      unlicensedDocName
      pharmacy_Name
    }
  }
`

const updateConsults = async variables => {
  const encryptedConsultDetails = await encryptConsult(variables)

  const updatedConsult = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateConsults,
      variables: encryptedConsultDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsult
}

export default updateConsults
