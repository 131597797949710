import React, { useState, useEffect, useRef } from "react"

import { MuiThemeProvider } from "material-ui"

import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import { Button } from "@material-ui/core"
import Image from "../../assets/img/wait.svg"
import Container from "@material-ui/core/Container"
import CallEndIcon from "@material-ui/icons/CallEnd"
import { Card, CardContent } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import VideoCallIcon from "@material-ui/icons/VideoCall"
import Modal from "@material-ui/core/Modal"
import VideocamOffIcon from "@material-ui/icons/VideocamOff"
import MicOffIcon from "@material-ui/icons/MicOff"

import moment from "moment"

import "./VideoStyles.css"

import fetchVideoCallDetails from "../../queries/fetchVideoCallDetails"
import ModalDialog from "../../components/Modal/ModalDialog"
import getCurrentTimezoneName from "../../utils/getCurrentTimezoneName"
import KinesisConfig from "./KinesisConfig"

import { mutateStatus, subscribe } from "./subscription"

let patientStatus = "initial"
let videoCallId
let doctorId
let viewer = {}
export default function (props) {
  const doctorVideo = useRef(null)
  const [date, setDate] = useState(null)
  // const [channelName, setChannelName] = useState(null);
  // const [viewerState, setViewerState] = useState("initial");
  const [showJoinCallButton, setShowJoinCallButton] = useState(true)
  const [videoWindow, setVideoWindow] = useState(true)
  const [patientConnected, setPatientConnected] = useState(false)
  const [doctorConnected, setDoctorConnected] = useState(false)
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [VideoCallDetails, setVideoCallDetails] = useState(null)
  const [showFullScreen, setfullScreen] = useState(false)
  const isfullScreen = document.fullscreen
  const [isMute, setMute] = useState(false)
  const [isVidMute, setVidMute] = useState(false)
  const [connecting, setConnecting] = useState(false);

  useEffect(() => {
    if (viewer.signalingClient) {
      viewer.signalingClient.close()
      window.location.reload();
    }
    const { match } = props
    fetchVideoCallDetails(match.params.consultId)
      .then(async res => {
        let id = res.data.VideoCall.id
        videoCallId = res.data.VideoCall.id
        doctorId = res.data.VideoCall.doctorId
        if (
          res.data.VideoCall.callStatus === "completed" ||
          res.data.VideoCall.callStatus === "incompleted"
        ) {
          props.history.push(`/patient/consults/${id}/videoCallHistory`)
        }
        setDate(moment(res.data.VideoCall.scheduledDateTime))
        setVideoCallDetails(res)
        const videoCallData = { id: id }
        await subscribe(videoCallData, actBasedOnMasterState)
        mutateStatus(id, patientStatus)
      })
      .catch(err => console.log(err))
  }, [])

  async function actBasedOnMasterState(data) {
    let master = data.data.onUpdateDoctorTime.masterStatus
    console.log("master", master, "patient", patientStatus)

    // if( master == "initial" &&  patientStatus == "inital" ) {

    // }
    if (master == "local" && patientStatus == "initial") {

    }
    if (master == "initial" && patientStatus == "ready") {
      setConnecting(false);
      getLocalStream()
    }

    if (master === "local" && patientStatus === "local") {
      console.log(videoCallId)
      patientStatus = "ready"
      mutateStatus(videoCallId, "ready")
    }
    if (master == "ready" && patientStatus == "ready") {
      setConnecting(true);
      await patientScreen()
    }
    if (
      (master == "local" || master == "initial") &&
      patientStatus == "connected"
    ) {
      setConnecting(false);
      getLocalStream()
    }
    if (master == "ready" && patientStatus == "connected") {
    }
    // if (master == "connected" && patientStatus == "connected") {
    //   mutateStatus('ecb3f63e-ee53-435a-8657-6fafffde0d35', 'connected');
    // }
    if (master == "connected" && patientStatus == "disconnected") {
    }
    if (master == "disconnected" && patientStatus == "connected") {
      setConnecting(false);
      alert("Doctor completed the call")
      onClose()
    }
  }

  async function getLocalStream() {
    setShowJoinCallButton(false)
    setDoctorConnected(false)
    if (viewer.signalingClient) {
      viewer.signalingClient.close()
    }
    try {
      if (doctorVideo && doctorVideo.current && doctorVideo.current.srcObject) {
        viewer.remoteStream = null
        doctorVideo.current.srcObject = null
      }
      const resolution = { width: 640, height: 480 }
      const constraints = {
        video: resolution,
        audio: true,
      }
      let localStream = await navigator.mediaDevices.getUserMedia(constraints)
      setPatientConnected(true)
      document.getElementById("patient").srcObject = localStream
      patientStatus = "local"
      mutateStatus(videoCallId, "local")
    } catch (e) {
      alert('We coult not access your webcam, Please check your webcam settings or try from other devices');
    }
  }

  async function onClose() {
    mutateStatus(videoCallId, "disconnected")
    if (viewer.signalingClient) {
      viewer.signalingClient.close()
      viewer.signalingClient = null
    }

    if (viewer.peerConnection) {
      viewer.peerConnection.close()
      viewer.peerConnection = null
    }

    if (viewer.localStream) {
      viewer.localStream.getTracks().forEach(track => track.stop())
      viewer.localStream = null
    }
    if (viewer.remoteStream) {
      viewer.remoteStream.getTracks().forEach(track => track.stop())
      viewer.remoteStream = null
    }

    if (viewer.peerConnectionStatsInterval) {
      clearInterval(viewer.peerConnectionStatsInterval)
      viewer.peerConnectionStatsInterval = null
    }

    if (viewer.localView) {
      viewer.localView.srcObject = null
    }

    if (viewer.remoteView) {
      viewer.remoteView.srcObject = null
    }
    setTimeout(() => {
      // let id = VideoCallDetails.data.VideoCall.id
      props.history.push(`/patient/consults/${videoCallId}/videoCallHistory`)
      window.location.reload()
    }, 2000)
  }

  async function patientScreen() {
    try {
      viewer = await KinesisConfig(doctorId)
      viewer.signalingClient.open()
      viewer.signalingClient.on("open", async () => {
        try {
          const resolution = { width: 640, height: 480 }
          const constraints = {
            video: resolution,
            audio: true,
          }
          viewer.localStream = await navigator.mediaDevices.getUserMedia(
            constraints
          )
          viewer.localStream
            .getTracks()
            .forEach(track =>
              viewer.peerConnection.addTrack(track, viewer.localStream)
            )
          let patientVideoElem = document.getElementById("patient")
          patientVideoElem.srcObject = viewer.localStream
          patientVideoElem.muted = true
        } catch (e) {
          alert('We coult not access your webcam, Please check your webcam settings or try from other devices');
        }

        await viewer.peerConnection.setLocalDescription(
          await viewer.peerConnection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
        )
        viewer.signalingClient.sendSdpOffer(
          viewer.peerConnection.localDescription
        )
      })
      viewer.signalingClient.on("sdpAnswer", async answer => {
        console.log("sdp answer")
        await viewer.peerConnection.setRemoteDescription(answer)
      })

      viewer.signalingClient.on("iceCandidate", candidate => {
        viewer.peerConnection.addIceCandidate(candidate)
      })

      viewer.peerConnection.addEventListener(
        "icecandidate",
        ({ candidate }) => {
          if (candidate) {
            viewer.signalingClient.sendIceCandidate(candidate)
          }
          // else {
          //   viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription)
          // }
        }
      )

      viewer.peerConnection.addEventListener("track", event => {
        setDoctorConnected(true)
        let doctorVideoId = document.getElementById("doctor")
        if (!doctorVideoId.srcObject) {
          viewer.remoteStream = event.streams[0]
          doctorVideoId.srcObject = viewer.remoteStream
          patientStatus = "connected"
          mutateStatus(videoCallId, "connected")
        }
      })

      viewer.signalingClient.on("close", () => {
        // setViewerState("disconnected")
        console.log("Patient disconnected from signaling channel")
      })

      viewer.signalingClient.on("error", error => {
        // setViewerState("error")
        window.location.reload();
        console.error("Signaling client error: ", error)
      })
    } catch (e) {
      try {
        let logData = {
          data: JSON.stringify(e),
          consultName: "Video-call error",
        }
        fetch(
          process.env.REACT_APP_LOG_URL,
          {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            body: JSON.stringify(logData), // body data type must match "Content-Type" header
          }
        )
          .then(response => {
            return response.text()
          })
          .then(result => { console.log(result) })
          .catch((err) => {
            // console.log(err)
          })
      } catch (err) {
        console.log(err)
      }
    }
  }

  function openFullscreen() {
    const elem = document.getElementById("doctorMedia")
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen()
    }
  }

  function closeFullscreen() {
    if (isfullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } else {
      setfullScreen(!showFullScreen)
    }
  }

  const fullScreen =
    showFullScreen && isfullScreen ? (
      <div id="full-window" onClick={closeFullscreen}>
        <Typography color="secondary" gutterBottom>
          Exit fullScreen
        </Typography>
      </div>
    ) : (
        <div id="full-window" onClick={openFullscreen}>
          <Typography color="secondary" gutterBottom>
            Fullscreen
        </Typography>{" "}
        </div>
      )

  return (
    <MuiThemeProvider>
      {date ? (
        doctorConnected ? null : showJoinCallButton ? (
          date >= moment() ? (
            <Container
              fixed
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card style={{ width: "fit-content" }}>
                <h1 style={{ padding: 10 }}>
                  Your consult time is scheduled at {date.format("LLLL")} -{" "}
                  {getCurrentTimezoneName()}.
                </h1>
                <CardContent>
                  <Typography color="secondary" gutterBottom>
                    <h3>Join the consult on time.</h3>
                  </Typography>
                  <br />
                  <Typography color="primary">
                    Note:{" "}
                    <ul>
                      <li>
                        <Typography color="textSecondary">
                          Make sure your browser camera & microphone are
                          enabled.
                        </Typography>
                      </li>
                      <li>
                        <Typography color="textSecondary">
                          Please use chrome browser in PC, mac and android. Use safari browser in iOS devices (iPhone and iPad).
                        </Typography>
                      </li>
                    </ul>
                  </Typography>
                  <br />
                </CardContent>
              </Card>
            </Container>
          ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card style={{ width: "fit-content" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CardContent>
                      <br />
                      <Typography color="primary">
                        Note:{" "}
                        <ul>
                          <li>
                            <Typography color="textSecondary">
                              Make sure your browser camera & microphone are
                              enabled.
                          </Typography>
                          </li>
                          <li>
                            <Typography color="textSecondary">
                              Please use chrome browser in PC, mac and android. Use safari browser in iOS devices (iPhone and iPad).
                          </Typography>
                          </li>
                          <li>
                            <Typography color="textSecondary">
                              Once you join the call, if you face any issues,
                              please refresh and join the call again.
                          </Typography>
                          </li>
                        </ul>
                      </Typography>
                      <br />
                    </CardContent>
                    <br />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "2.5rem",
                    }}
                  >
                    <Button variant="outlined" onClick={getLocalStream}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#3fcff3",
                        }}
                      >
                        <VideoCallIcon fontSize="large" id="hangup" />
                        Join call
                    </Typography>
                    </Button>
                  </div>
                </Card>
              </div>
            )
        ) : patientConnected ? null : (
          <div style={{ padding: "15%" }}>
            <Card>
              <Button disabled>
                {" "}
                <Typography
                  variant="caption"
                  style={{
                    color: "#3fcff3",
                  }}
                >
                  <h3 style={{ fontWeight: "bold" }}>Joining call...</h3>
                  <CircularProgress size={20} thickness={3} disableShrink />
                </Typography>
              </Button>
              <Typography
                style={{ color: "rgb(63, 207, 243)", padding: "10px" }}
              >
                Looks like your doctor has not joined yet, please wait for a
                moment or send a message via chat.
              </Typography>
              <Typography
                style={{
                  color: "rgb(63, 207, 243)",
                  padding: "10px",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                Once you join the call, if you face any issues, please refresh
                and join the call again.
              </Typography>
            </Card>
          </div>
        )
      ) : (
          <h1>Loading ....</h1>
        )}

      <div id="doctorMedia">
        <a onClick={() => setVideoWindow(!videoWindow)}>
          {!doctorConnected ? (
            patientConnected ? (
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <img
                  className={
                    videoWindow ? "mainWindowImage" : "smallWindowImage"
                  }
                  src={Image}
                />
                <Typography
                  variant="caption"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "40%",
                    color: "#3fcff3",
                  }}
                >
                  {connecting ? <h3>Connecting call ...</h3> : <h3> Waiting for doctor to join the call ...</h3>}
                  <CircularProgress size={30} thickness={4} />
                </Typography>
              </div>
            ) : null
          ) : (
              <video
                playsInline
                ref={doctorVideo}
                className={videoWindow ? "mainWindow" : "smallWindow"}
                id="doctor"
                autoPlay
              />
            )}
        </a>
        <a onClick={() => setVideoWindow(!videoWindow)}>
          <video
            playsInline
            // muted
            className={videoWindow ? "smallWindow" : "mainWindow"}
            id="patient"
            autoPlay
            playsInline
            muted
          />
        </a>

        {doctorConnected ? (
          <BottomNavigation className="navBar">
            <BottomNavigationAction
              icon={fullScreen}
              onClick={() => setfullScreen(!showFullScreen)}
            />
            <BottomNavigationAction
              icon={
                <VideocamOffIcon
                  id="hangup"
                  label="video off"
                  fontSize="large"
                  color={isVidMute ? "error" : "gray"}
                />
              }
              onClick={() => {
                viewer.localStream.getVideoTracks()[0].enabled = isVidMute
                setVidMute(!isVidMute)
              }}
            />
            <BottomNavigationAction
              icon={
                <MicOffIcon
                  id="hangup"
                  label="mic off"
                  fontSize="large"
                  color={isMute ? "error" : "gray"}
                />
              }
              onClick={() => {
                viewer.localStream.getAudioTracks()[0].enabled = isMute
                setMute(!isMute)
              }}
            />
            <BottomNavigationAction
              icon={
                <CallEndIcon
                  id="hangup"
                  label="Leave Room"
                  fontSize="large"
                  color="error"
                  onClick={() => setOpenWarningModal(true)}
                />
              }
            />
          </BottomNavigation>
        ) : null}

        <ModalDialog
          open={openWarningModal}
          handleClose={() => setOpenWarningModal(false)}
          handleConfirmation={onClose}
          alertMsg={`Are you sure, you want to end this call?`}
        />
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={openErrorModal}
          onClose={() => {
            setOpenErrorModal(false)
            props.history.push(`/`)
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid #000",
            }}
          >
            <Typography color="textSecondary" gutterBottom>
              <h3 style={{ color: "red" }}>Something went wrong!</h3>
              <h4>Make sure your system date, time is correct.</h4>
              <h4>
                Please use chrome browser in PC, mac and android. Use safari browser in iOS devices (iPhone and iPad).
              </h4>
            </Typography>
            &nbsp;
            <span>
              &nbsp;
              <Button
                style={{ backgroundColor: "green" }}
                fontSize="small"
                onClick={() => props.history.push(`/`)}
              >
                Ok!
              </Button>
            </span>
          </div>
        </Modal>
      </div>
    </MuiThemeProvider>
  )
}
