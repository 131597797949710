import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query fetchConsult($patientId: String!) {
    myConsult(patientId: $patientId) {
      id
      email
      consultFor
      cardToken
      cardDeclined
      doctorId
      phone_number
      patientId
      status
      state
      pharmacy_NCPDPID
      patientDob
      allergy
      age
      patientname
      alchohol
      cigrattes_per_day
      drinks_per_week
      gender
      medical_issues
      currentMedicalIssue
      medication
      surgeries
      createdAt
      url1
      url2
      url3
      url4
      email
      chats {
        createdAt
        senderRole
        senderId
        receiverId
        message
        url
      }
      hasPdf
      archived
      isVideoCallConsult
      practiceId
      insuranceFile1 {
        key
      }
      insuranceFile2 {
        key
      }
      drivingLicenseFile {
        key
      }
      insuranceUrl1
      insuranceUrl2
      drivingLicenseUrl
      source
    }
  }
`

const fetchMyConsult = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl
      .query({ query, variables, fetchPolicy: "network-only" })
      .then(function logData(data) {
        return data
      })
  })
  return result
}

export default fetchMyConsult
