import React, { Component } from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { withStyles } from "@material-ui/core/styles"

import ChatContainer from "../../components/Chat/ChatContainer"

const styles = theme => ({
  card: {
    minWidth: 275,
    flex: 1,
    // margin: '10px',
    overflow: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    flexFlow: "row wrap",
    width: "100%",
    height: "100vh",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardActionBtn: {
    marginLeft: "auto",
  },
})

class ConsultShow extends Component {
  componentDidMount = async () => {
    const observer = new MutationObserver(this.scrollToBottom)
    const config = { attributes: true, childList: true }
    observer.observe(this.root, config)
  }

  scrollToBottom = () => {
    this.root.scrollTop = this.root.scrollHeight
  }

  render() {
    const { consult, classes } = this.props

    return (
      <div className={classes.root} ref={el => (this.root = el)}>
        <Card className={classes.card}>
          <CardContent>
            <ChatContainer consult={consult} {...this.props} />
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default withStyles(styles)(ConsultShow)
