import React, { Component } from "react"
import { Authenticator } from "aws-amplify-react"
import configureAmplify from "./configureAmplify"

import Patient from "./layouts/Patient"
import {
  CustomSignUp,
  CustomSignIn,
  CustomConfirmSignIn,
  CustomConfirmSignUp,
} from "./components/Authentication"

configureAmplify()

class App extends Component {
  render() {
    return this.props.authState === "signedIn" ? (
      <Patient {...this.props} />
    ) : null
  }
}

class AppWithAuth extends Component {
  state = {
    showSignUp: false,
  }

  render() {
    return (
      <div>
        <Authenticator hideDefault={true} authState="signUp">
          <CustomSignIn />
          <CustomConfirmSignIn />
          <CustomSignUp override={"SignUp"} />
          <CustomConfirmSignUp />
          <App {...this.props} />
        </Authenticator>
      </div>
    )
  }
}

export default AppWithAuth
