import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query allAvailabilities(
  $doctorId: String
  ){
    allAvailabilities(filter:{
      doctorId: {eq: $doctorId},
      isActive:{eq: true}
    }){
      items{
        id
        doctorId
        day
        slots{
          from
          to
        }
        isActive
      }
    }
  }
`

const fetchDoctorAllAvailabilities = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl
      .query({ query, variables, fetchPolicy: "network-only" })
      .then(function logData(data) {
        return data
      })
  })
  return result
}

export default fetchDoctorAllAvailabilities
