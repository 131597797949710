import React, { Component } from "react"
import { Redirect } from "react-router-dom"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import ConsultShowPage from "../../views/ConsultShow/ConsultShow"

import ModalDialog from "../Modal/ModalDialog"
import fetchDoctor from "../../queries/fetchDoctor"
import fetchPracticeDetails from "../../queries/fetchPracticeDetails"

class Consult extends Component {
  state = {
    openWarningModal: false,
    scheduleCallConsultId: null,
    doctorFee: 10,
    showScheduleVideocall: true,
    consultFee: 59,
    docDisableVideocall: false,
  }

  componentDidMount = async () => {
    let singleConsult = this.props.allConsults.find(
      consult => consult.id === this.props.match.params.consultId
    )
    fetchDoctor({ doctorId: singleConsult && singleConsult.doctorId }).then(res => {
      if (
        res &&
        res.data &&
        res.data.doctor &&
        res.data.doctor.disableVideoCall
      ) {
        this.setState({ docDisableVideocall: true })
      }
    })
  }

  fetchDoctorDetails = consult => {
    fetchDoctor({ doctorId: consult.doctorId })
      .then(response => {
        const {
          data: { doctor },
        } = response
        if (doctor && doctor.id) {
          this.setState({
            openWarningModal: true,
            scheduleCallConsultId: consult.id,
            doctorFee: doctor.videocallFee ? doctor.videocallFee : 10,
          })
          if (consult.practiceId) {
            fetchPracticeDetails(consult.practiceId).then(res => {
              this.setState({
                consultFee: res.data.practiceDetails.consultationFee
                  ? res.data.practiceDetails.consultationFee
                  : 59,
              })
            })
          }
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          openWarningModal: true,
          scheduleCallConsultId: consult.id,
        })
      })
  }

  render() {
    const { allConsults, match, practiceDetails, isFromDermio } = this.props
    const { showScheduleVideocall } = this.state

    const selectedConsult = allConsults.find(
      consult => consult.id === match.params.consultId
    ) // Change to === after testing

    // console.log("selectedConsult ", selectedConsult)

    if (!selectedConsult) {
      return (
        <Redirect
          to={`/patient/dashboard${isFromDermio
            ? "?from=dermio"
            : `?practiceId=${practiceDetails && practiceDetails.id}`
            }`}
        />
      )
    }

    let {
      cardToken,
      pharmacy_NCPDPID,
      patientDob,
      status,
      doctorId,
      isVideoCallConsult,
      practiceId,
      insuranceFile1
    } = selectedConsult

    //-------------------------------- If consult status is "Pending" or "initialized"--------------------------------------
    if (status === "pending" || status === "initialized") {

      if (
        !isVideoCallConsult &&
        showScheduleVideocall &&
        doctorId &&
        !this.state.docDisableVideocall &&
        practiceId &&
        !cardToken &&
        !insuranceFile1
      ) {
        return (
          <div>
            <Card style={{ marginBottom: 5 }}>
              <CardContent>
                <Typography gutterBottom>
                  Would you like to upgrade your consult as a "Videocall
                  consult" ?
                </Typography>
                <Typography variant="caption" gutterBottom>
                  You can schedule a videocall with doctor.(You can't upgrade to videocall once card details are added)
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={() => {
                    this.fetchDoctorDetails(selectedConsult)
                  }}
                >
                  Upgrade
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={() => {
                    this.setState({ showScheduleVideocall: false })
                  }}
                >
                  Skip
                </Button>
              </CardContent>
            </Card>
            <ModalDialog
              open={this.state.openWarningModal}
              handleClose={() => {
                this.setState({ openWarningModal: false })
              }}
              handleConfirmation={() => {
                this.props.history.push(
                  `/patient/consults/${this.state.scheduleCallConsultId}/schedule`
                )
              }}
              alertMsg={`Video calling feature costs additional $${this.state.doctorFee
                }. Are you sure you want to schedule a call? (Total fee = $${this
                  .state.consultFee + this.state.doctorFee})`}
            />
          </div>
        )
      }

      // If dermio practice consult go to insurance page
      if (
        !cardToken &&
        practiceId === process.env.REACT_APP_PRACTICE_ID &&
        insuranceFile1 === null
      ) {
        return (
          <Redirect
            to={`/patient/consults/${match.params.consultId}/insuranceDetails`}
          />
        )
      }

      // If other practice consult go to card details page
      if (!cardToken && practiceId != process.env.REACT_APP_PRACTICE_ID) {
        return (
          <Redirect
            to={`/patient/consults/${match.params.consultId}/cardDetails`}
          />
        )
      }

      console.log("practiceDetails ", practiceDetails)
      if ((practiceDetails && !practiceDetails.disabledPrescription) && (!patientDob || !pharmacy_NCPDPID)) {
        return (
          <Redirect
            to={`/patient/consults/${match.params.consultId}/pharmacy`}
          />
        )
      }

      return (
        <div>
          <Card>
            <CardContent>
              <Typography gutterBottom>
                Thank you! Your board-certified dermatologist will respond
                within 48-72 hours.
                </Typography>
              <Typography gutterBottom>
                When the doctor responds, we will notify you via SMS. You can
                then login to see his response and chat with them.
                </Typography>
              <Typography variant="caption" gutterBottom>
                Drop us a mail at helpdesk@derm.io if you face any issues.
                </Typography>
            </CardContent>
          </Card>

          {/* {!isVideoCallConsult &&
            showScheduleVideocall &&
            doctorId &&
            !this.state.docDisableVideocall &&
            practiceId ? (
              <Card style={{ marginBottom: 5 }}>
                <CardContent>
                  <Typography gutterBottom>
                    Do you like to Upgrade your consult as a "Videocall consult" ?
                </Typography>
                  <Typography variant="caption" gutterBottom>
                    You can schedule a videocall with doctor.
                </Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: 10 }}
                    onClick={() => {
                      this.fetchDoctorDetails(selectedConsult)
                    }}
                  >
                    Upgrade
                </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: 10 }}
                    onClick={() => {
                      this.setState({ showScheduleVideocall: false })
                    }}
                  >
                    Not now
                </Button>
                </CardContent>
              </Card>
            ) : (
              <Card>
                <CardContent>
                  <Typography gutterBottom>
                    Thank you! Your board-certified dermatologist will respond
                    within 48-72 hours.
                </Typography>
                  <Typography gutterBottom>
                    When the doctor responds, we will notify you via SMS. You can
                    then login to see their response and chat with them.
                </Typography>
                  <Typography variant="caption" gutterBottom>
                    Drop us a mail at helpdesk@derm.io if you face any issues.
                </Typography>
                </CardContent>
              </Card>
            )}
          <ModalDialog
            open={this.state.openWarningModal}
            handleClose={() => {
              this.setState({ openWarningModal: false })
            }}
            handleConfirmation={() => {
              this.props.history.push(
                `/patient/consults/${this.state.scheduleCallConsultId}/schedule`
              )
            }}
            alertMsg={`Video calling feature costs additional $${
              this.state.doctorFee
              }. Are you sure you want to schedule a call? (Total fee = $${this
                .state.consultFee + this.state.doctorFee})`}
          /> */}
        </div>
      )
    }
    //------------------------If consult status is "accepted"-------------------------------------------
    if (status === "accepted") {
      if (cardToken || insuranceFile1 != null) {
        if (!patientDob || !pharmacy_NCPDPID) {
          return (
            <Redirect
              to={`/patient/consults/${match.params.consultId}/pharmacy`}
            />
          )
        }

        return (
          // <Redirect to={`/patient/consults/${match.params.consultId}/show`} />
          <ConsultShowPage
            consult={selectedConsult}
            history={this.props.history}
            practiceDetails={practiceDetails}
          />
        )
      }

      return (
        <Redirect
          to={`/patient/consults/${match.params.consultId}/cardDetails`}
        />
      )
    }
    //------------------------If consult status is "closed_paid" or "closed_unpaid" or "declined"---------------------------
    if (
      status === "closed_paid" ||
      status === "closed_unpaid" ||
      status === "declined"
    ) {
      return (
        <ConsultShowPage
          consult={selectedConsult}
          history={this.props.history}
          practiceDetails={practiceDetails}
        />
      )
    }
  }
}

export default Consult
