import React, { Component, Fragment } from "react"
import { Form, Formik, Field } from "formik"
import { object, string } from "yup"
import getYear from "date-fns/get_year"
import LinearProgress from "@material-ui/core/LinearProgress"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import MaskedInput from "react-text-mask"
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe"

import PharmacySelectionForm from "./PharmacySelectionForm"
import fetchPharmaciesList from "../../../queries/fetchPharmicesList"
import updateConsult from "../../../queries/updateConsult"

const styles = theme => ({
  root: {
    display: "flex",
  },
  formControl: {
    marginTop: theme.spacing.unit * 3,
  },
  formLabel: {
    margin: 0,
  },
  group: {
    display: "flex",
    flexFlow: "row wrap",
  },
  formWrapper: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
    backgroundColor: "rgba(251, 251, 251, 0.9)",
  },
  field: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  btn: {
    color: "#36c2ea",
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

const CustomTextComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      type="text"
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
    />
  )
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy", {
  minYear: 1900,
  maxYear: getYear(new Date()),
})

const CustomDobComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <MaskedInput
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      id={field.name}
      name={field.name}
      placeholder="mm/dd/yyyy"
      keepCharPositions={true}
      pipe={autoCorrectedDatePipe}
      {...field}
      {...props}
      touched={touched}
      errors={errors}
      render={(ref, textFieldProps) => {
        return (
          <TextField
            label={textFieldProps.label}
            type="text"
            name={textFieldProps.name}
            helperText={
              textFieldProps.touched[textFieldProps.name] &&
              textFieldProps.errors[textFieldProps.name]
            }
            error={
              textFieldProps.touched[textFieldProps.name] &&
              textFieldProps.errors[textFieldProps.name] &&
              true
            }
            inputRef={ref}
            {...textFieldProps}
          />
        )
      }}
    />
  )
}

const validationSchema = object().shape({
  // dob validation has been done at the corresponding field level because of a bug with yup string().matches()
  pharmacyName: string("What is the name of the pharmacy?").required(
    "Please enter a valid pharmacy name"
  ),
  pharmacyZipCode: string("What is the zipcode of the pharmacy")
    .required("Please enter a zipcode")
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
      message: "Please enter a valid zipcode",
    }),
  pharmacy_NCPDPID: string().required("Please select a pharmacy"),
})

class Pharmacy extends Component {
  state = {
    value: "pharmacyZipCode",
    loading: false,
    allPharmacies: [],
    selectedPharmacy: null,
    pharmaciesToLoadInitially: 10,
    errorType: "",
    isModalOpen: false,
  }

  fetchPharmacies = ({ values }) => {
    const { value: searchPharmacyBy } = this.state

    let variables = {}

    if (searchPharmacyBy === "pharmacyZipCode") {
      const zipcode = values.pharmacyZipCode
      variables = { zipcode, name: "", pharmacy_NCPDPID: "" }
    }

    if (searchPharmacyBy === "pharmacyName") {
      const name = values.pharmacyName
      variables = { zipcode: "", name, pharmacy_NCPDPID: "" }
    }

    return this.setState({ loading: true }, async () => {
      try {
        const data = await fetchPharmaciesList(variables)
        const { allPharmacies } = data.data

        if (!allPharmacies.length) {
          return this.setState({
            errorType: "No Pharmacies",
            loading: false,
          })
        }

        return this.setState({
          allPharmacies,
          loading: false,
        })
      } catch (e) {
        console.error(e)
        return this.setState({
          errorType: "Fetch Error",
          loading: false,
        })
      }
    })
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  submit = async (values, { setSubmitting }) => {
    const { consult, handleConsultUpdate } = this.props;

    const patientDob = values.dob.replace(/\//g, " ")
    // console.log(values.pharmacy_Name)
    const variables = {
      id: consult.id,
      pharmacy_Name: values.pharmacy_Name,
      pharmacy_NCPDPID: values.pharmacy_NCPDPID,
      patientDob,
    }

    try {
      const data = await updateConsult(variables)
      setSubmitting(false)

      const updatedConsult = data.data.updateConsult

      const { patientDob, pharmacy_NCPDPID } = updatedConsult

      const updateDetails = {
        patientDob,
        pharmacy_NCPDPID,
      }

      handleConsultUpdate(consult.id, updateDetails)
    } catch (e) {
      console.error(e)
      this.setState({
        errorType: "Update Error",
      })

      setSubmitting(false)
    }
  }

  onContinuation = ({ validateForm, setFieldTouched, values }) => {
    const { value } = this.state

    this.setState({
      allPharmacies: [],
    })

    validateForm().then(errors => {
      if (value === "pharmacyZipCode") {
        if (!errors.dob && !errors.pharmacyZipCode) {
          return this.setState(
            {
              errorType: "",
            },
            () => this.fetchPharmacies({ values })
          )
        } else {
          setFieldTouched("dob", true)
          setFieldTouched("pharmacyZipCode", true)
        }
      } else {
        if (!errors.dob && !errors.pharmacyName) {
          return this.setState(
            {
              errorType: "",
            },
            () => this.fetchPharmacies({ values })
          )
        } else {
          setFieldTouched("dob", true)
          setFieldTouched("pharmacyName", true)
        }
      }
    })
  }

  handlePharmacyConfirmation = async (pharmacy, setFieldValue, submitForm) => {
    const { NCPDPID, Name } = pharmacy
    setFieldValue("pharmacy_Name", Name, false)
    setFieldValue("pharmacy_NCPDPID", NCPDPID, false)
    setFieldValue("pharmacyName", "Walgreens", false)
    setFieldValue("pharmacyZipCode", "10005", false)

    setTimeout(() => submitForm(), 250) // Because sometimes the click event fires only on the second click under load.
  }

  handlePharmacyClick = async (e, pharmacy, values) => {
    e.preventDefault()

    // https://stackoverflow.com/questions/51224/regular-expression-to-match-valid-dates/8768241#8768241
    const reDob = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g

    if (!reDob.test(values.dob)) {
      return this.setState({
        errorType: "Dob Error",
        selectedPharmacy: null,
      })
    }

    this.setState(
      {
        errorType: "",
        selectedPharmacy: pharmacy,
      },
      () => this.handleModalOpen()
    )
  }

  // Doing this because of performance overhead when 100's of pharmacies are loaded.
  loadMorePharmacies = () => {
    const { pharmaciesToLoadInitially } = this.state

    return this.setState({
      pharmaciesToLoadInitially: pharmaciesToLoadInitially + 10,
    })
  }

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose = isSubmitting => {
    // Prevent user from closing modal while formik form is submitting.
    // This may not be necessary but done just to be safe.
    if (!isSubmitting) {
      return this.setState({ isModalOpen: false })
    }

    return this.setState({ isModalOpen: true })
  }

  render() {
    const {
      value,
      allPharmacies,
      errorType,
      loading,
      pharmaciesToLoadInitially,
      isModalOpen,
      selectedPharmacy,
    } = this.state
    const { classes, allConsults, consult } = this.props

    let prefilledDetails = {
      dob: "",
    }

    if (allConsults && allConsults.length > 0 && allConsults[0].url1) {
      const userConsultsAlone = allConsults.filter(
        consult => consult.consultFor !== "others"
      )

      if (
        consult &&
        consult.consultFor !== "others" &&
        userConsultsAlone.length
      ) {
        prefilledDetails = {
          dob:
            userConsultsAlone.slice(-1)[0].patientDob &&
            userConsultsAlone.slice(-1)[0].patientDob.replace(/ /g, "/"),
        }
      }
    }

    return (
      <Formik
        initialValues={{
          dob: prefilledDetails.dob,
          pharmacy_Name: "",
          pharmacyZipCode: "",
          pharmacy_NCPDPID: "",
        }}
        onSubmit={this.submit}
        validationSchema={validationSchema}
      >
        {({
          status,
          validateForm,
          setFieldValue,
          values,
          setFieldTouched,
          submitForm,
          isSubmitting,
          setStatus,
        }) => (
            <Form>
              {!isSubmitting ? (
                <Fragment>
                  <Paper className={classes.formWrapper} elevation={5}>
                    <Field
                      component={CustomDobComponent}
                      validate={value => {
                        let error

                        // https://stackoverflow.com/questions/51224/regular-expression-to-match-valid-dates/8768241#8768241
                        const reDob = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g

                        if (!value) {
                          error = "Date of Birth is required."
                        } else if (!reDob.test(value)) {
                          error = "Invalid Date."
                        }

                        return error
                      }}
                      name="dob"
                      label="Date of birth"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      className={classes.field}
                      variant="outlined"
                      margin="normal"
                    />
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend" className={classes.formLabel}>
                        Search Pharmacy By:
                    </FormLabel>
                      <RadioGroup
                        aria-label="Search Pharmacy By"
                        name="searchBy"
                        className={classes.group}
                        value={this.state.value}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="pharmacyZipCode"
                          control={<Radio />}
                          label="Zipcode"
                        />
                        <FormControlLabel
                          value="pharmacyName"
                          control={<Radio />}
                          label="Name"
                        />
                      </RadioGroup>
                    </FormControl>
                    {value === "pharmacyZipCode" ? (
                      <Field
                        component={CustomTextComponent}
                        name="pharmacyZipCode"
                        label="Enter pharmacy zipcode:"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        className={classes.field}
                        variant="outlined"
                        margin="normal"
                      />
                    ) : (
                        <Field
                          component={CustomTextComponent}
                          name="pharmacyName"
                          label="Enter pharmacy name:"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          className={classes.field}
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                    <Button
                      disabled={loading}
                      type="button"
                      onClick={() =>
                        this.onContinuation({
                          validateForm,
                          setFieldTouched,
                          values,
                        })
                      }
                      className={classes.btn}
                    >
                      Search Pharmacies
                  </Button>
                    <PharmacySelectionForm
                      searchPharmacyBy={value}
                      allPharmacies={allPharmacies}
                      handlePharmacyClick={this.handlePharmacyClick}
                      errorType={errorType}
                      loading={loading}
                      validateForm={validateForm}
                      setFieldValue={setFieldValue}
                      values={values}
                      submitForm={submitForm}
                      setFieldTouched={setFieldTouched}
                      isSubmitting={isSubmitting}
                      status={status}
                      setStatus={setStatus}
                      pharmaciesToLoadInitially={pharmaciesToLoadInitially}
                      loadMorePharmacies={this.loadMorePharmacies}
                      isModalOpen={isModalOpen}
                      handleModalClose={this.handleModalClose}
                      handleConfirmation={this.handlePharmacyConfirmation}
                      selectedPharmacy={selectedPharmacy}
                    />
                  </Paper>
                </Fragment>
              ) : (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="caption">
                        Thank you! Please wait while we update your details.... DO
                        NOT GO BACK OR RELOAD THE PAGE IN THE MEANTIME
                  </Typography>
                      <LinearProgress
                        classes={{
                          colorPrimary: classes.linearColorPrimary,
                          barColorPrimary: classes.linearBarColorPrimary,
                        }}
                      />
                    </CardContent>
                  </Card>
                )}
            </Form>
          )}
      </Formik>
    )
  }
}

export default withStyles(styles)(Pharmacy)
