import React from "react"
import { Auth } from "aws-amplify"
import { SignIn } from "aws-amplify-react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import Link from "@material-ui/core/Link"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import dermioLogo from "../../assets/img/dermio-logo.svg"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey["A700"],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "0 1em 1em 1em",
  },
  linkLabel: {
    marginTop: 15,
  },
})

class CustomSignIn extends SignIn {
  state = {
    error: "",
  }

  handleChange = e => {
    console.log("dds")
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }

  componentDidMount = () => {
    // console.log("rer")
    this.setState({
      error: "",
    })
  }

  handleSubmit = async e => {
    e.preventDefault()

    const PASSWORD = "mD@19911"

    let { phoneNumber } = this.inputs

    phoneNumber = `+1${phoneNumber.trim()}`

    Auth.signIn(phoneNumber, PASSWORD)
      .then(user => {
        this.changeState("confirmSignIn", user)
        this.setState({
          error: "",
        })
      })
      .catch(err => {
        console.error("SignIn", err)

        if (err.code === "UserNotFoundException") {
          this.setState({
            error: "Phone number not registered. Please sign up!",
          })
        }

        if (err.code === "UserNotConfirmedException") {
          Auth.resendSignUp(phoneNumber).then(res => {
            this.changeState("confirmSignUp", {
              user: {
                username: phoneNumber,
              },
            })
          })
        }

        // if (err.code === "NotAuthorizedException") {
        //   this.setState({
        //     error: "Incorrect username or password."
        //   });
        // }
      })
  }

  render() {
    const { classes, authState, onStateChange } = this.props
    if (authState === "signIn") {
      return (
        <div className={classes.main}>
          <Card className={classes.card} id="signIn">
            {/* <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div> */}
            <div
              style={{
                margin: "1em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
              <Typography variant="caption" style={{ color: "#25c3ea" }}>
                (Online dermatology consultation)
              </Typography>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className={classes.hint}>SIGN IN TO YOUR ACCOUNT</div>
              <div className={classes.error}>{this.state.error}</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    margin="normal"
                    type="text"
                    helperText="10 digit phone number"
                    fullWidth={true}
                    required
                    error={this.state.error ? true : false}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                {/* <Typography variant="caption" color="primary">
                  New to Dermio?{" "}
                  <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    className={classes.button}
                    fullWidth
                    onClick={() => {
                      onStateChange("signUp")
                      this.setState({
                        error: "",
                      })
                    }}
                  >
                    SIGN UP
                  </Button>
                </Typography> */}
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  onChange={this.handleInputChange}
                >
                  Submit
                </Button>

                <Link
                  component="button"
                  variant="body1"
                  color="secondary"
                  underline="always"
                  className={classes.linkLabel}
                  onClick={() => {
                    onStateChange("signUp")
                    this.setState({
                      error: "",
                    })
                  }}
                >
                  New to website? Register here.
                </Link>
              </CardActions>
            </form>
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomSignIn)
