import React, { Component, Fragment } from "react"
import { FastField } from "formik"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import ArrowRight from "@material-ui/icons/ArrowRight"
import { withStyles } from "@material-ui/core/styles"

import states from "../../../utils/states"

const styles = theme => ({
  field: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  formActions: {
    display: "flex",
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  submitBtn: {
    marginLeft: "auto",
    color: "#36c2ea",
  },
  imageUpload: {
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
})

const CustomTextComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      type="text"
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
    />
  )
}

const CustomNumberComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      type="number"
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
    />
  )
}

const CustomSelectComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      select
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
      SelectProps={{
        native: true,
      }}
    >
      {props.options}
    </TextField>
  )
}

let allStates = [
  <option key="default" value="">
    Choose state
  </option>,
  ...states.map(state => (
    <option key={state} value={state}>
      {state}
    </option>
  )),
]

let genderOptions = [
  <option key="default" value="">
    Choose gender
  </option>,
  <option key="Male" value="Male">
    Male
  </option>,
  <option key="Female" value="Female">
    Female
  </option>,
  <option key="Other" value="Other">
    Other
  </option>,
]

class BasicDetailsForm extends Component {
  onContinuation = () => {
    const { goNext, validateForm, setFieldTouched, setStatus } = this.props

    validateForm().then(errors => {
      if (
        !errors.age &&
        !errors.state &&
        !errors.gender &&
        !errors.patientname &&
        !errors.medicalIssues &&
        !errors.currentMedicalIssue
      ) {
        setStatus({})

        return goNext()
      } else {
        setFieldTouched("age", true)
        setFieldTouched("state", true)
        setFieldTouched("gender", true)
        setFieldTouched("patientname", true)
        setFieldTouched("medicalIssues", true)
        setFieldTouched("currentMedicalIssue", true)

        return setStatus({
          errorType: "Form Error",
        })
      }
    })
  }

  render() {
    const { createConsultFor, isSubmitting, classes } = this.props

    return (
      <Fragment>
        <FastField
          component={CustomSelectComponent}
          name="gender"
          options={genderOptions}
          label={
            createConsultFor === "me"
              ? "What is your gender?"
              : "What is their gender?"
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          required
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        {createConsultFor === "others" && (
          <FastField
            component={CustomTextComponent}
            name="patientname"
            label="What is their full name? eg: John Doe"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            className={classes.field}
            variant="outlined"
            margin="normal"
          />
        )}
        <FastField
          component={CustomNumberComponent}
          name="age"
          label={
            createConsultFor === "me"
              ? "What is your age?"
              : "What is their age?"
          }
          inputProps={{
            min: 0,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          required
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomSelectComponent}
          name="state"
          options={allStates}
          label={
            createConsultFor === "me"
              ? "Which state are you from?"
              : "Which state are they from?"
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          required
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomTextComponent}
          name="allergy"
          label={
            createConsultFor === "me"
              ? "Describe all medications you are allergic to?"
              : "Describe all medications they are allergic to?"
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomTextComponent}
          name="medication"
          label={
            createConsultFor === "me"
              ? "Describe all medications you are currently taking?"
              : "Describe all medications they are currently taking?"
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomNumberComponent}
          name="cigarettesPerDay"
          label={
            createConsultFor === "me"
              ? "How many times do you smoke in a day?"
              : "How many times do they smoke in a day?"
          }
          inputProps={{
            min: 0,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomNumberComponent}
          name="drinksPerWeek"
          label={
            createConsultFor === "me"
              ? "How many drinks do you have in a week?"
              : "How many drinks do they have in a week?"
          }
          inputProps={{
            min: 0,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomTextComponent}
          name="surgeries"
          label={
            createConsultFor === "me"
              ? "Describe in detail about any surgeries you have had before?"
              : "Describe in detail about any surgeries they have had before?"
          }
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rowsMax={8}
          fullWidth
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomTextComponent}
          name="medicalIssues"
          label={
            createConsultFor === "me"
              ? "Describe in detail about any medical issues you or your blood relatives have had?"
              : "Describe in detail about any medical issues they or their blood relatives have had?"
          }
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rowsMax={8}
          fullWidth
          required
          className={classes.field}
          variant="outlined"
          margin="normal"
        />
        <FastField
          component={CustomTextComponent}
          name="currentMedicalIssue"
          label={
            createConsultFor === "me"
              ? "Please describe your skin condition. Location, duration, does it itch, painful"
              : "Please describe their skin condition. Location, duration, does it itch, painful"
          }
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rowsMax={12}
          fullWidth
          required
          className={classes.field}
          variant="outlined"
          margin="normal"
        />

        <div className={classes.formActions}>
          <Button
            type="button"
            onClick={() => this.onContinuation()}
            disabled={isSubmitting}
            color="primary"
            className={classes.submitBtn}
            size="large"
          >
            Continue <ArrowRight />
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(BasicDetailsForm)
