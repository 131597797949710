/* eslint-disable */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Auth } from "aws-amplify"
import { Route, Switch } from "react-router-dom"
import queryString from "query-string"

import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

import FlashOnIcon from "@material-ui/icons/FlashOn"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"

import Navbar from "../components/NavBar/Navbar"
import DashboardPage from "../views/Dashboard/Dashboard"
import routes from "../routes"
import fetchMyConsultDetails from "../queries/fetchmyConsultDetails"
import fetchPracticeDetails from "../queries/fetchPracticeDetails"
import fetchMyConsultVideoCalls from "../queries/fetchMyConsultVideoCalls"
import autoLogout from "../utils/autoLogout"
import decryptConsult from "../utils/decryptConsult"

import dermioLogo from "../assets/img/dermio-logo.svg"

const styles = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    overflow: "auto",
    position: "relative",
    float: "right",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    marginTop: "85px",
    padding: "15px 0px",
    minHeight: "calc(100vh - 123px)",
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  avatar: {
    // margin: "1em",
    display: "flex",
    justifyContent: "center",
    maxWidth: 70,
  },
})

class Dashboard extends Component {
  constructor() {
    super()

    this.state = {
      allConsults: [],
      loadingConsults: true,
      practiceDetails: {},
      isPracticeValid: true,
      isFromDermio: false,
      queryValues: {},
      errorsWhile: {},
      allVideoCalls: [],
      loadingVideoCalls: true,
    }
    autoLogout(window.location.href)
  }

  addNewConsult = async consult => {
    const { allConsults } = this.state

    return this.setState(
      {
        allConsults: [...allConsults, await decryptConsult(consult)],
      },
      () => {
        // const isVideocallConsult = JSON.parse(
        //   localStorage.getItem("isVideocallConsult")
        // )
        // // console.log("addNewConsult ", isVideocallConsult)
        // if (isVideocallConsult) {
        //   this.props.history.push(`/patient/consults/${consult.id}/schedule`)
        // } else {
        this.props.history.push(`/patient/consults/${consult.id}`)
        // }
      }
    )
  }

  handleConsultUpdate = (id, updateDetails) => {
    const { allConsults } = this.state

    const consultToUpdate = allConsults.find(consult => consult.id === id)

    const remainingConsults = allConsults.filter(consult => consult.id !== id)

    const updatedConsult = {
      ...consultToUpdate,
      ...updateDetails,
    }

    const consults = [updatedConsult, ...remainingConsults]

    return this.setState(
      {
        allConsults: consults,
      },
      () => {
        this.props.history.push(`/patient/consults/${id}`)
      }
    )
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }

  componentDidMount = async () => {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel)
    }

    window.addEventListener("resize", this.resizeFunction)

    // // const queryValues = { from: "dermio" } //dummy data for testing
    // const queryValues = {
    //   from: "dermio",
    //   // practiceId: "3985c334-163d-4362-8e31-98a60a6dd776", // no doctors
    //   practiceId: "98200635-6ad6-438a-aa90-3bdda81c66e1",
    // } //dummy data for testing
    const queryValues = queryString.parse(this.props.location.search)

    if (Object.keys(queryValues).length === 0) {
      // console.log(queryValues.keys)
      const practiceDetails = JSON.parse(
        localStorage.getItem("practiceDetails")
      )

      queryValues.practiceId = practiceDetails.practiceId
      queryValues.from = practiceDetails.from
    }

    const { practiceId, from } = queryValues

    localStorage.setItem(
      "practiceDetails",
      JSON.stringify({ practiceId, from })
    )

    try {
      const currentSession = await Auth.currentSession()

      const payload = currentSession.idToken.payload
      const sub = payload["sub"]

      const variables = { patientId: sub }

      if (practiceId) {
        try {
          const {
            data: { practiceDetails },
          } = await fetchPracticeDetails(practiceId)
          console.log(practiceDetails)
          if (practiceDetails) {
            this.setState({
              practiceDetails,
              queryValues,
              isPracticeValid: true,
            })
          } else {
            return this.setState({
              practiceDetails: {},
              queryValues,
              isPracticeValid: false,
              loadingConsults: false,
              loadingVideoCalls: false,
            })
          }
        } catch (e) {
          console.error(e)

          return this.setState({
            practiceDetails: {},
            queryValues,
            isPracticeValid: false,
            loadingConsults: false,
            loadingVideoCalls: false,
            errorsWhile: {
              fetchingPractice: true,
            },
          })
        }
      } else if (from && from === "dermio") {
        try {
          const {
            data: { practiceDetails },
          } = await fetchPracticeDetails(process.env.REACT_APP_PRACTICE_ID)

          this.setState({
            practiceDetails,
            queryValues,
            isFromDermio: true,
          })
        } catch (e) {
          console.error(e)

          return this.setState({
            practiceDetails: {},
            queryValues,
            isFromDermio: false,
            loadingConsults: false,
            loadingVideoCalls: false,
            errorsWhile: {
              fetchingPractice: true,
            },
          })
        }
      } else {
        return this.setState({
          practiceDetails: {},
          queryValues,
          isFromDermio: false,
          loadingConsults: false,
          loadingVideoCalls: false,
        })
      }

      // fetch consults upcoming video call list
      try {
        const { data } = await fetchMyConsultVideoCalls(variables)

        const allVideoCalls = data.allVideoCalls.items
        console.log(allVideoCalls)
        if (allVideoCalls) {
          this.setState({
            allVideoCalls: allVideoCalls,
            loadingVideoCalls: false,
          })
        }
      } catch (e) {
        console.error(e)
        this.setState({
          loadingVideoCalls: false,
          errorsWhile: {
            fetchingVideoCalls: true,
          },
        })
      }

      this.fetchMyConsults()
    } catch (e) {
      console.error(e)

      return this.setState({
        loadingConsults: false,
        errorsWhile: {
          auth: true,
        },
      })
    }
  }

  fetchMyConsults = async () => {
    const currentSession = await Auth.currentSession()
    const payload = currentSession.idToken.payload
    const sub = payload["sub"]
    const variables = { patientId: sub }

    try {
      const { data } = await fetchMyConsultDetails(variables)

      const allConsults = data.myConsult

      if (allConsults) {
        this.setState({
          allConsults: await Promise.all(
            allConsults.map(async c => await decryptConsult(c))
          ),
        })
      }
    } catch (e) {
      console.error(e)

      this.setState({
        loadingConsults: false,
        errorsWhile: {
          fetchingConsults: true,
        },
      })
    } finally {
      return this.setState({
        loadingConsults: false,
      })
    }
  }

  componentWillReceiveProps = () => {
    this.fetchMyConsults()
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction)
  }

  render() {
    const { classes } = this.props

    const {
      allConsults,
      allVideoCalls,
      loadingConsults,
      loadingVideoCalls,
      practiceDetails,
      queryValues,
      isPracticeValid,
      isFromDermio,
      errorsWhile,
    } = this.state
    // console.log("isFromDermio ",isFromDermio)
    if ((queryValues.practiceId && isPracticeValid) || isFromDermio) {
      const switchRoutes = (
        <Switch>
          {routes.map((prop, key) => {
            if (prop.layout === "/patient") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={props => (
                    <prop.component
                      {...props}
                      routes={prop.routes}
                      allConsults={allConsults}
                      handleConsultUpdate={this.handleConsultUpdate}
                      addNewConsult={this.addNewConsult}
                      allVideoCalls={allVideoCalls}
                      test={"test"}
                      loadingConsults={loadingConsults}
                      loadingVideoCalls={loadingVideoCalls}
                      practiceDetails={practiceDetails}
                      queryValues={queryValues}
                      isPracticeValid={isPracticeValid}
                      isFromDermio={isFromDermio}
                      errorsWhile={errorsWhile}
                      fetchMyConsults={this.fetchMyConsults}
                    />
                  )}
                  key={key}
                />
              )
            }
          })}
        </Switch>
      )

      return (
        <div className={classes.wrapper}>
          <div className={classes.mainPanel} ref="mainPanel">
            <Navbar
              {...this.props}
              practiceDetails={practiceDetails}
              queryValues={queryValues}
              isPracticeValid={isPracticeValid}
              isFromDermio={isFromDermio}
              errorsWhile={errorsWhile}
            />
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
              <Typography
                variant="subtitle2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <FlashOnIcon color="error" />
                Powered by{" "}
                <a
                  href="https://www.dermio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: "3px",
                  }}
                >
                  <div className={classes.avatar}>
                    <img
                      alt="Dermio Logo"
                      src={dermioLogo}
                      className={classes.avatar}
                    />
                  </div>
                  {/* {`Dermio`} */}
                </a>
              </Typography>
            </div>
          </div>
        </div>
      )
    } else {
      const switchRoutes = (
        <Switch>
          {routes.map((prop, key) => {
            if (prop.layout === "/patient") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  render={props => (
                    <DashboardPage
                      {...props}
                      routes={prop.routes}
                      allConsults={allConsults}
                      allVideoCalls={allVideoCalls}
                      handleConsultUpdate={this.handleConsultUpdate}
                      addNewConsult={this.addNewConsult}
                      loadingConsults={loadingConsults}
                      loadingVideoCalls={loadingVideoCalls}
                      practiceDetails={practiceDetails}
                      queryValues={queryValues}
                      errorsWhile={errorsWhile}
                      isPracticeValid={isPracticeValid}
                      isFromDermio={isFromDermio}
                      fetchMyConsults={this.fetchMyConsults}
                    />
                  )}
                  key={key}
                />
              )
            }
          })}
        </Switch>
      )

      return (
        <div className={classes.wrapper}>
          <div className={classes.mainPanel} ref="mainPanel">
            <Navbar
              {...this.props}
              practiceDetails={practiceDetails}
              queryValues={queryValues}
              isPracticeValid={isPracticeValid}
              isFromDermio={isFromDermio}
              errorsWhile={errorsWhile}
            />
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
              <Typography
                variant="subtitle2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <FlashOnIcon color="error" />
                Powered by{" "}
                <a
                  href="https://www.dermio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginLeft: "3px",
                  }}
                >
                  <div className={classes.avatar}>
                    <img
                      alt="Dermio Logo"
                      src={dermioLogo}
                      className={classes.avatar}
                    />
                  </div>
                  {/* {`Dermio`} */}
                </a>
              </Typography>
            </div>
          </div>
        </div>
      )
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Dashboard)
