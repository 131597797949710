
import React from "react"

const PreviewComponent =(props)=>(
            <div
              style={{
                height: "100%",
                width: props.url ? "100%" : "0%",
                position: "absolute",
                // top:0,
                bottom: 0,
                left: 0,
                top: 50, 
                zIndex: 101,
                backgroundColor: "#CACACA",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  right: 15,
                  top: 15,
                  color: "white",
                  fontSize: 25,
                  cursor: "pointer",
                }}
                onClick={props.close}
              >
                X
              </span>
               {props.messageType === "file" ? 
                  <iframe src={props.url} frameborder="0" style={{ width: "100%", height: "100%", paddingTop: 50 }} />
               :
                <img
                  src={props.url}
                  style={{
                    maxHeight: "90%",
                    maxWidth: "100%",
                  }}
                />
              }
            </div>);

export default PreviewComponent;