import { Auth } from "aws-amplify"

const timer = (redirectTo) => {
  let timeoutID
  const waitTimeForLogout = 3600000
    // const waitTimeForLogout = 20000

    // Automatically invoked
    ; (function setup() {
      document.body.addEventListener("mousemove", resetTimer, false)
      document.body.addEventListener("mousedown", resetTimer, false)
      document.body.addEventListener("keypress", resetTimer, false)
      document.body.addEventListener("DOMMouseScroll", resetTimer, false)
      document.body.addEventListener("mousewheel", resetTimer, false)
      document.body.addEventListener("touchmove", resetTimer, false)
      document.body.addEventListener("MSPointerMove", resetTimer, false)
      startTimer()
    })()

  function startTimer() {
    timeoutID = window.setTimeout(goInactive, waitTimeForLogout)
  }

  const resetTimer = e => {
    window.clearTimeout(timeoutID)
    goActive()
  }

  function goInactive() {
    const practiceDetails = JSON.parse(localStorage.getItem("practiceDetails"));
    // console.log("practiceDetails ", practiceDetails)
    Auth.signOut().then(res => {
      window.clearTimeout(timeoutID)
      // console.log(redirectTo)
      window.location.href = `#/patient/dashboard${practiceDetails && practiceDetails.practiceId ? `?practiceId=${practiceDetails && practiceDetails.practiceId}` : "?from=dermio"}`;
      // window.location.hash = "/patient/dashboard";
      localStorage.removeItem("practiceDetails");
      console.log(res);
    })
  }

  function goActive() {
    // do something
    startTimer()
  }
}

export default timer
