import gql from "graphql-tag"
import Appsync from "../config"

const client = Appsync()

const query = gql`
  query allVideoCalls($patientId: String) {
    allVideoCalls: allPatientVideoCalls(
      patientId: $patientId
      filter: { inviteStatus: { eq: "accepted" } }
    ) {
      items {
        id
        doctorId
        patientId
        callStatus
        inviteStatus
        createdAt
        scheduledDateTime
        scheduledDateString
        doctorName
      }
    }
  }
`

const fetchMyConsultVideoCalls = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "no-cache" })
  })
  return result
}

export default fetchMyConsultVideoCalls
