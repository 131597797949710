import React, { Component, Fragment } from "react"
import { v4 as uuid } from "uuid"

import { Auth } from "aws-amplify"

import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import LinearProgress from "@material-ui/core/LinearProgress"
import imageCompression from "browser-image-compression"

import Notifications from "../Notifications/Notifications"
import updateInsurancePics from "../../queries/updateInsurancePics"
import { sendConsultNotification } from "../../queries/sendConsultNotification"

import MemberIdInput from "./MemberIdInput"
import UppyDashoard from "./UppyDashboard"

const styles = theme => ({
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  btn: {
    color: "#36c2ea",
  },
  imageUpload: {
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

class ImageSelectionForm extends Component {
  state = {
    showImageUpload: false,
    isSubmitting: false,
    isError: false,
    step: 1,
    insuranceMemberId: null,
    showSelectAllImageError: false,
    selectedImages: [],
    showMedicaidCoveredMsg: false,
    isMedicaidCovered: false
  }

  showImageUploadForm = (isMedicaidInsurance) => {
    this.setState({ showImageUpload: true, isMedicaidCovered: isMedicaidInsurance })
  }

  showMedicaidCovered = () => {
    this.setState({ showMedicaidCoveredMsg: true })
  }

  payWithCard = () => {
    const { match } = this.props
    return this.props.history.push(
      `/patient/consults/${match.params.consultId}/cardDetails`
    )
  }

  compressImage = async files => {
    let imageFiles = files
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      onProgress: data => {
        // console.log(data)
      },
    }

    const newFiles = imageFiles.map(async file => {
      try {
        const compressedFile = imageCompression(file.data, options)
        return compressedFile
      } catch (error) {
        console.log(error)
      }
    })

    const result = await Promise.all(newFiles)
    return result
  }

  sanitizeIssuePicFiles = files => {
    const sanitizedPics = files.map(file => {
      if (file.name) {
        // Image File (From Device)
        const issuePicFile = file

        return issuePicFile
      } else if (!file.name) {
        // Image Blob (From Webcam)
        const issuePicFile = file

        issuePicFile.name = file.name
        issuePicFile.lastModified = Date.now()
        issuePicFile.lastModifiedDate = new Date()

        return issuePicFile
      }

      // const sanitizedPics = files.map(file => {
      //   if (file.data.name) {
      //     // Image File (From Device)
      //     const issuePicFile = file.data

      //     return issuePicFile
      //   } else if (!file.data.name) {
      //     // Image Blob (From Webcam)
      //     const issuePicFile = file.data

      //     issuePicFile.name = file.name
      //     issuePicFile.lastModified = Date.now()
      //     issuePicFile.lastModifiedDate = new Date()

      //     return issuePicFile
      //   }
      // })
    })
    return sanitizedPics
  }

  submit = async () => {
    this.setState({
      isSubmitting: true,
      isError: false,
      showSelectAllImageError: false,
    })

    const { allConsults, match, handleConsultUpdate, queryValues } = this.props
    const { insuranceMemberId, selectedImages, isMedicaidCovered } = this.state
    const files = selectedImages

    const selectConsult = allConsults.filter(
      consult => consult.id === match.params.consultId
    )

    const totalFiles = isMedicaidCovered ? 5 : 3;

    if (
      selectConsult &&
      selectConsult.length &&
      files &&
      files.length === totalFiles &&
      (insuranceMemberId && insuranceMemberId != "")
    ) {
      const compressedImages = await this.compressImage(files)

      const insurancePics = this.sanitizeIssuePicFiles(compressedImages)
      const bucket = process.env.REACT_APP_BUCKET_NAME

      let file1 = null
      let file2 = null
      let file3 = null
      let file4 = null
      let file5 = null

      let url1 = null
      let url2 = null
      let url3 = null
      let url4 = null
      let url5 = null

      if (insurancePics[0]) {
        const { name, type: mimeType2 } = insurancePics[0]
        const [, , , extension2] = /([^.]+)(\.(\w+))?$/.exec(name)
        const key2 = [uuid(), extension2].filter(x => !!x).join(".")

        url1 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key2}`
        file1 = {
          region: "us-east-1",
          bucket,
          key: `public/${key2}`,
          mimeType: mimeType2,
          localUri: insurancePics[0],
        }
      }

      if (insurancePics[1]) {
        const { name, type: mimeType3 } = insurancePics[1]
        const [, , , extension3] = /([^.]+)(\.(\w+))?$/.exec(name)
        const key3 = [uuid(), extension3].filter(x => !!x).join(".")

        url2 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key3}`
        file2 = {
          region: "us-east-1",
          bucket,
          key: `public/${key3}`,
          mimeType: mimeType3,
          localUri: insurancePics[1],
        }
      }

      if (insurancePics[2]) {
        const { name, type: mimeType4 } = insurancePics[2]
        const [, , , extension4] = /([^.]+)(\.(\w+))?$/.exec(name)
        const key4 = [uuid(), extension4].filter(x => !!x).join(".")

        url3 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key4}`
        file3 = {
          region: "us-east-1",
          bucket,
          key: `public/${key4}`,
          mimeType: mimeType4,
          localUri: insurancePics[2],
        }
      }

      if (insurancePics[3]) {
        const { name, type: mimeType4 } = insurancePics[3]
        const [, , , extension4] = /([^.]+)(\.(\w+))?$/.exec(name)
        const key5 = [uuid(), extension4].filter(x => !!x).join(".")

        url4 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key5}`
        file4 = {
          region: "us-east-1",
          bucket,
          key: `public/${key5}`,
          mimeType: mimeType4,
          localUri: insurancePics[3],
        }
      }

      if (insurancePics[4]) {
        const { name, type: mimeType4 } = insurancePics[4]
        const [, , , extension4] = /([^.]+)(\.(\w+))?$/.exec(name)
        const key6 = [uuid(), extension4].filter(x => !!x).join(".")

        url5 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key6}`
        file5 = {
          region: "us-east-1",
          bucket,
          key: `public/${key6}`,
          mimeType: mimeType4,
          localUri: insurancePics[4],
        }
      }

      const variables = {
        id: selectConsult[0].id,
        insuranceFile1: file1,
        insuranceFile2: file2,
        drivingLicenseFile: file3,
        insuranceUrl1: url1,
        insuranceUrl2: url2,
        drivingLicenseUrl: url3,
        insuranceMemberId: insuranceMemberId,
        medicaidRefferalFile1: file4,
        medicaidRefferalFile2: file5,
        medicaidRefferalUrl1: url4,
        medicaidRefferalUrl2: url5,
      }

      try {
        const data = await updateInsurancePics(variables)
        let updatedConsult = data.data.updateConsult
        delete updatedConsult.id
        this.setState({ isSubmitting: false, isError: false })

        const { doctorId, state } = updatedConsult

        try {
          await sendConsultNotification({
            doctorId,
            state,
            queryValues,
          })
        } catch (e) {
          // console.error(e) // Handle sendConsultNotification errors silently
        }

        handleConsultUpdate(selectConsult[0].id, updatedConsult)
      } catch (e) {
        console.error(e)

        Auth.currentSession()
          .then(currentSession => {
            let data = null
            if (
              currentSession &&
              currentSession.idToken &&
              currentSession.idToken.payload
            ) {
              const payload = currentSession.idToken.payload
              data = {
                data: JSON.stringify(e),
                consultName: payload.name,
              }
            } else {
              data = {
                data: JSON.stringify(e),
                consultName: String(new Date()),
              }
            }
            fetch(process.env.REACT_APP_LOG_URL, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              mode: "no-cors", // no-cors, cors, *same-origin
              body: JSON.stringify(data), // body data type must match "Content-Type" header
            })
              .then(response => {
                return response.text()
              })
              .then(result => {
                // console.log(result)
              })
              .catch(err => {
                // console.log(err)
              })
          })
          .catch(error => {
            // console.log(error)
          })

        this.setState({ isSubmitting: false, isError: true })
      }
    } else {
      this.setState({
        isSubmitting: false,
        showSelectAllImageError: true,
      })
    }
  }

  onContinuation = () => {
    this.setState({ step: this.state.step + 1 })
  }

  setMemberId = event => {
    this.setState({
      insuranceMemberId: event.target.value,
    })
  }

  setSelectedImages = images => {
    this.setState({ selectedImages: images })
  }

  render() {
    const { classes, allConsults, match } = this.props

    const {
      showImageUpload,
      isError,
      isSubmitting,
      step,
      insuranceMemberId,
      showSelectAllImageError,
      selectedImages,
      showMedicaidCoveredMsg,
      isMedicaidCovered
    } = this.state

    const selectConsult = allConsults.filter(
      consult => consult.id === match.params.consultId
    )

    const consultFor =
      selectConsult && selectConsult.length && selectConsult[0].consultFor

    const totalFiles = isMedicaidCovered ? 5 : 3;

    return (
      <Fragment>
        {isError && (
          <Notifications
            variant="error"
            message={`OOPS! Something went wrong while uploading insurance details. Please try again. Contact helpdesk@derm.io if you have any issues.`}
          />
        )}

        {!isSubmitting ? (
          <Paper elevation={2} className={classes.imageUpload}>
            {!showImageUpload && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: 10,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div>
                  {!showMedicaidCoveredMsg ? < Typography gutterBottom>
                    Will you be using insurance for this consultation or do you
                    prefer direct payment?
                  </Typography> : <Typography gutterBottom >
                    Are you covered under a Medicaid plan/program? If so, do you have the required referral?
                    <br />
                    <Typography variant="caption" display="block" gutterBottom>
                      (Please be aware that if your plan requires a referral and your provider doesn’t have it
                      on file, the consult will be closed until the correct referral is obtained)
                    </Typography>
                  </Typography>}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {showMedicaidCoveredMsg ?
                      <>
                        <Button
                          type="button"
                          onClick={() => { this.showImageUploadForm(true) }}
                          disabled={isSubmitting}
                          color="primary"
                          className={classes.btn}
                        >
                          Yes
                        </Button>
                        <Button
                          type="button"
                          onClick={() => { this.showImageUploadForm(false) }}
                          disabled={isSubmitting}
                          color="primary"
                          className={classes.btn}
                        >
                          No
                        </Button>
                        <Button
                          type="button"
                          onClick={() => this.payWithCard()}
                          disabled={isSubmitting}
                          color="primary"
                          className={classes.btn}
                          size="large"
                        >
                          Direct payment
                        </Button>
                      </> :
                      <>
                        <Button
                          type="button"
                          onClick={this.showMedicaidCovered}
                          disabled={isSubmitting}
                          color="primary"
                          className={classes.btn}
                        >
                          Insurance
                        </Button>

                        <Button
                          type="button"
                          onClick={() => this.payWithCard()}
                          disabled={isSubmitting}
                          color="primary"
                          className={classes.btn}
                          size="large"
                        >
                          Direct payment
                        </Button></>}
                  </div>
                </div>
              </div>
            )}
            {showImageUpload && (
              <>
                {step === 1 && (
                  // <Paper elevation={2} className={classes.imageUpload}>
                  <MemberIdInput
                    memberId={insuranceMemberId}
                    setMemberId={this.setMemberId}
                    onContinuation={this.onContinuation}
                    consultFor={consultFor}
                  />
                  // </Paper>
                )}

                {step === 2 && (
                  <UppyDashoard
                    uppyId={"InsuranceFront"}
                    label={`Please upload or take a photo of the front of ${consultFor === "others" ? "their" : "your"
                      } insurance card`}
                    setSelectedImages={this.setSelectedImages}
                    selectedImages={selectedImages}
                    step={step}
                    isSubmitting={isSubmitting}
                    onContinuation={this.onContinuation}
                    submit={this.submit}
                    totalFiles={totalFiles}
                  />
                )}

                {step === 3 && (
                  <UppyDashoard
                    uppyId={"InsuranceBack"}
                    label={`Now upload or take a photo of the back of ${consultFor === "others" ? "their" : "your"
                      } insurance card`}
                    setSelectedImages={this.setSelectedImages}
                    selectedImages={selectedImages}
                    step={step}
                    isSubmitting={isSubmitting}
                    onContinuation={this.onContinuation}
                    submit={this.submit}
                    totalFiles={totalFiles}
                  />
                )}

                {step === 4 && (
                  <UppyDashoard
                    uppyId={"driversLicenseFront"}
                    label={`Now upload or take a photo of the front of ${consultFor === "others" ? "their" : "your"
                      } drivers license`}
                    setSelectedImages={this.setSelectedImages}
                    selectedImages={selectedImages}
                    step={step}
                    isSubmitting={isSubmitting}
                    onContinuation={this.onContinuation}
                    submit={this.submit}
                    totalFiles={totalFiles}
                  />
                )}

                {step === 5 && (
                  <UppyDashoard
                    uppyId={"photoOfMedicaid"}
                    label={`Please upload or take a photo of the referral of Medicaid`}
                    setSelectedImages={this.setSelectedImages}
                    selectedImages={selectedImages}
                    step={step}
                    isSubmitting={isSubmitting}
                    onContinuation={this.onContinuation}
                    submit={this.submit}
                    totalFiles={totalFiles}
                  />
                )}


                {step === 6 && (
                  <UppyDashoard
                    uppyId={"photoIDofMedicaid"}
                    label={`Now upload or take a photo of photo ID of the referral`}
                    setSelectedImages={this.setSelectedImages}
                    selectedImages={selectedImages}
                    step={step}
                    isSubmitting={isSubmitting}
                    onContinuation={this.onContinuation}
                    submit={this.submit}
                    totalFiles={totalFiles}
                  />
                )}
              </>
            )}
          </Paper>
        ) : (
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="caption">
                Thank you! Please wait while we process your insurance
                details.... DO NOT GO BACK OR RELOAD THE PAGE IN THE MEANTIME.
              </Typography>
              <LinearProgress
                classes={{
                  colorPrimary: classes.linearColorPrimary,
                  barColorPrimary: classes.linearBarColorPrimary,
                }}
              />
            </CardContent>
          </Card>
        )
        }
      </Fragment>
    )
  }
}

export default withStyles(styles)(ImageSelectionForm)
