import React, { Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import LinearProgress from "@material-ui/core/LinearProgress"

import PharmacyForm from "../Forms/Pharmacy/Pharmacy"

import fetchPharmaciesList from "../../queries/fetchPharmicesList"

const ExpansionPanel = withStyles({
  root: {
    margin: "10px 0",
    width: "100%",
    minWidth: 275,
    overflow: "auto",
  },
  expanded: {
    width: "100%",
    minWidth: 275,
    overflow: "auto",
  },
})(MuiExpansionPanel)

const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing.unit,
  },
  pageHeading: {
    minWidth: 275,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    paddingBottom: 6,
  },
  headerCard: {
    minWidth: 275,
    display: "flex",
    flexFlow: "row wrap",
    padding: `${theme.spacing.unit}px 0px`,
    justifyContent: "space-around",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    display: "flex",
    flexFlow: "column wrap",
  },
  column: {
    flexBasis: "75%",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardActionBtn: {
    marginLeft: "auto",
  },
  expansionPanelSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

class PharmacyDetails extends Component {
  state = {
    previousPharmacy: null,
  }

  componentDidMount = async () => {
    const { allConsults, match } = this.props

    const selectedConsult = allConsults.find(
      consult => consult.id === match.params.consultId
    )

    if (selectedConsult && selectedConsult.pharmacy_NCPDPID) {
      try {
        const data = await fetchPharmaciesList({
          name: "",
          zipcode: "",
          pharmacy_NCPDPID: selectedConsult.pharmacy_NCPDPID,
        })

        const { allPharmacies } = data.data

        if (allPharmacies.length) {
          return this.setState({
            previousPharmacy: allPharmacies[0],
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  render() {
    const {
      classes,
      allConsults,
      handleConsultUpdate,
      match,
      practiceDetails,
      isFromDermio,
    } = this.props

    const selectedConsult = allConsults.find(
      consult => consult.id === match.params.consultId
    )

    const { previousPharmacy } = this.state

    if (!selectedConsult) {
      return (
        <Redirect
          to={`/patient/dashboard${
            isFromDermio
              ? "?from=dermio"
              : `?practiceId=${practiceDetails && practiceDetails.id}`
          }`}
        />
      )
    }

    return (
      <div>
        <ExpansionPanel elevation={5} defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Typography variant="caption">Edit Pharmacy Info!</Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails className={classes.details}>
            {previousPharmacy ? (
              <Fragment>
                <Typography gutterBottom>
                  The pharmacy selected by you previously:
                </Typography>
                <Fragment key={previousPharmacy.NCPDPID}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div className={classes.column}>
                        <Typography className={classes.heading}>
                          {previousPharmacy.Name}
                        </Typography>
                      </div>
                      <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>
                          {previousPharmacy.Address1}
                        </Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                      <div className={classes.column}>
                        <Typography variant="body1">
                          City: {previousPharmacy.City}
                        </Typography>
                        <Typography variant="body1">
                          State: {previousPharmacy.State}
                        </Typography>
                        <Typography variant="body1">
                          Zipcode: {previousPharmacy.Zip}
                        </Typography>
                      </div>
                    </ExpansionPanelDetails>
                    <Divider />
                  </ExpansionPanel>
                </Fragment>
              </Fragment>
            ) : (
              <LinearProgress
                classes={{
                  colorPrimary: classes.linearColorPrimary,
                  barColorPrimary: classes.linearBarColorPrimary,
                }}
              />
            )}

            <Typography variant="caption">
              Drop us a mail at helpdesk@derm.io if you cannot find the pharmacy
              of your choice.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <PharmacyForm
          consult={selectedConsult}
          allConsults={allConsults}
          handleConsultUpdate={handleConsultUpdate}
        />
      </div>
    )
  }
}

export default withStyles(styles)(PharmacyDetails)
