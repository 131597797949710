import React, { Component } from "react"
import Storage from "@aws-amplify/storage"

Storage.configure({
  bucket: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_BUCKET_REGION,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
})

class AwsImageComponent extends Component {
  state = {
    loading: true,
    url: "",
  }

  componentDidMount = () => {
    const key = this.props.src.split("/").pop()
    Storage.get(key, { level: "public", expires: 60 })
      .then(result => {
        // console.log("key "+key+" result: ",result)
        this.setState({ loading: false, url: result })
      })
      .catch(err => console.log(err))
  }

  render() {
    const { loading, url } = this.state
    const { onClick, componentFrom, styles, alt } = this.props

    if (loading) {
      return <h6>loading...</h6>
    }

    if (componentFrom === "chat") {
      return (
        <img
          className="chat__image"
          src={url}
          alt=""
          onClick={() => { onClick(url) }}
        />
      )
    } else if (componentFrom === "practiceLogo") {
      return <img style={styles} src={url} alt={alt} />
    } else {
      return (
        <img
          src={url}
          style={{
            height: "30%",
            width: "30%",
            boxShadow: "5px 3px 10px #202124",
            marginTop: 15,
            marginRight: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            onClick(url)
          }}
          alt=""
        />
      )
    }
  }
}

export default AwsImageComponent
