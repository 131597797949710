import gql from "graphql-tag"
import Appsync from "../config"
import encryptConsult from "../utils/encryptConsult"

const client = Appsync()

const mutationUpdateConsults = gql`
  mutation updateConsult(
    $insuranceFile1: S3ObjectInput
    $id: ID!
    $insuranceFile2: S3ObjectInput
    $drivingLicenseFile: S3ObjectInput
    $insuranceUrl1: String
    $insuranceUrl2: String
    $drivingLicenseUrl: String
    $insuranceMemberId: String
    $medicaidRefferalFile1: S3ObjectInput
    $medicaidRefferalFile2: S3ObjectInput
    $medicaidRefferalUrl1: String
    $medicaidRefferalUrl2: String
  ) {
    updateConsult(
      id: $id
      insuranceFile1: $insuranceFile1
      insuranceFile2: $insuranceFile2
      drivingLicenseFile: $drivingLicenseFile
      insuranceUrl1: $insuranceUrl1
      insuranceUrl2: $insuranceUrl2
      drivingLicenseUrl: $drivingLicenseUrl
      insuranceMemberId: $insuranceMemberId
      status: "pending",
      medicaidRefferalFile1: $medicaidRefferalFile1,
      medicaidRefferalFile2: $medicaidRefferalFile2,
      medicaidRefferalUrl1: $medicaidRefferalUrl1,
      medicaidRefferalUrl2: $medicaidRefferalUrl2,
    ) {
      id
      insuranceFile1 {
        key
      }
      insuranceFile2 {
        key
      }
      drivingLicenseFile {
        key
      }
      insuranceUrl1
      insuranceUrl2
      drivingLicenseUrl
      state
      doctorId
	    medicaidRefferalUrl1
	    medicaidRefferalUrl2
    }
  }
`

const updateConsults = async variables => {
  const encryptedConsultDetails = await encryptConsult(variables)

  const updatedConsult = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateConsults,
      variables: encryptedConsultDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsult
}

export default updateConsults
