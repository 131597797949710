import React from "react"
import Notifications from "../../Notifications/Notifications"

const PharmacyError = ({ errorType, values, searchPharmacyBy }) => {
  if (errorType === "No Pharmacies") {
    if (searchPharmacyBy === "pharmacyZipCode") {
      return (
        <Notifications
          variant="error"
          message={`Sorry! There are no pharmacies available at zipcode: ${values.pharmacyZipCode}. Please try again with a different zipcode. If you cannot find the pharmacy of your choice after multiple attempts, please contact us at helpdesk@derm.io.`}
        />
      )
    }

    if (searchPharmacyBy === "pharmacyName") {
      return (
        <Notifications
          variant="error"
          message={`Sorry! There are no pharmacies available by the name of ${values.pharmacyName.toUpperCase()}. Please try again with a different pharmacy name. If you cannot find the pharmacy of your choice after multiple attempts, please contact us at helpdesk@derm.io.`}
        />
      )
    }
  }

  if (errorType === "Fetch Error") {
    return (
      <Notifications
        variant="error"
        message={`OOPS! Something went wrong while fetching the pharmacies. Please try again. Contact helpdesk@derm.io if you have any issues.`}
      />
    )
  }

  if (errorType === "Update Error") {
    return (
      <Notifications
        variant="error"
        message={`OOPS! Something went wrong while updating your details. Please try again. Contact helpdesk@derm.io if you have any issues.`}
      />
    )
  }

  if (errorType === "Dob Error") {
    return (
      <Notifications
        variant="error"
        message={`Your Date of Birth is invalid. Please check for errors.`}
      />
    )
  }
}

export default PharmacyError
