import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query VideoCall($id: ID!) {
    VideoCall(id: $id) {
      id
      doctorId
      patientId
      consultId
      scheduledDateString
      callStatus
      createdAt
      scheduledDateTime
      doctorName
      consultName
      startTime
      endTime
      duration
    }
  }
`

const fetchVideoCallDetails = async videocallId => {
  // console.log(videocallId)
  const variables = { id: videocallId }

  const videoCallDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return videoCallDetails
}

export default fetchVideoCallDetails
