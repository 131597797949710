import React, { Component, Fragment } from "react"
import { Field } from "formik"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  field: {
    // margin: `${theme.spacing.unit * 2}px 0px`
  },
  formActions: {
    display: "flex",
  },
  divider: {
    // margin: `${theme.spacing.unit * 2}px 0px`
  },
  continueBtn: {
    marginLeft: "auto",
    color: "#36c2ea",
  },
})

const CustomEmailComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      type={field.name}
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
    />
  )
}

class EmailForm extends Component {
  onContinuation = () => {
    const { setFieldTouched, validateForm, goNext } = this.props

    validateForm().then(errors => {
      if (!errors.email) {
        return goNext()
      } else {
        return setFieldTouched("email", true)
      }
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <Typography gutterBottom>
          Please enter your email and then click continue to enter your credit
          card details.
        </Typography>
        <Field
          name="email"
          component={CustomEmailComponent}
          label="Email"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.field}
          variant="outlined"
          margin="normal"
          required
        />
        {/* <Divider className={classes.divider}/> */}
        <div className={classes.formActions}>
          <Button
            type="button"
            color="primary"
            className={classes.continueBtn}
            onClick={() => this.onContinuation()}
            size="large"
          >
            Continue
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(EmailForm)
