import gql from "graphql-tag"
import Appsync from "../config"

const client = Appsync()

const mutationCreateVideoCallSchedule = gql`
mutation createVideoCall(
$doctorId: ID!
$patientId: ID!
$scheduledDateTime: String!
$scheduledDateString: String!
$consultId: ID!
$consultName: String
){
  createVideoCall(
    doctorId: $doctorId,
    consultId: $consultId,
    patientId: $patientId,
	  scheduledDateTime: $scheduledDateTime,
    scheduledDateString: $scheduledDateString
    consultName: $consultName
    inviteStatus: "invited"
  ){
    id
  }

    updateConsult(
      id: $consultId,
      isVideoCallConsult: true,
      status: "pending"
    ){
      id
    }
}
`

const createVideoCallSchedule = async variables => {

  const createVideoCallSchedule = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateVideoCallSchedule,
      variables: variables,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return createVideoCallSchedule
}

export default createVideoCallSchedule
