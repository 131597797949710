import React, { Component } from "react"
import PropTypes from "prop-types"
import Auth from "@aws-amplify/auth"
import Storage from "@aws-amplify/storage"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import dermioLogo from "../../assets/img/dermio-logo.svg"

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  logoLink: {
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: "white",
    },
  },
  appBar: {
    backgroundColor: "#fafafa",
  },
  dashboardBtn: {
    marginRight: theme.spacing.unit + 10,
    marginLeft: "auto",
    color: "#36c2ea",
  },
  logo: {
    height: "1.5rem",
  },
  practiceName: {
    color: "#36c2ea",
  },
})

class Logo extends Component {
  state = {
    practiceLogo: null,
    isFetchingPracticeLogo: false,
  }

  componentDidMount = () => {
    const {
      practiceDetails: { file },
    } = this.props

    if (file) {
      this.setState({ isFetchingPracticeLogo: true }, async () => {
        try {
          const practiceLogo = await Storage.get(file.key.split("/")[1])

          this.setState({ practiceLogo, isFetchingPracticeLogo: false })
        } catch (e) {
          console.error(e)
          this.setState({ practiceLogo: null, isFetchingPracticeLogo: false })
        }
      })
    }
  }

  render() {
    const {
      practiceDetails: { name: practiceName, website },
      queryValues,
      isPracticeValid,
      classes,
    } = this.props

    const { practiceLogo, isFetchingPracticeLogo } = this.state

    if (queryValues.practiceId && isPracticeValid) {
      if (isFetchingPracticeLogo) {
        return null
      }

      return (
        <Typography variant="h6">
          <a
            href={
              website.split("/").indexOf("https:") !== -1
                ? website
                : `https://${website}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className={classes.logoLink}
          >
            {practiceLogo ? (
              <img
                src={practiceLogo}
                alt={`${practiceName} Logo`}
                id="nav-logo"
                className={classes.logo}
              />
            ) : (
                <Typography variant="button" className={classes.practiceName}>
                  {practiceName}
                </Typography>
              )}
          </a>
        </Typography>
      )
    }

    return (
      <Typography variant="h6" color="inherit">
        <a
          href="https://dermio.com"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.logoLink}
        >
          <img
            src={dermioLogo}
            alt={`Dermio Logo`}
            id="nav-logo"
            className={classes.logo}
          />
        </a>
      </Typography>
    )
  }
}

class NavBar extends Component {
  signOut = async () => {
    const { practiceDetails, isFromDermio } = this.props
    localStorage.removeItem("practiceDetails");
    Auth.signOut({ global: true })
      .then(data => {
        window.location.hash = `/patient/dashboard${isFromDermio ? "?from=dermio" : `?practiceId=${practiceDetails && practiceDetails.id}`}`;
        console.log(data);
      })
      .catch(err => console.log(err))
  }

  render() {
    const { classes, practiceDetails, isFromDermio } = this.props
    // console.log(isFromDermio)
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            {!Object.keys(practiceDetails).length ? null : (
              <Logo {...this.props} />
            )}

            <Button
              color="primary"
              className={classes.dashboardBtn}
              onClick={() => this.props.history.push(`/patient/dashboard${isFromDermio ? "?from=dermio" : `?practiceId=${practiceDetails && practiceDetails.id}`}`)}
            >
              DashBoard
            </Button>
            <Button color="secondary" onClick={() => this.signOut()}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  practiceDetails: PropTypes.object.isRequired,
  queryValues: PropTypes.object.isRequired,
  isPracticeValid: PropTypes.bool.isRequired,
}

export default withStyles(styles)(NavBar)
