import gql from "graphql-tag"
import { Auth } from "aws-amplify"
import { v4 as uuid } from "uuid"
import Appsync from "../config"
import encryptConsult from "../utils/encryptConsult"
import imageCompression from "browser-image-compression"

const client = Appsync()

const mutationCreateConsults = gql`
  mutation Create(
    $id: String
    $patientId: String!
    $consultFor: String
    $allergy: String
    $medication: String
    $alchohol: Boolean
    $smoker: Boolean
    $cigrattes_per_day: Int
    $drinks_per_week: Int
    $gender: String
    $age: String
    $state: String
    $patientname: String
    $firstname: String
    $lastname: String
    $createdAt: String
    $status: String
    $surgeries: String
    $medical_issues: String
    $currentMedicalIssue: String
    $file1: S3ObjectInput
    $url1: String
    $file2: S3ObjectInput
    $url2: String
    $file3: S3ObjectInput
    $url3: String
    $file4: S3ObjectInput
    $url4: String
    $doctorId: String
    $practiceId: ID
    $source: String
    $phone_number: String!
    $longitude: String
    $latitude: String
    $unlicensedDocName: String
    $isVideoCallConsult: Boolean
  ) {
    createConsult(
      id: $id
      source: $source
      patientId: $patientId
      consultFor: $consultFor
      allergy: $allergy
      createdAt: $createdAt
      medication: $medication
      alchohol: $alchohol
      age: $age
      gender: $gender
      state: $state
      smoker: $smoker
      patientname: $patientname
      firstname: $firstname
      lastname: $lastname
      cigrattes_per_day: $cigrattes_per_day
      drinks_per_week: $drinks_per_week
      surgeries: $surgeries
      status: $status
      doctorId: $doctorId
      practiceId: $practiceId
      url1: $url1
      url2: $url2
      url3: $url3
      url4: $url4
      medical_issues: $medical_issues
      currentMedicalIssue: $currentMedicalIssue
      file1: $file1
      file2: $file2
      file3: $file3
      file4: $file4
      longitude: $longitude
      latitude: $latitude
      phone_number: $phone_number
      unlicensedDocName: $unlicensedDocName
      isVideoCallConsult: $isVideoCallConsult
    ) {
      id
      email
      consultFor
      cardToken
      cardDeclined
      doctorId
      practiceId
      phone_number
      patientId
      status
      state
      pharmacy_NCPDPID
      patientDob
      allergy
      age
      patientname
      firstname
      lastname
      alchohol
      cigrattes_per_day
      drinks_per_week
      gender
      medical_issues
      medication
      surgeries
      createdAt
      url1
      url2
      url3
      url4
      chats {
        createdAt
        senderRole
        senderId
        receiverId
        message
        url
      }
      hasPdf
    }
  }
`

const finalval = (values, key) => {
  const val = values[key]

  if (key === "cigarettesPerDay" || key === "drinksPerWeek" || key === "age") {
    return val || 0
  }

  return val || "NO"
}

const removePlusOne = phoneNum => {
  let phoneStr =
    typeof phoneNum === "string" ? phoneNum : Number(phoneNum).toString()

  if (phoneStr.length > 10) {
    return phoneStr.slice(phoneStr.length - 10)
  }

  return phoneStr
}

const sanitizeIssuePicFiles = files => {
  // console.log("sanitizeIssuePicFiles files: ", files)
  const sanitizedPics = files.map(file => {
    if (file.name) {
      // Image File (From Device)
      const issuePicFile = file

      return issuePicFile
    } else if (!file.name) {
      // Image Blob (From Webcam)
      const issuePicFile = file

      issuePicFile.name = file.name
      issuePicFile.lastModified = Date.now()
      issuePicFile.lastModifiedDate = new Date()

      return issuePicFile
    }

    // if (file.data.name) {
    //   // Image File (From Device)
    //   const issuePicFile = file.data

    //   return issuePicFile
    // } else if (!file.data.name) {
    //   // Image Blob (From Webcam)
    //   const issuePicFile = file.data

    //   issuePicFile.name = file.name
    //   issuePicFile.lastModified = Date.now()
    //   issuePicFile.lastModifiedDate = new Date()

    //   return issuePicFile
    // }
  })

  return sanitizedPics
}

const compressImage = async files => {
  let imageFiles = files
  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    onProgress: data => {
      // console.log(data)
    },
  }

  const newFiles = imageFiles.map(async file => {
    try {
      const compressedFile = imageCompression(file.data, options)
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  })

  const result = await Promise.all(newFiles)
  return result
}

const createConsult = async ({
  values,
  createConsultFor,
  queryValues,
  isPracticeValid,
  isFromDermio,
  allConsults,
  practiceDetails,
}) => {
  // Compress images first
  const originalImages = values.issuePics
  const compressedImages = await compressImage(originalImages)

  const bucket = process.env.REACT_APP_BUCKET_NAME

  const issuePics = sanitizeIssuePicFiles(compressedImages)

  // There will atleast be a single image file to upload.
  const { name, type: mimeType1 } = issuePics[0]
  const [, , , extension1] = /([^.]+)(\.(\w+))?$/.exec(name)
  const key1 = [uuid(), extension1].filter(x => !!x).join(".")
  const url1 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key1}`

  // The remaining three pics are optional.
  let file2 = null
  let file3 = null
  let file4 = null

  let url2 = null
  let url3 = null
  let url4 = null

  if (issuePics[1]) {
    const { name, type: mimeType2 } = issuePics[1]
    const [, , , extension2] = /([^.]+)(\.(\w+))?$/.exec(name)
    const key2 = [uuid(), extension2].filter(x => !!x).join(".")

    url2 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key2}`
    file2 = {
      region: "us-east-1",
      bucket,
      key: `public/${key2}`,
      mimeType: mimeType2,
      localUri: issuePics[1],
    }
  }

  if (issuePics[2]) {
    const { name, type: mimeType3 } = issuePics[2]
    const [, , , extension3] = /([^.]+)(\.(\w+))?$/.exec(name)
    const key3 = [uuid(), extension3].filter(x => !!x).join(".")

    url3 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key3}`
    file3 = {
      region: "us-east-1",
      bucket,
      key: `public/${key3}`,
      mimeType: mimeType3,
      localUri: issuePics[2],
    }
  }

  if (issuePics[3]) {
    const { name, type: mimeType4 } = issuePics[3]
    const [, , , extension4] = /([^.]+)(\.(\w+))?$/.exec(name)
    const key4 = [uuid(), extension4].filter(x => !!x).join(".")

    url4 = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key4}`
    file4 = {
      region: "us-east-1",
      bucket,
      key: `public/${key4}`,
      mimeType: mimeType4,
      localUri: issuePics[3],
    }
  }

  let payload = null

  try {
    const currentSession = await Auth.currentSession()
    payload = currentSession.idToken.payload
  } catch (e) {
    throw new Error("Auth Error")
  }

  const patientId = payload["sub"]
  const phone_number = removePlusOne(payload["phone_number"])
  const patientname = payload["name"]
  const patientnameArr = patientname.toLowerCase().split(" ")
  const firstname = patientnameArr[0]
  const lastname = patientnameArr[1]

  // console.log("queryValues ", queryValues)
  // console.log("isPracticeValid ", isPracticeValid)
  // console.log("practiceDetails ", queryValues)

  const practiceId =
    (queryValues.practiceId && practiceDetails && practiceDetails.id) || null
  const source =
    (queryValues.practiceId && isPracticeValid && practiceDetails.website) ||
    "dermio.com"
  let status = "initialized"
  let doctorId = null
  let id = null
  let createdAt = new Date().toISOString()

  if (source === "dermio.com") {
    doctorId = `dermio-${uuid()}`
  }

  if (queryValues.practiceId && isPracticeValid && values.doctorId) {
    doctorId = values.doctorId
  }

  // console.log("Doctor id ", doctorId)

  if (allConsults.length === 1 && !allConsults[0].url1) {
    createdAt = allConsults[0].createdAt || new Date().toISOString()
    id = allConsults[0].id
  }

  let consultDetails = {
    id,
    doctorId,
    practiceId,
    status,
    source,
    patientId,
    phone_number,
    gender: finalval(values, "gender"),
    age: finalval(values, "age"),
    state: finalval(values, "state"),
    createdAt,
    allergy: finalval(values, "allergy"),
    medication: finalval(values, "medication"),
    cigrattes_per_day: finalval(values, "cigarettesPerDay"),
    drinks_per_week: finalval(values, "drinksPerWeek"),
    surgeries: finalval(values, "surgeries"),
    medical_issues: finalval(values, "medicalIssues"),
    currentMedicalIssue: finalval(values, "currentMedicalIssue"),
    url1,
    url2,
    url3,
    url4,
    file1: {
      region: "us-east-1",
      bucket,
      key: `public/${key1}`,
      mimeType: mimeType1,
      localUri: issuePics[0],
    },
    file2,
    file3,
    file4,
    isVideoCallConsult: false,
  }

  if (createConsultFor === "me") {
    consultDetails = {
      ...consultDetails,
      patientname: patientname,
      firstname: firstname,
      lastname: lastname,
    }
  } else {
    const OtherPatientName = finalval(values, "patientname")
    const OtherPatientNameArr = OtherPatientName.toLowerCase().split(" ")
    const othersFirstName = OtherPatientNameArr[0]
    const othersLastName = OtherPatientNameArr[1]

    // console.log("othersFirstName", othersFirstName)
    // console.log("othersLastName", othersLastName)

    consultDetails = {
      ...consultDetails,
      consultFor: "others", // Important
      patientname: OtherPatientName,
      firstname: othersFirstName,
      lastname: othersLastName,
    }
  }
  // console.log("consultDetails ", consultDetails)
  const encryptedConsultDetails = await encryptConsult(consultDetails)

  const newConsult = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateConsults,
      variables: encryptedConsultDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return newConsult
}

export default createConsult
