import gql from "graphql-tag"
import Appsync from "../config"
import encryptConsult from "../utils/encryptConsult"

const client = Appsync()

const mutationUpdateConsults = gql`
  mutation Create(
    $cardToken: String!
    $id: ID!
    $email: String!
    $stripeId: String!
    $chargeId: String
  ) {
    updateConsult(
      cardToken: $cardToken
      id: $id
      email: $email
      stripeId: $stripeId
      status: "pending"
      chargeId: $chargeId
    ) {
      id
      cardToken
      patientDob
      pharmacy_NCPDPID
      email
      state
      doctorId
      unlicensedDocName
    }
  }
`

const updateConsults = async variables => {
  const encryptedConsultDetails = await encryptConsult(variables)

  const updatedConsult = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateConsults,
      variables: encryptedConsultDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsult
}

const mutationUpdateWithCardDeclined = gql`
  mutation Create(
    $cardToken: String!
    $id: ID!
    $email: String!
    $stripeId: String!
    $cardDeclined: Boolean
    $chargeId: String
  ) {
    updateConsult(
      cardToken: $cardToken
      id: $id
      email: $email
      stripeId: $stripeId
      status: "pending"
      cardDeclined: $cardDeclined
      chargeId: $chargeId
    ) {
      id
      cardToken
      patientDob
      pharmacy_NCPDPID
      email
      state
      doctorId
      unlicensedDocName
    }
  }
`

export const updateReEnterPaymentConsults = async variables => {
  const encryptedConsultDetails = await encryptConsult(variables)

  const updatedConsult = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateWithCardDeclined,
      variables: encryptedConsultDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsult
}

export default updateConsults
