// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard"
import LibraryBooks from "@material-ui/icons/LibraryBooks"
// core components/views for Patient layout
import DashboardPage from "./views/Dashboard/Dashboard"
import ConsultsListPage from "./views/ConsultsList/ConsultsList"
import ConsultCreatePage from "./views/ConsultCreate/ConsultCreate"
import ConsultPage from "./components/Consult/Consult"
import CardDetailsPage from "./components/CardDetails/CardDetails"
import PharmacyDetailsPage from "./components/PharmacyDetails/PharmacyDetails"
import PharmacyDetailsEditPage from "./components/PharmacyDetails/PharmacyDetailsEdit"
import {
  VideoCallSchedule,
  VideoCallPage,
  VideoCallHistory,
} from "./components/VideoCall/VideoCall"
import InsuranceDetails from "./components/InsuranceDetails/InsuranceForm"

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    icon: Dashboard,
    layout: "/patient",
  },
  {
    path: "/consults",
    name: "Consults",
    component: ConsultsListPage,
    icon: LibraryBooks,
    layout: "/patient",
    routes: [
      {
        path: "/consults/:consultId/videoCallHistory",
        component: VideoCallHistory,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/video-call",
        component: VideoCallPage,
        layout: "/patient",
      },
      {
        path: "/consults/create",
        component: ConsultCreatePage,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId",
        component: ConsultPage,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/cardDetails",
        component: CardDetailsPage,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/edit",
        component: ConsultCreatePage,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/pharmacy",
        component: PharmacyDetailsPage,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/pharmacy/edit",
        component: PharmacyDetailsEditPage,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/schedule",
        component: VideoCallSchedule,
        layout: "/patient",
      },
      {
        path: "/consults/:consultId/insuranceDetails",
        component: InsuranceDetails,
        layout: "/patient",
      },
      // {
      //   path: "/consults/:consultId/show",
      //   component: PharmacyDetailsPage,
      //   layout: '/patient',
      // }
    ],
  },
  // {
  //   path: "/:WhereIsThat",
  //   component: <h1>NoMatch</h1>
  // }
]

export default routes
