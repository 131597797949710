import React, { Component } from "react"
import { Redirect } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"

import PharmacyForm from "../Forms/Pharmacy/Pharmacy"

const ExpansionPanel = withStyles({
  root: {
    margin: "10px 0",
    width: "100%",
    minWidth: 275,
    overflow: "auto",
  },
  expanded: {
    width: "100%",
    minWidth: 275,
    overflow: "auto",
  },
})(MuiExpansionPanel)

const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing.unit,
  },
  pageHeading: {
    minWidth: 275,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    paddingBottom: 6,
  },
  headerCard: {
    minWidth: 275,
    display: "flex",
    flexFlow: "row wrap",
    padding: `${theme.spacing.unit}px 0px`,
    justifyContent: "space-around",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    display: "flex",
    flexFlow: "column wrap",
  },
  column: {
    flexBasis: "75%",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardActionBtn: {
    marginLeft: "auto",
  },
  expansionPanelSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

class PharmacyDetails extends Component {
  render() {
    const {
      classes,
      allConsults,
      handleConsultUpdate,
      match,
      practiceDetails,
      isFromDermio,
    } = this.props

    const selectedConsult = allConsults.find(
      consult => consult.id === match.params.consultId
    )

    if (!selectedConsult) {
      return (
        <Redirect
          to={`/patient/dashboard${
            isFromDermio
              ? "?from=dermio"
              : `?practiceId=${practiceDetails && practiceDetails.id}`
          }`}
        />
      )
    }

    return (
      <div>
        <ExpansionPanel elevation={5} defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Typography variant="caption">Important!</Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails className={classes.details}>
            <Typography gutterBottom>
              Please choose a pharmacy of your choice so that the doctor can
              send your prescription after diagnosis.
            </Typography>

            <Typography gutterBottom>
              You will be able to chat with the doctor once you select your
              pharmacy.
            </Typography>

            <Typography variant="caption">
              Drop us a mail at helpdesk@derm.io if you cannot find the pharmacy
              of your choice.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <PharmacyForm
          consult={selectedConsult}
          allConsults={allConsults}
          handleConsultUpdate={handleConsultUpdate}
        />
      </div>
    )
  }
}

export default withStyles(styles)(PharmacyDetails)
