import gql from "graphql-tag"
import Appsync from "../config"

const client = Appsync()

const sendReEnteredCardNotificationQuery = gql`
  query consultNotification($region: String, $doctorId: String) {
    notification: sendReEnterCardNotification(
      region: $region
      doctorId: $doctorId
    ) {
      doctorId
    }
  }
`

export const sendReEnteredCardNotification = async consultDetails => {
  var variables = {}

  if (consultDetails && consultDetails.doctorId) {
    variables.doctorId = consultDetails.doctorId
  }

  if (consultDetails && consultDetails.state) {
    variables.region = consultDetails.state
  }

  const result = await client.hydrated().then(function(cl) {
    return cl.query({
      query: sendReEnteredCardNotificationQuery,
      variables,
      fetchPolicy: "no-cache",
    })
  })

  return result
}
