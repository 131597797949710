import React, { Component, Fragment } from "react"
import { Form, Formik } from "formik"
import { object, string, number, array } from "yup"
import { Analytics } from "aws-amplify"
import XRegExp from "xregexp"
import { Auth } from "aws-amplify"

import Uppy from "@uppy/core"
import Webcam from "@uppy/webcam"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "@uppy/webcam/dist/style.css"

import LinearProgress from "@material-ui/core/LinearProgress"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import BasicDetailsForm from "../BasicDetailsForm"
import ImageSelectionForm from "../ImageSelectionForm"
import PracticeDoctorsForm from "../PracticeDoctorsForm"
import createConsult from "../../../../queries/createConsult"
import ErrorNotifications from "../ErrrorNotifications"

const styles = theme => ({
  root: {
    display: "flex",
  },
  formControl: {
    marginTop: theme.spacing.unit * 3,
  },
  formLabel: {
    margin: 0,
  },
  group: {
    display: "flex",
    flexFlow: "row wrap",
  },
  formWrapper: {
    padding: theme.spacing.unit,
    backgroundColor: "rgba(251, 251, 251, 0.9)",
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

class SteppedFormForOthers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 0,
    }

    this.uppy = new Uppy({
      id: "uppy",
      autoProceed: false,
      restrictions: {
        maxFileSize: 52428800,
        maxNumberOfFiles: 4,
        minNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".gif", ".png", ".jpeg", "tiff"],
      },
      meta: {},
    }).use(Webcam, {
      id: "Webcam",
      countdown: false,
      modes: ["picture"],
      facingMode: "user",
      mirror: true,
      locale: {
        strings: {
          // Shown before a picture is taken when the `countdown` option is set.
          smile: "Smile!",
          // Used as the label for the button that takes a picture.
          // This is not visibly rendered but is picked up by screen readers.
          takePicture: "Take a picture",
          // Title on the “allow access” screen
          allowAccessTitle: "Please allow access to your camera",
          // Description on the “allow access” screen
          allowAccessDescription:
            "In order to take pictures with your camera, please allow camera access for this site.",
        },
      },
    })
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  submit = async (values, { setSubmitting, setStatus }) => {
    const {
      addNewConsult,
      createConsultFor,
      toggleConsultForOptionsVisibility,
      queryValues,
      isPracticeValid,
      practiceDetails,
      isFromDermio,
      allConsults,
      handleConsultUpdate,
    } = this.props

    toggleConsultForOptionsVisibility(true)

    try {
      const data = await createConsult({
        values,
        createConsultFor,
        queryValues,
        isPracticeValid,
        isFromDermio,
        allConsults,
        practiceDetails,
      })

      toggleConsultForOptionsVisibility(true)

      // Analytics.record("consult_creation_success")

      const consult = data.data.createConsult

      setSubmitting(false)

      if (allConsults.length === 1 && !allConsults[0].url1) {
        return handleConsultUpdate(consult.id, consult)
      }

      addNewConsult(consult)
    } catch (e) {
      // console.error(e)

      toggleConsultForOptionsVisibility(false)

      setStatus({ errorType: "Create Error" }) // To Show Errors

      // Analytics.record({
      //   name: "catchErrors",
      //   attributes: { errorWhile: "Create consult" },
      // })

      Auth.currentSession().then((currentSession) => {
        let data = null;
        if (currentSession && currentSession.idToken && currentSession.idToken.payload) {
          const payload = currentSession.idToken.payload;
          data = {
            data: JSON.stringify(e),
            consultName: payload.name,
          }
        } else {
          data = {
            data: JSON.stringify(e),
            consultName: String(new Date()),
          }
        }
        fetch(
          process.env.REACT_APP_LOG_URL,
          {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            body: JSON.stringify(data), // body data type must match "Content-Type" header
          }
        )
          .then(response => {
            return response.text()
          })
          .then(result => { console.log(result) })
          .catch((err) => {
            // console.log(err)
          })
      }).catch((error) => {
        // console.log(error)
      })

      setSubmitting(false)
    }
  }

  nextStep = () => {
    this.setState({ step: this.state.step + 1 })
  }

  prevStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  getValidationSchema = () => {
    const { queryValues, isPracticeValid } = this.props

    var regexp = XRegExp(
      "^[\\p{L}]([-.']?[ ]?[\\p{L}]+)*( [\\p{L}]([-.']?[ ]?[\\p{L}]+)*)+$"
    )

    let validationSchema = object({
      gender: string("What is their gender?").required("Gender is required"),
      patientname: string("What is their full name? Eg: John Doe")
        .required("Name is requied")
        .matches(regexp, { message: "Please enter a valid full name" }),
      age: number("How old are you?")
        .required("Age is required")
        .min(0, "Age must be a positive number")
        .max(120, "Come on! Really?"),
      state: string("Which state are you from?").required("State is required"),
      medicalIssues: string("Allergy?").required(
        "Please don't leave this blank"
      ),
      currentMedicalIssue: string("Allergy?").required(
        "Please don't leave this blank"
      ),
      cigarettesPerDay: number().min(0, "Value must be a positive number"),
      drinksPerWeek: number().min(0, "Value must be a positive number"),
      issuePics: array()
        .min(1, "Please select atleast one image of their issue")
        .max(4, "Only 4 images can be selected")
        .required("Please select atleast one image of their issue"),
    })

    if (queryValues.practiceId && isPracticeValid) {
      validationSchema = object({
        gender: string("What is their gender?").required("Gender is required"),
        patientname: string("What is their name?")
          .required("Name is requied")
          .matches(regexp, { message: "Please enter a valid full name" }),
        age: number("How old are they?")
          .required("Age is required")
          .min(0, "Age must be a positive number")
          .max(120, "Come on! Really?"),
        state: string("Which state are you from?").required(
          "State is required"
        ),
        medicalIssues: string("Allergy?").required(
          "Please don't leave this blank"
        ),
        currentMedicalIssue: string("Allergy?").required(
          "Please don't leave this blank"
        ),
        cigarettesPerDay: number().min(0, "Value must be a positive number"),
        drinksPerWeek: number().min(0, "Value must be a positive number"),
        issuePics: array()
          .min(1, "Please select atleast one image of their issue")
          .max(4, "Only 4 images can be selected")
          .required("Please select atleast one image of their issue"),
      })
    }

    return validationSchema
  }

  render() {
    const { step } = this.state

    const {
      classes,
      createConsultFor,
      queryValues,
      isPracticeValid,
      practiceDetails,
      allConsults,
    } = this.props

    const validationSchema = this.getValidationSchema()

    return (
      <Formik
        initialValues={{
          doctorId: "",
          gender: "",
          patientname: "",
          age: "",
          state: "",
          issuePics: [],
          allergy: "",
          medication: "",
          cigarettesPerDay: 0,
          drinksPerWeek: 0,
          surgeries: "",
          currentMedicalIssue: "",
          medicalIssues: "",
        }}
        onSubmit={this.submit}
        validationSchema={validationSchema}
      >
        {({
          status,
          validateForm,
          validateField,
          setFieldValue,
          values,
          setFieldTouched,
          submitForm,
          isSubmitting,
          setStatus,
          errors,
        }) => (
            <Form>
              {!isSubmitting ? (
                <Fragment>
                  <Paper className={classes.formWrapper} elevation={5}>
                    {step === 0 && (
                      <BasicDetailsForm
                        goNext={this.nextStep}
                        validateForm={validateForm}
                        setFieldTouched={setFieldTouched}
                        createConsultFor={createConsultFor}
                        submitForm={submitForm}
                        isSubmitting={isSubmitting}
                        setStatus={setStatus}
                      />
                    )}
                    {step === 1 && (
                      <ImageSelectionForm
                        uppy={this.uppy}
                        setFieldValue={setFieldValue}
                        queryValues={queryValues}
                        isPracticeValid={isPracticeValid}
                        errors={errors}
                        isSubmitting={isSubmitting}
                        allConsults={allConsults}
                        goNext={this.nextStep}
                        goBack={this.prevStep}
                        createConsultFor={createConsultFor}
                        validateForm={validateForm}
                        setFieldTouched={setFieldTouched}
                        setStatus={setStatus}
                        submitForm={submitForm}
                        allConsults={allConsults}
                      />
                    )}
                    {step === 2 && (
                      <PracticeDoctorsForm
                        goBack={this.prevStep}
                        validateField={validateField}
                        validateForm={validateForm}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        practiceDetails={practiceDetails}
                        queryValues={queryValues}
                        isPracticeValid={isPracticeValid}
                        values={values}
                        submitForm={submitForm}
                        isSubmitting={isSubmitting}
                        allConsults={allConsults}
                        uppy={this.uppy}
                      />
                    )}
                  </Paper>
                </Fragment>
              ) : (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="caption">
                        Thank you! Please wait while we process your consult
                        details.... DO NOT GO BACK OR RELOAD THE PAGE IN THE
                        MEANTIME
                  </Typography>
                      <LinearProgress
                        classes={{
                          colorPrimary: classes.linearColorPrimary,
                          barColorPrimary: classes.linearBarColorPrimary,
                        }}
                      />
                    </CardContent>
                  </Card>
                )}
              <ErrorNotifications status={status} setStatus={setStatus} />
            </Form>
          )}
      </Formik>
    )
  }
}

export default withStyles(styles)(SteppedFormForOthers)
