import { Auth } from "aws-amplify"

function dataToEncrypt(consultDetails) {
  const fieldsToEncrypt = [
    "patientname",
    "age",
    "email",
    "gender",
    "phone_number",
    "firstname",
    "lastname",
  ]
  const objectToEncrypt = fieldsToEncrypt.reduce((res, i) => {
    if (consultDetails.hasOwnProperty(i)) {
      return { ...res, [i]: consultDetails[i] }
    }
    return res
  }, {})

  return objectToEncrypt
}
export default function encryptConsult(consultDetails) {
  // let firstName = consultDetails.patientname ? consultDetails.patientname.split(' ')[0] : consultDetails.patientname;
  // consultDetails.patientname = consultDetails.patientname ? consultDetails.patientname.split(' ').slice(1).join(' ') : consultDetails.patientname;

  return new Promise(async (resolve, reject) => {
    const data = dataToEncrypt(consultDetails)

    // THIS URL IS FROM PRODUCTION AWS API GATEWAY

    fetch(
      process.env.REACT_APP_ENCRYPT_URL,
      // "https://ij1omrmtl7.execute-api.us-east-1.amazonaws.com/dev/api/encryptconsult",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        },
        // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        // headers: {
        //   "Access-Control-Request-Headers": "Access-Control-Allow-Origin,Content-Type",
        //   'Access-Control-Allow-Origin': '*',
        //   "Content-Type": "application/json",
        // },
        // redirect: "follow", // manual, *follow, error
        // referrer: "no-referrer", // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
      .then(response => {
        return response.text()
      })
      .then(result => {
        const encryptedFields = JSON.parse(JSON.parse(result).body)
        if (consultDetails.patientDob) {
          encryptedFields.patientDob =
            consultDetails.patientDob
              .split(" ")
              .slice(0, 2)
              .join(" ") + " xxxx"
        }
        const finalData = { ...consultDetails, ...encryptedFields }
        resolve(finalData)
      })
      .catch(console.log)
  })
}
