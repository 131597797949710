import React, { Component, Fragment, useEffect, useState } from "react"
import Storage from "@aws-amplify/storage"
import { Analytics } from "aws-amplify"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import distanceInWordsToNow from "date-fns/distance_in_words_to_now"
import moment from "moment"

// @material-ui/core
import LinearProgress from "@material-ui/core/LinearProgress"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import WarningIcon from "@material-ui/icons/Warning"

import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import VideocamIcon from "@material-ui/icons/Videocam"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import ViewListIcon from "@material-ui/icons/ViewList"
import Videocam from "@material-ui/icons/Videocam"
import EventAvailableIcon from "@material-ui/icons/EventAvailable"
import EventBusyIcon from "@material-ui/icons/EventBusy"

import ConsultDetails from "../../components/ConsultDetails/ConsultDetails"
import ModalDialog from "../../components/Modal/ModalDialog"

import fetchDoctor from "../../queries/fetchDoctor"
import fetchPracticeDetails from "../../queries/fetchPracticeDetails"
import Loader from "./DashboardLoader"
import getCurrentTimezoneName from "../../utils/getCurrentTimezoneName"

const ExpansionPanel = withStyles({
  root: {
    margin: "10px 0",
    width: "100%",
    minWidth: 275,
    overflow: "auto",
  },
  expanded: {
    width: "100%",
    minWidth: 275,
    overflow: "auto",
  },
})(MuiExpansionPanel)

const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing.unit,
  },
  pageHeading: {
    minWidth: 275,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    paddingBottom: 6,
  },
  headerCard: {
    minWidth: 275,
    display: "flex",
    flexFlow: "row wrap",
    padding: `${theme.spacing.unit}px 0px`,
    justifyContent: "space-around",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cardActionBtn: {
    marginLeft: "auto",
  },
  expansionPanelSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  panelSummaryContent: {
    display: "flex",
    flexDirection: "column",
  },
  panelSummaryBtns: {
    display: "flex",
    flexFlow: "column wrap",
  },
  chatBtn: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    backgroundColor: "#36c2ea",
    "&:hover": {
      backgroundColor: "#45b2d2",
    },
  },
  pdfBtn: {
    backgroundColor: "#36c2ea",
    "&:hover": {
      backgroundColor: "#45b2d2",
    },
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
  createBtnContainer: {
    display: "flex",
    flexDirection: "column",
  },
})

let doctorName
class Dashboard extends Component {
  state = {
    scheduleCallConsultId: null,
    openWarningModal: false,
    doctorFee: 10,
    consultFee: 59,
  }

  constructor(props) {
    super(props)
    this.myDivToFocus = React.createRef()
    // localStorage.setItem("isVideocallConsult", false)
  }

  handlePdfDownload = async consult => {
    try {
      const result = await Storage.get(`pdfs/${consult.id}.pdf`, {
        level: "public",
        expires: 60,
      })
      window.open(result)
    } catch (e) {
      // console.error(e)
      // Analytics.record({
      //   name: "catchErrors",
      //   attributes: { errorWhile: "Get pdf from Storage" },
      // })
    }
  }

  fetchDoctorDetails = consult => {
    fetchDoctor({ doctorId: consult.doctorId })
      .then(response => {
        const {
          data: { doctor },
        } = response
        if (doctor && doctor.id) {
          this.setState({
            openWarningModal: true,
            scheduleCallConsultId: consult.id,
            doctorFee: doctor.videocallFee ? doctor.videocallFee : 10,
          })
          if (consult.practiceId) {
            fetchPracticeDetails(consult.practiceId).then(res => {
              this.setState({
                consultFee: res.data.practiceDetails.consultationFee
                  ? res.data.practiceDetails.consultationFee
                  : 59,
              })
            })
          }
        }
      })
      .catch(error => {
        // console.log(error)
        this.setState({
          openWarningModal: true,
          scheduleCallConsultId: consult.id,
        })
      })
  }

  render() {
    const {
      classes,
      allConsults,
      allVideoCalls,
      loadingConsults,
      loadingVideoCalls,
      queryValues,
      isPracticeValid,
      isFromDermio,
      errorsWhile,
      fetchMyConsults,
      practiceDetails,
    } = this.props
    console.log("practiceDetails", practiceDetails)
    const todayDateString = moment().format("DD/MM/YYYY")

    // console.log("allVideoCalls ",allVideoCalls)
    if (allConsults.length === 1 && !allConsults[0].url1) {
      return <Redirect to={`/patient/consults/${allConsults[0].id}/edit`} />
    }

    if (allConsults && allConsults.length) {
      const declinedConsults = allConsults.filter(consult => {
        return (
          consult.status === "accepted" &&
          !consult.cardToken &&
          consult.practiceId != process.env.REACT_APP_PRACTICE_ID
        )
      })
      if (declinedConsults && declinedConsults.length) {
        return (
          <Redirect
            to={`/patient/consults/${declinedConsults[0].id}/cardDetails`}
          />
        )
      }
    }

    let todayVideocalls = []
    if (allVideoCalls && allVideoCalls.length) {
      todayVideocalls = allVideoCalls.filter(videoCall => {
        if (
          videoCall.scheduledDateString === todayDateString &&
          videoCall.inviteStatus == "accepted" &&
          moment(videoCall.scheduledDateTime).format("L") ===
            moment().format("L")
        ) {
          return videoCall
        }
      })
    }

    let canAcceptConsult = false
    if (practiceDetails && practiceDetails.doctors) {
      const { doctors } = practiceDetails
      const licensedDoctors = doctors.filter(doctor => {
        return doctor.enabled
      })
      if (licensedDoctors.length) {
        canAcceptConsult = true
      }
    }

    // console.log("todayVideocalls ", todayVideocalls)

    if ((queryValues.practiceId && isPracticeValid) || isFromDermio) {
      return (
        <div>
          {!loadingConsults && !canAcceptConsult && (
            <Card
              className={classes.card}
              style={{
                marginBottom: "2rem",
                backgroundColor: "#fff4e5",
              }}
            >
              <CardContent>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#663c00",
                  }}
                >
                  <WarningIcon
                    style={{
                      marginRight: "10px",
                      color: "#fea118",
                    }}
                  />
                  we are temporarily unable to perform online consultations...
                </Typography>
              </CardContent>
            </Card>
          )}
          {loadingConsults ? (
            <Loader />
          ) : (
            !errorsWhile.fetchingVideoCalls &&
            !loadingVideoCalls && (
              <Fragment>
                <div className={classes.pageHeading}>
                  <Typography variant="h5">Today video calls</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size={"small"}
                    onClick={() => {
                      if (this.myDivToFocus.current) {
                        this.myDivToFocus.current.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                        })
                      }
                    }}
                    style={{ backgroundColor: "#36c2ea" }}
                  >
                    All video calls
                  </Button>
                </div>
              </Fragment>
            )
          )}
          {loadingConsults ? (
            <Loader />
          ) : !errorsWhile.fetchingConsults &&
            !loadingConsults &&
            loadingVideoCalls ? null : todayVideocalls &&
            todayVideocalls.length ? (
            <Fragment>
              <Card className={classes.card} style={{ marginBottom: 10 }}>
                <List
                  component="nav"
                  className={classes.root}
                  aria-label="contacts"
                >
                  {todayVideocalls.map((videoCall, index) => {
                    if (!videoCall.doctorName) {
                      fetchDoctor({ doctorId: videoCall.doctorId }).then(
                        res => {
                          doctorName = res.data.doctor.name
                        }
                      )
                    }
                    const date = moment(videoCall.scheduledDateTime)
                    const dateWithDuration = moment(date, "hh:mm:ss A")
                      .add(0, "seconds")
                      .add(15, "minutes")
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.history.push(
                            `/patient/consults/${videoCall.id}/video-call`
                          )
                        }}
                      >
                        <ListItem key={videoCall.id}>
                          <ListItemText
                            primary={moment(videoCall.scheduledDateTime).format(
                              "LLLL"
                            )}
                            secondary={getCurrentTimezoneName()}
                          />
                          {videoCall.doctorName ? (
                            <ListItemText
                              primary={`${videoCall.doctorName}`}
                              secondary={"Physician"}
                            />
                          ) : (
                            <ListItemText
                              primary={`${doctorName}`}
                              secondary={"Physician"}
                            />
                          )}
                          <ListItemSecondaryAction>
                            {videoCall.callStatus == "completed" ? (
                              <IconButton>
                                <ViewListIcon
                                  onClick={() => {
                                    this.props.history.push(
                                      `/patient/consults/${videoCall.id}/videoCallHistory`
                                    )
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <Videocam
                                  onClick={() => {
                                    this.props.history.push(
                                      `/patient/consults/${videoCall.id}/video-call`
                                    )
                                  }}
                                />
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>

                          <ListItemText primary={""} />
                        </ListItem>
                        <Divider />
                      </div>
                    )
                  })}
                </List>
              </Card>
            </Fragment>
          ) : (
            <Fragment>
              <Card className={classes.card} style={{ marginBottom: 10 }}>
                <Typography variant="caption" style={{ padding: 5 }}>
                  No videocall scheduled today.
                </Typography>
              </Card>
            </Fragment>
          )}

          {!errorsWhile.fetchingConsults && !loadingConsults && (
            <Fragment>
              <div className={classes.pageHeading}>
                <Typography variant="h5">My Consults</Typography>
                <div className={classes.createBtnContainer}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    onClick={() =>
                      this.props.history.push(`/patient/consults/create`)
                    }
                    style={{
                      backgroundColor: canAcceptConsult
                        ? "#36c2ea"
                        : "secondary",
                      margin: 5,
                    }}
                    disabled={canAcceptConsult ? false : true}
                  >
                    Create New Consult
                  </Button>
                  {/* {!isFromDermio && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        localStorage.setItem("isVideocallConsult", true)
                        this.props.history.push(`/patient/consults/create`)
                      }}
                      style={{ backgroundColor: "#36c2ea" }}
                    >
                      Create Video Consult
                    </Button>
                  )} */}
                </div>
                {/* <MuiLink 
                  component={ConsultLink}
                  to={`/patient/consults/create`}
                  >
                    Create New Consult
                  </MuiLink> */}
              </div>

              {/* <Divider /> */}
            </Fragment>
          )}
          {loadingConsults ? (
            <Loader />
          ) : // (
          //   <Card className={classes.card}>
          //     <CardContent>
          //       <Typography variant="caption">
          //         Loading! Please wait....
          //       </Typography>
          //       <LinearProgress
          //         classes={{
          //           colorPrimary: classes.linearColorPrimary,
          //           barColorPrimary: classes.linearBarColorPrimary,
          //         }}
          //       />
          //     </CardContent>
          //   </Card>
          // )
          allConsults.length ? (
            allConsults
              .sort(function(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
              })
              .map((consult, index) => {
                return (
                  <SingleConsult
                    {...this.props}
                    fetchDoctorDetails={this.fetchDoctorDetails}
                    consult={consult}
                    index={index}
                    classes={classes}
                    allConsults={allConsults}
                    handlePdfDownload={this.handlePdfDownload}
                    fetchMyConsults={fetchMyConsults}
                  />
                )
              })
          ) : (
            <Card className={classes.card}>
              <CardContent>
                <Typography>
                  {errorsWhile.fetchingConsults
                    ? `OOPS! Something went wrong while fetching your details. Please check your internet connection and reload the page again.`
                    : `You have no consults. Please create a new one if you have any skin issues.`}
                </Typography>
              </CardContent>
            </Card>
          )}

          {!errorsWhile.fetchingVideoCalls && !loadingVideoCalls && (
            <Fragment>
              <div className={classes.pageHeading}>
                <Typography variant="h5">Video calls</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {}}
                  style={{ backgroundColor: "#36c2ea", visibility: "hidden" }}
                >
                  Schedule call
                </Button>
              </div>
            </Fragment>
          )}

          {loadingVideoCalls && loadingConsults ? null : allVideoCalls && // ) //   </Card> //     </CardContent> //       /> //         }} //           barColorPrimary: classes.linearBarColorPrimary, //           colorPrimary: classes.linearColorPrimary, //         classes={{ //       <LinearProgress //         </Typography> //         Loading! Please wait.... //       <Typography variant="caption"> //     <CardContent> //   <Card className={classes.card}> //  (
            allVideoCalls.length ? (
            <Fragment>
              <Card className={classes.card} ref={this.myDivToFocus}>
                <List
                  component="nav"
                  className={classes.root}
                  aria-label="contacts"
                >
                  {allVideoCalls
                    .sort(function(a, b) {
                      return (
                        new Date(a.scheduledDateTime) -
                        new Date(b.scheduledDateTime)
                      )
                    })
                    .map((videoCall, index) => {
                      // console.log(videoCall, "videoCalll ======")
                      if (!videoCall.doctorName) {
                        fetchDoctor({ doctorId: videoCall.doctorId }).then(
                          res => {
                            doctorName = res.data.doctor.name
                          }
                        )
                      }
                      const date = moment(videoCall.scheduledDateTime)
                      const dateWithDuration = moment(date, "hh:mm:ss A")
                        .add(0, "seconds")
                        .add(15, "minutes")
                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.props.history.push(
                              `/patient/consults/${videoCall.id}/video-call`
                            )
                          }}
                        >
                          <ListItem key={videoCall.id}>
                            <ListItemText
                              primary={moment(
                                videoCall.scheduledDateTime
                              ).format("LLLL")}
                              secondary={getCurrentTimezoneName()}
                            />
                            {videoCall.doctorName ? (
                              <ListItemText
                                primary={`${videoCall.doctorName}`}
                                secondary={"Physician"}
                              />
                            ) : (
                              <ListItemText
                                primary={`${doctorName}`}
                                secondary={"Physician"}
                              />
                            )}
                            <ListItemSecondaryAction>
                              {videoCall.callStatus == "completed" ? (
                                <IconButton>
                                  <ViewListIcon
                                    onClick={() => {
                                      this.props.history.push(
                                        `/patient/consults/${videoCall.id}/videoCallHistory`
                                      )
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton>
                                  <EventAvailableIcon
                                    onClick={() => {
                                      this.props.history.push(
                                        `/patient/consults/${videoCall.id}/video-call`
                                      )
                                    }}
                                  />
                                </IconButton>
                              )}
                            </ListItemSecondaryAction>

                            <ListItemText primary={""} />
                          </ListItem>
                          <Divider />
                        </div>
                      )
                    })}
                </List>
              </Card>
              <Divider />
            </Fragment>
          ) : (
            <Card className={classes.card}>
              <CardContent>
                <Typography>
                  {errorsWhile.fetchingVideoCalls
                    ? `OOPS! Something went wrong while fetching your details. Please check your internet connection and reload the page again.`
                    : `You have no upcoming scheduled videocall. Please create a new one if you have any skin issues.`}
                </Typography>
              </CardContent>
            </Card>
          )}

          <ModalDialog
            open={this.state.openWarningModal}
            handleClose={() => {
              this.setState({ openWarningModal: false })
            }}
            handleConfirmation={() => {
              // console.log(`/patient/consults/${this.state.scheduleCallConsultId}/schedule`)
              this.props.history.push(
                `/patient/consults/${this.state.scheduleCallConsultId}/schedule`
              )
            }}
            alertMsg={`Video calling feature costs an additional $${
              this.state.doctorFee
            }. Are you sure you want to schedule a call? (Total fee = $${this
              .state.consultFee + this.state.doctorFee})`}
          />
        </div>
      )
    }

    if (queryValues.practiceId && !isPracticeValid) {
      return loadingConsults ? (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">Loading! Please wait....</Typography>
            <LinearProgress
              classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary,
              }}
            />
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <Typography>
              {errorsWhile.fetchingPractice
                ? `OOPS! Something went wrong while fetching your details. Please check your internet connection and reload the page again.`
                : `Sorry! You cannot access your dashboard. Please make sure you didn't modify your url. You can fix this by going back to the chatbot and click login or to the website button that redirected you here.`}
            </Typography>
          </CardContent>
        </Card>
      )
    }

    if (!isFromDermio) {
      return loadingConsults ? (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">Loading! Please wait....</Typography>
            <LinearProgress
              classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary,
              }}
            />
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <Typography>
              {errorsWhile.fetchingPractice
                ? `OOPS! Something went wrong while fetching your details. Please check your internet connection and reload the page again.`
                : `Are you lost? You can visit us only through your dermatologist's website chatbot. Please contact helpdesk@derm.io for help.`}
            </Typography>
          </CardContent>
        </Card>
      )
    }
  }
}

const SingleConsult = props => {
  const {
    consult,
    classes,
    index,
    allConsults,
    fetchDoctorDetails,
    handlePdfDownload,
    fetchMyConsults,
  } = props
  const [disableVideocall, setDisableVideocall] = useState(true)

  useEffect(() => {
    fetchDoctor({ doctorId: consult.doctorId })
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.doctor &&
          res.data.doctor.disableVideoCall
        ) {
          setDisableVideocall(true)
        } else if (res && res.data && res.data.doctor) {
          setDisableVideocall(false)
        }
      })
      .catch(err => console.log(err))
  }, [consult.doctorId])

  return (
    <Fragment key={consult.id}>
      <ExpansionPanel defaultExpanded={index === 0 && allConsults.length === 1}>
        {/* <ExpansionPanel> */}
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.panelSummaryContent}>
            <Typography variant="caption" gutterBottom>
              Created{" "}
              {distanceInWordsToNow(consult.createdAt, {
                addSuffix: true,
              })}
            </Typography>
            <div className={classes.panelSummaryBtns}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  props.history.push(`/patient/consults/${consult.id}`)
                }
                className={classes.chatBtn}
              >
                See Doctor Chat
              </Button>
              {consult.hasPdf && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.pdfBtn}
                  style={{ marginBottom: 10 }}
                  onClick={() => handlePdfDownload(consult)}
                >
                  Download consult pdf
                </Button>
              )}
              {consult.pharmacy_NCPDPID && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.pdfBtn}
                  onClick={() =>
                    props.history.push(
                      `/patient/consults/${consult.id}/pharmacy/edit`
                    )
                  }
                >
                  Edit Pharmacy Info
                </Button>
              )}
              {/*} {consult.doctorId && consult.status != "pending" && (
              <Button
                variant="contained"
                color="primary"
                className={classes.chatBtn}
                style={{ marginTop: 10 }}
                onClick={() => { this.fetchDoctorDetails(consult) }}
              >
                Live Video Call
            </Button>
            )}*/}

              {!consult.insuranceUrl1 &&
                consult.status === "accepted" &&
                !consult.cardToken && (
                  <Button
                    variant="contained"
                    // color="secondary"
                    style={{
                      marginTop: 10,
                      background: "#f44336",
                      color: "white",
                    }}
                    onClick={() =>
                      props.history.push(
                        `/patient/consults/${consult.id}/cardDetails`
                      )
                    }
                    className={classes.chatBtn}
                  >
                    Re-enter card details
                  </Button>
                )}

              {!consult.cardToken &&
                consult.doctorId &&
                !consult.isVideoCallConsult &&
                !consult.insuranceFile1 &&
                !disableVideocall && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.chatBtn}
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      fetchDoctorDetails(consult)
                    }}
                  >
                    Upgrade to Video Call
                  </Button>
                )}
            </div>
          </div>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails className={classes.details}>
          <ConsultDetails record={consult} fetchMyConsults={fetchMyConsults} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  )
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Dashboard)
