import React, { Component, Fragment } from "react"
import StripeCheckout from "react-stripe-checkout"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Auth } from "aws-amplify"

const styles = theme => ({
  field: {
    // margin: `${theme.spacing.unit * 2}px 0px`
  },
  formActions: {
    display: "flex",
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  continueBtn: {
    marginRight: "auto",
    color: "#36c2ea",
  },
  backBtn: {
    color: "#36c2ea",
  },
})

class StripeCheckoutForm extends Component {
  componentDidMount = () => {
    if (this.props.isReEnteringCard) {
      this.buttonElement.click()
    }
  }

  componentWillUnmount = () => {
    this.props.changeReEnterState()
  }

  onToken = async token => {
    const { setFieldValue, submitForm, values, setCardDeclined, consult } = this.props
    // console.log("consult ", consult)
    // Create Stripe customer

    const data = {
      email: values.email,
      cardToken: token.id,
      doctorId: consult.doctorId,
      practiceId: consult.practiceId || process.env.REACT_APP_PRACTICE_ID,
      consultName: consult.patientname,
      source: consult.source,
      isVideoCallConsult: consult.isVideoCallConsult
    }
    // console.log(data)
    fetch(process.env.REACT_APP_CREATE_STRIPE_CUSTOMER_URL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      headers: {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(res => res.json())
      .then(async response => {
        console.log("response ", response)
        const { customer, charge, error } = response

        if (customer && customer.id) {
          setFieldValue("cardToken", token.id, false)
          setFieldValue("customerStripeId", customer.id, false)
          if (charge && charge.id) {
            setFieldValue("customerChargeId", charge.id, false)
          }
          submitForm()
        } else {
          setCardDeclined()
        }
      })
      .catch(error => {
        console.log(error)
        setCardDeclined()
      })
  }

  render() {
    const { goBack, values, isSubmitting, classes } = this.props

    const finalTotal = 0.0
    const STRIPE_PK = process.env.REACT_APP_STRIPE_KEY

    return (
      <Fragment>
        <Typography gutterBottom>
          Please click on CARD DETAILS to enter your credit card details.
        </Typography>
        {/* <Divider className={classes.divider}/> */}

        <div className={classes.formActions}>
          <Button
            type="button"
            onClick={() => goBack()}
            className={classes.backBtn}
            color="primary"
          >
            Back
          </Button>
          <StripeCheckout
            token={this.onToken}
            currency="USD"
            amount={finalTotal}
            stripeKey={STRIPE_PK}
            label="Card Details"
            email={values.email}
            panelLabel="Submit Card Details"
          >
            <Button
              disabled={isSubmitting}
              color="primary"
              className={classes.continueBtn}
              ref={input => (this.buttonElement = input)}
            >
              Card Details
            </Button>
          </StripeCheckout>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(StripeCheckoutForm)
