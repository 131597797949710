import gql from "graphql-tag"
import Appsync from "../../config"
import encryptConsult from "../../utils/encryptConsult"

const client = Appsync()

const mutationUpdateConsults = gql`
  mutation Create(
    $patientname: String
    $id: ID!
    $firstname: String
    $lastname: String
    $age: Int
  ) {
    updateConsult(
      patientname: $patientname
      firstname: $firstname
      id: $id
      lastname: $lastname
      age: $age
    ) {
      id
    }
  }
`

const updateConsults = async variables => {
  const encryptedConsultDetails = await encryptConsult(variables)

  const updatedConsult = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateConsults,
      variables: encryptedConsultDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsult
}

export default updateConsults
