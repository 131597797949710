import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import SteppedFormForMe from "../../components/Forms/Create/SteppedFormForMe/SteppedFormForMe"
import SteppedFormForOthers from "../../components/Forms/Create/SteppedFormForOthers/SteppedFormForOthers"

const styles = theme => ({
  root: {
    display: "flex",
    flexFlow: "column wrap",
  },
  formControl: {
    // marginTop: theme.spacing.unit * 3,
  },
  formLabel: {
    margin: 0,
  },
  group: {
    display: "flex",
    flexFlow: "row wrap",
  },
  card: {
    minWidth: 275,
    marginBottom: theme.spacing.unit * 2,
    // backgroundColor: 'rgba(251, 251, 251, 0.9)'
  },
})

class ConsultCreate extends Component {
  state = {
    createConsultFor: "me",
    hideOptions: false,
  }

  handleChange = event => {
    this.setState({ createConsultFor: event.target.value })
  }

  toggleConsultForOptionsVisibility = bool =>
    this.setState({ hideOptions: bool })

  render() {
    const { createConsultFor, hideOptions } = this.state

    const {
      classes,
      addNewConsult,
      queryValues,
      isFromDermio,
      isPracticeValid,
      practiceDetails,
      allConsults,
      handleConsultUpdate,
    } = this.props

    return (
      <div className={classes.root}>
        {!hideOptions && (
          <Card className={classes.card} elevation={5}>
            <CardContent>
              {allConsults.length === 1 && !allConsults[0].url1 && (
                <Typography gutterBottom>
                  Your consult was created by the doctor. Please go ahead and
                  update your consult.
                </Typography>
              )}
              <Typography gutterBottom>
                You can place a consult either for yourself or for others like
                your family members.
              </Typography>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="Create Consult For"
                  name="createConsultFor"
                  className={classes.group}
                  value={this.state.createConsultFor}
                  onChange={this.handleChange}
                >
                  <FormControlLabel value="me" control={<Radio />} label="Me" />
                  <FormControlLabel
                    value="others"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        )}
        {createConsultFor === "me" ? (
          <SteppedFormForMe
            createConsultFor={createConsultFor}
            addNewConsult={addNewConsult}
            handleConsultUpdate={handleConsultUpdate}
            toggleConsultForOptionsVisibility={
              this.toggleConsultForOptionsVisibility
            }
            practiceDetails={practiceDetails}
            queryValues={queryValues}
            isPracticeValid={isPracticeValid}
            isFromDermio={isFromDermio}
            allConsults={allConsults}
          />
        ) : (
          <SteppedFormForOthers
            createConsultFor={createConsultFor}
            addNewConsult={addNewConsult}
            handleConsultUpdate={handleConsultUpdate}
            toggleConsultForOptionsVisibility={
              this.toggleConsultForOptionsVisibility
            }
            practiceDetails={practiceDetails}
            queryValues={queryValues}
            isPracticeValid={isPracticeValid}
            isFromDermio={isFromDermio}
            allConsults={allConsults}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ConsultCreate)
