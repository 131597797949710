import momentTimezone from "moment-timezone"

const getCurrentTimezoneName = () =>{

	const currntTimezoneShortName = momentTimezone().tz(momentTimezone.tz.guess()).format('z');

	const allTimezones = [
		{
			shortName: "HST",
			name: "Hawaii Standard Time (HST)"
		},
		{
			shortName: "AKST",
			name: "Alaska Standard Time	(AKST)"
		},
		{
			shortName: "PST",
			name: "Pacific Standard Time (PST)"
		},
		{
			shortName: "MST",
			name: "Mountain Standard Time (MST)"
		},
		{
			shortName: "CST",
			name: "Central Standard Time (CST)"
		},
		{
			shortName: "EST",
			name: "Eastern Standard Time (EST)"
		}
		];

	const currentTimezone = allTimezones.filter((timezone)=>{
		return timezone.shortName === currntTimezoneShortName;
	});

	if(currentTimezone && currentTimezone.length){
		return currentTimezone && currentTimezone[0] ? currentTimezone[0].name : "";
	}else{
		return currntTimezoneShortName;
	}
}
export default getCurrentTimezoneName;