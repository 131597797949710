import React from "react"
import { Auth, Analytics } from "aws-amplify"
import { ConfirmSignIn } from "aws-amplify-react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import dermioLogo from "../../assets/img/dermio-logo.svg"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey["A700"],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
})

class CustomSignIn extends ConfirmSignIn {
  state = {
    error: "",
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const user = this.props.authData

    let { loginOtp } = this.inputs

    loginOtp = loginOtp.trim()

    Auth.confirmSignIn(user, loginOtp, "SMS_MFA")
      .then(user => {
        // Analytics.record("login_success")
        this.changeState("signedIn")
        this.setState({
          error: "",
        })
      })
      .catch(err => {
        // console.log("OTP", err)
        if (err.code === "ExpiredCodeException") {
          this.setState({
            error: "Your 6-digit code has expired. Please try again!",
          })
        } else {
          this.setState({
            error: "Please enter the correct 6-digit verification code",
          })
        }
      })
  }

  render() {
    const { classes, authState } = this.props
    if (authState === "confirmSignIn") {
      return (
        <div className={classes.main}>
          <Card className={classes.card} id="confirmSignIn">
            <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className={classes.hint}>SIGN IN TO YOUR ACCOUNT</div>
              <div className={classes.error}>{this.state.error}</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="loginOtp"
                    name="loginOtp"
                    label="6-digit verification code"
                    margin="normal"
                    type="text"
                    helperText="Enter the 6-digit verification code sent to your phone"
                    fullWidth={true}
                    required
                    error={this.state.error ? true : false}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  id="loginSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  onChange={this.handleInputChange}
                >
                  Login
                </Button>
                <Button
                  onClick={() => {
                    this.changeState("signIn")
                    this.setState({
                      error: "",
                    })
                  }}
                >
                  Retry
                </Button>
              </CardActions>
            </form>
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomSignIn)
