import React, { Component, Fragment } from "react"
import { Form, Formik } from "formik"
import { object, string } from "yup"
import { Analytics } from "aws-amplify"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import EmailForm from "./EmailForm"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import LinearProgress from "@material-ui/core/LinearProgress"

import StripeCheckoutForm from "./StripeCheckoutForm"
import updatePayment, { updateReEnterPaymentConsults } from "../../../queries/updatePayment"
import { sendConsultNotification } from "../../../queries/sendConsultNotification"
import { sendReEnteredCardNotification } from "../../../queries/sendReEnteredCardNotification"
import Notifications from "../../Notifications/Notifications"

const styles = theme => ({
  formWrapper: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
    backgroundColor: "rgba(251, 251, 251, 0.9)",
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

const validationSchema = object().shape({
  email: string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  cardToken: string().required("CardToken is required"),
})

class Payment extends Component {
  state = {
    step: 1,
    isCardDeclined: false,
    email: "",
    isReEnteringCard: false,
  }

  componentDidMount = () => {
    const { consult } = this.props
    if (consult && consult.email && !consult.cardToken) {
      this.setState({
        step: this.state.step + 1,
        email: consult.email,
        isReEnteringCard: true,
      })
    }
  }

  nextStep = () => {
    this.setState({ step: this.state.step + 1 })
  }

  prevStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  submit = async (values, { setSubmitting, setStatus }) => {
    const { consult, handleConsultUpdate, queryValues } = this.props

    let variables = {
      id: consult.id,
      cardToken: values.cardToken,
      email: values.email,
      stripeId: values.customerStripeId
    }

    try {
      let data = null;

      if (consult && consult.email && !consult.cardToken) {
        variables.cardDeclined = false;
        if (values.customerChargeId) {
          variables.chargeId = values.customerChargeId;
        }
        data = await updateReEnterPaymentConsults(variables)
      } else {
        if (values.customerChargeId) {
          variables.chargeId = values.customerChargeId;
        }
        data = await updatePayment(variables)
      }

      const updatedConsult = data.data.updateConsult

      const { doctorId, state, cardToken, email } = updatedConsult

      // if (queryValues && queryValues.from != "dermio") {

      // If Consult reentered details send Reentered card details notification
      if (consult && consult.email && !consult.cardToken) {
        try {
          await sendReEnteredCardNotification({
            doctorId,
            state,
            queryValues,
          })
        } catch (e) {
          // console.error(e) // Handle sendConsultNotification errors silently
        }
      } else {
        try {
          await sendConsultNotification({
            doctorId,
            state,
            queryValues,
          })
        } catch (e) {
          // console.error(e) // Handle sendConsultNotification errors silently
        }
      }
      // }

      if (data.data.updateConsult.unlicensedDocName) {
        localStorage.setItem(
          "unlicensedDocName",
          data.data.updateConsult.unlicensedDocName
        )
      } else {
        localStorage.removeItem("unlicensedDocName")
      }
      setSubmitting(false)

      const updateDetails = {
        cardToken,
        email,
      }

      handleConsultUpdate(consult.id, updateDetails)
    } catch (e) {
      // console.error(e)
      setStatus({ errorType: "Update Error" }) // To Show Errors
      // Analytics.record({
      //   name: "catchErrors",
      //   attributes: { errorWhile: "Update consult" },
      // })
      setSubmitting(false)
    }
  }

  setCardDeclined = () => {
    this.setState({ isCardDeclined: true }, () => {
      setTimeout(() => {
        this.setState({ isCardDeclined: false });
      }, 2500);
    })
  }

  changeReEnterState = () => {
    this.setState({ isReEnteringCard: false })
  }

  render() {
    const { step, email, isReEnteringCard } = this.state
    const { classes, consult } = this.props

    return (
      <Formik
        initialValues={{
          email: email,
          cardToken: "",
          customerStripeId: "",
          customerChargeId: ""
        }}
        onSubmit={this.submit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          status,
          validateForm,
          setFieldValue,
          values,
          setFieldTouched,
          submitForm,
          isSubmitting,
          setStatus,
        }) => (
            <Form>
              {!isSubmitting ? (
                <Fragment>
                  {this.state.isCardDeclined && (
                    <Notifications
                      variant="error"
                      message={`Card declined. Please try again with different card. Contact helpdesk@derm.io if you have any issues.`}
                    />
                  )}

                  {status &&
                    status.errorType &&
                    status.errorType === "Update Error" && (
                      <Notifications
                        variant="error"
                        message={`OOPS! Something went wrong while processing your credit card details. Please try again. Contact helpdesk@derm.io if you have any issues.`}
                      />
                    )}
                  <Paper className={classes.formWrapper} elevation={5}>
                    {step === 1 && (
                      <EmailForm
                        goNext={this.nextStep}
                        validateForm={validateForm}
                        values={values}
                        setFieldTouched={setFieldTouched}
                      />
                    )}
                    {step === 2 && (
                      <StripeCheckoutForm
                        goBack={this.prevStep}
                        validateForm={validateForm}
                        setFieldValue={setFieldValue}
                        values={values}
                        submitForm={submitForm}
                        setFieldTouched={setFieldTouched}
                        isSubmitting={isSubmitting}
                        status={status}
                        setStatus={setStatus}
                        setCardDeclined={this.setCardDeclined}
                        isReEnteringCard={isReEnteringCard}
                        changeReEnterState={this.changeReEnterState}
                        consult={consult}
                      />
                    )}
                  </Paper>
                </Fragment>
              ) : (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="caption">
                        Thank you! Please wait while we process your credit card
                        details.... DO NOT GO BACK OR RELOAD THE PAGE IN THE
                        MEANTIME.
                  </Typography>
                      <LinearProgress
                        classes={{
                          colorPrimary: classes.linearColorPrimary,
                          barColorPrimary: classes.linearBarColorPrimary,
                        }}
                      />
                    </CardContent>
                  </Card>
                )}
            </Form>
          )}
      </Formik>
    )
  }
}

export default withStyles(styles)(Payment)
