import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query allUnavailabilities(
    $doctorId: String
  ){
    allUnavailabilities(filter:{
      doctorId: { eq : $doctorId }
    }){
      items{
        id
        doctorId
        day
        dateString
        datetime
        slots{
          from
          to
        }
      }
    }
  }
`

const fetchDoctorAllUnavailabilities = async variables => {
  const result = await client.hydrated().then(function(cl) {
    return cl
      .query({ query, variables, fetchPolicy: "network-only" })
      .then(function logData(data) {
        return data
      })
  })
  return result
}

export default fetchDoctorAllUnavailabilities
