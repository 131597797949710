import React, { Component, Fragment } from "react"
import classNames from "classnames"
import { Analytics } from "aws-amplify"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import Arrowback from "@material-ui/icons/ArrowBack"
import moment from "moment"

import distanceInWords from "date-fns/distance_in_words"
import isWithinRange from "date-fns/is_within_range"
import format from "date-fns/format"

import "../../assets/css/chat.css"
import FileUpload from "../FileUpload/FileUpload"
import DocumentUpload from "../FileUpload/DocumentUpload"
import imageUploadIcon from "../../assets/img/image-upload-icon.svg"
import AttachFileIcon from '@material-ui/icons/AttachFile'
import {
  createChatTextMsgMutation,
  createChatImgMsgMutation,
} from "../../queries/createChatMutation"
import sendIcon from "../../assets/img/send-icon.svg"
import AwsImageComponent from "../AwsImageComponent/AwsImageComponent"
import AwsFileComponent from "./AwsFileComponent"
import fetchConsultChats from "../../queries/fetchConsultChats"
import Notifications from "../Notifications/Notifications"
import PreviewComponent from "./PreviewComponent"

const styles = theme => ({
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

class DoctorPatientChat extends Component {
  state = {
    chatMessage: "",
    messages: [],
    errorMsg: "",
    isImageUploadOpen: false,
    uploadingImg: false,
    sendButton: false,
    isFileUploadOpen: false,
    messageType: "",
    isMoreThan14Days: false
  }

  componentDidMount = async () => {
    const { chatDetails, consult } = this.props;

    if (chatDetails.isConsultClosed && consult.createdAt) {
      const actualChatCloseDateUnix = moment(consult.createdAt).add(14, 'days').unix();
      const todayDateUnix = moment().unix();
      if (todayDateUnix > actualChatCloseDateUnix) {
        this.setState({ isMoreThan14Days: true });
      }
    }

    const observer = new MutationObserver(this.scrollToBottom)
    const config = { attributes: true, childList: true }
    observer.observe(this.chatMessagesContainer, config)

    const { consultId, initialFetchedMessages } = this.props.chatDetails

    const variables = {
      consultId: consultId,
    }

    try {
      const { data } = await fetchConsultChats(variables)
      const messages = data.getChats

      return this.setState({
        messages,
      })
    } catch (e) {
      console.error(e)

      // If error, show messages (Not upto date) fetched in CheckUser when first logged in.
      return this.setState({
        messages: initialFetchedMessages,
        errorMsg:
          "Sorry! Something went wrong. We are unable to fetch your latest messages at the moment.",
      })
    }
  }

  scrollToBottom = () => {
    // console.log("this.chatMessagesContainer ",this.chatMessagesContainer)
    this.chatMessagesContainer.scrollTop = this.chatMessagesContainer.scrollHeight
  }

  handleMessageInput(e) {
    const { value: chatMessage } = e.target
    this.setState({ chatMessage })
  }

  sendChatMessage = async (e) => {
    const { consultId, doctorId, patientname } = this.props.chatDetails
    const { chatMessage, messages } = this.state

    this.setState(
      {
        errorMsg: "",
      },
      () => this.scrollToBottom()
    )

    if (chatMessage.trim().length) {
      // If message is a Text content
      this.setState(
        {
          sendButton: true,
        },
        () => this.scrollToBottom()
      )

      try {
        console.log("this.seen", this.seen)
        const res = await createChatTextMsgMutation(
          chatMessage.trim(),
          consultId,
          doctorId,
          patientname,
          this.seen
        )

        this.seen = true
        console.log("this.seen", this.seen)
        const updatedMessages = [...messages, res.data.createChat]

        this.setState(
          {
            chatMessage: "",
            messages: updatedMessages,
            sendButton: false,
            errorMsg: "",
          },
          () => this.scrollToBottom()
        )
      } catch (e) {
        // console.error(e) // Todo: Display error message to the user
        // Analytics.record({
        //   name: "catchErrors",
        //   attributes: { errorWhile: "Create chat text message" },
        // })

        this.setState(
          {
            uploadingImg: false,
            errorMsg:
              "Sorry! Something went wrong while sending your message. Please try again.",
            sendButton: false,
          },
          () => this.scrollToBottom()
        )
      }
    }
  }

  handleFileUploadOpen = () => {
    this.setState({
      chatMessage: "",
      isFileUploadOpen: true,
      errorMsg: "",
    })
  }

  handleFileUploadClose = () => {
    this.setState(
      {
        chatMessage: "",
        isFileUploadOpen: false,
        errorMsg: "",
      },
      () => this.scrollToBottom()
    )
  }

  handleImageUploadOpen = () => {
    this.setState({
      chatMessage: "",
      isImageUploadOpen: true,
      errorMsg: "",
    })
  }

  handleImageUploadClose = () => {
    this.setState(
      {
        chatMessage: "",
        isImageUploadOpen: false,
        errorMsg: "",
      },
      () => this.scrollToBottom()
    )
  }

  // sendChatFile= async files=>{
  //   console.log(files)
  // }

  sendChatImage = async pics => {
    // console.log("files ",pics)
    const { consultId, doctorId, patientname } = this.props.chatDetails
    const { messages } = this.state

    this.setState(
      {
        uploadingImg: true,
        sendButton: true,
        isImageUploadOpen: false,
        isFileUploadOpen: false,
        errorMsg: "",
      },
      () => this.scrollToBottom()
    )

    try {
      const res = await createChatImgMsgMutation(
        pics,
        consultId,
        doctorId,
        patientname
      )

      const updatedMessages = [...messages, res.data.createChat]

      this.setState(
        {
          chatMessage: "",
          messages: updatedMessages,
          isImageUploadOpen: false,
          uploadingImg: false,
          sendButton: false,
          errorMsg: "",
        },
        () => this.scrollToBottom()
      )
    } catch (e) {
      // console.error(e) // Todo: Display error message to the user
      // Analytics.record({
      //   name: "catchErrors",
      //   attributes: { errorWhile: "Create chat image message" },
      // })

      this.setState(
        {
          uploadingImg: false,
          errorMsg:
            "Sorry! Something went wrong while uploading your image. Please try again.",
          sendButton: false,
        },
        () => this.scrollToBottom()
      )
    }
  }

  getAwsImageFileComponent = (message) => {
    const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(message.url);
    const imageExtensions = [".jpg", ".gif", ".png", ".jpeg", "tiff"];
    const isImage = imageExtensions.includes(`.${extension}`);
    if (isImage) {
      return <a
        className="chat__image-link"
        // href={message.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AwsImageComponent src={message.url} componentFrom="chat" onClick={(url) => { this.setState({ imgUrl: url, messageType: "image" }) }} />
      </a>
    } else {
      return <AwsFileComponent src={message} extension={extension} componentFrom="chat" onClick={(url) => { this.setState({ imgUrl: url, messageType: "file" }); }} />
    }
  }

  render() {
    const {
      messages,
      chatMessage,
      isImageUploadOpen,
      uploadingImg,
      errorMsg,
      isFileUploadOpen,
      messageType,
      isMoreThan14Days
    } = this.state

    const { classes, isFromDermio, practiceDetails } = this.props

    const {
      doctorAvatar,
      isConsultClosed,
      doctor,
      archived,
    } = this.props.chatDetails

    const isDocOnVacation =
      doctor &&
      doctor.onVacation &&
      isWithinRange(
        new Date(),
        doctor.startVacationDate,
        doctor.endVacationDate
      )

    const sortedChatMsgs = messages.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt)
    })

    // console.log("sortedChatMsgs ",sortedChatMsgs)

    if (isImageUploadOpen) {
      return (
        <FileUpload
          handleUpload={pics => this.sendChatImage(pics)}
          goBack={() => this.handleImageUploadClose()}
        />
      )
    }

    if (isFileUploadOpen) {
      return (
        <DocumentUpload
          handleUpload={files => this.sendChatImage(files)}
          goBack={() => this.handleFileUploadClose()}
        />
      )
    }

    return (
      <div className={"overlay overlay--open"}>
        {errorMsg ? <Notifications variant="error" message={errorMsg} /> : null}
        <div
          className="chat"
          style={{ position: "relative", height: "100%", width: "100%" }}
        >
          <div
            style={{
              position: "fixed",
              marginTop: "5rem",
              top: 10,
              right: 10,
              zIndex: 10,
            }}
          >
            {!archived && (
              <Arrowback
                className={"backBtn"}
                onClick={() => this.props.history.push(`/patient/dashboard${isFromDermio ? "?from=dermio" : `?practiceId=${practiceDetails && practiceDetails.id}`}`)}
              />
            )}
          </div>
          <div
            className="chat__messages"
            ref={el => (this.chatMessagesContainer = el)}
          >
            {sortedChatMsgs.map((message, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    "chat__message",
                    {
                      "chat__message--me":
                        message.senderRole === "patient" ? true : false,
                    },
                    { "chat__message--image": message.url ? true : false }
                  )}
                >
                  <div className="chat__message-body" style={{ marginLeft: 0 }}>
                    {message.senderRole === "patient" ? (
                      ""
                    ) : (
                      <img
                        src={doctorAvatar}
                        alt="Avatar"
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    {message.message ? (
                      <div>
                        <p className="chat__message-text">{message.message}</p>
                        <p className="chat__message-time">{`\n${distanceInWords(
                          message.createdAt,
                          Date.now()
                        )} ago`}</p>
                      </div>
                    ) : this.getAwsImageFileComponent(message)
                    }
                  </div>
                </div>
              )
            })}
          </div>

          <div className="chat__notification">
            {isConsultClosed && !isMoreThan14Days && <p className="chat__notification_text">“If you have issues related to this consult, you may message your physician here. If you have a new issue, you may be asked to submit a new consult.”</p>}
          </div>

          {!uploadingImg ? (
            <div className="chat__actions">
              {!isConsultClosed && !isMoreThan14Days ? (
                !archived ? (
                  isDocOnVacation ? (
                    `${doctor.isAssistant === true ? '' : "Dr."} ${doctor.firstName} ${doctor.lastName
                    } ${doctor.isAssistant === true ? doctor.assistantTitle : ""} is not available from ${format(
                      doctor.startVacationDate,
                      "MMMM Do, YYYY - HH:mm:ss A"
                    )} to ${format(
                      doctor.endVacationDate,
                      "MMMM Do, YYYY - HH:mm:ss A"
                    )}. You can chat with him once becomes available.`
                  ) : (
                    // <div className="chat__notification">
                    //   {isConsultClosed && <p className="chat__notification_text">“If you have issues related to this consult, you may message your physician here. If you have a new issue, you may be asked to submit a new consult.”</p>}
                    <textarea
                      className="chat__message-input"
                      value={this.state.chatMessage}
                      onChange={e => this.handleMessageInput(e)}
                      placeholder="Write your message..."
                    />
                    // </div>
                  )
                ) : (
                  `This consult has been archived by the doctor. You can no longer chat with him. Please create a new consult if you have any issues. Thank you!`
                )
              ) : (
                `The consult is closed. To chat, please create a new consult, If you have any other questions, please write to info@dermiodermatology.com. Thank you.`
              )}

              {!this.state.chatMessage && !isDocOnVacation && !archived && !isMoreThan14Days ? (
                <div className="chat__image-upload">
                  <span className="chat__btn chat__btn--image">
                    <img
                      src={imageUploadIcon}
                      style={{ width: 25, height: 25 }}
                      onClick={() => this.handleImageUploadOpen()}
                      alt=""
                    />
                  </span>
                </div>
              ) : null}
              {/* File attachement*/}
              {!this.state.chatMessage && !isDocOnVacation && !archived && !isMoreThan14Days ? (
                <div className="chat__image-upload">
                  <span className="chat__btn chat__btn--image">
                    {/*<img src={AttachFileIcon} style={{ width: 25, height: 25 }} onClick={() => this.handleImageUploadOpen()} alt="" />*/}
                    <AttachFileIcon
                      fontSize="default"
                      style={{ color: "black" }}
                      onClick={() => this.handleFileUploadOpen()}
                    />
                  </span>
                </div>
              ) : null}

              {!isDocOnVacation && !archived && !isMoreThan14Days ? (
                <div className="chat__message-upload">
                  <button
                    className="chat__btn chat__btn--text"
                    onClick={e => this.sendChatMessage(e)}
                    hidden={!chatMessage.length}
                    disabled={this.state.sendButton}
                  >
                    <img
                      src={sendIcon}
                      style={{ width: 25, height: 25 }}
                      alt="sendIcon"
                    />
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <Fragment>
              Uploading file. Please wait...
              <LinearProgress
                classes={{
                  colorPrimary: classes.linearColorPrimary,
                  barColorPrimary: classes.linearBarColorPrimary,
                }}
              />
            </Fragment>
          )}
          <PreviewComponent url={this.state.imgUrl} messageType={messageType} close={() => { this.setState({ imgUrl: "", messageType: "" }) }} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DoctorPatientChat)
