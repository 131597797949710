import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"

class ConsultsList extends Component {
  render() {
    const {
      routes,
      allConsults,
      handleConsultUpdate,
      addNewConsult,
      loadingConsults,
      practiceDetails,
      queryValues,
      isPracticeValid,
      isFromDermio,
    } = this.props

    const switchRoutes = (
      <Switch>
        {routes.map((prop, key) => {
          if (prop.layout === "/patient") {
            return (
              <Route
                exact
                path={prop.layout + prop.path}
                render={props => (
                  <prop.component
                    {...props}
                    routes={prop.routes}
                    allConsults={allConsults}
                    handleConsultUpdate={handleConsultUpdate}
                    addNewConsult={addNewConsult}
                    loadingConsults={loadingConsults}
                    practiceDetails={practiceDetails}
                    queryValues={queryValues}
                    isPracticeValid={isPracticeValid}
                    isFromDermio={isFromDermio}
                  />
                )}
                key={key}
              />
            )
          }
        })}
      </Switch>
    )

    return (
      <div>
        {this.props.match.isExact && "....."}
        {switchRoutes}
      </div>
    )
  }
}

export default ConsultsList
