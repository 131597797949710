import AWS from "aws-sdk"
import { SignalingClient } from "amazon-kinesis-video-streams-webrtc"

export default async ( channelName ) => {

  const returnVal = {} ;
  const kinesisVideoClient = new AWS.KinesisVideo({
    region: process.env.REACT_APP_AWS_KINESIS_WEBRTC_REGION,
    accessKeyId: process.env.REACT_APP_AWS_KINESIS_WEBRTC_ACCESSKEY_ID,
    secretAccessKey:
      process.env.REACT_APP_AWS_KINESIS_WEBRTC_SECRET_ACCESSKEY,
      correctClockSkew: true,
  })

  const describeSignalingChannelResponse = await kinesisVideoClient
    .describeSignalingChannel({ ChannelName: channelName, }).promise()

  const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN

  const getSignalingChannelEndpointResponse = await kinesisVideoClient
    .getSignalingChannelEndpoint({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ["WSS", "HTTPS"],
        Role: "VIEWER",
      },
    }).promise()

  const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
    (endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
      return endpoints
    }, {})

  const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels(
    {
      region: process.env.REACT_APP_AWS_KINESIS_WEBRTC_REGION,
      accessKeyId: process.env.REACT_APP_AWS_KINESIS_WEBRTC_ACCESSKEY_ID,
      secretAccessKey:
        process.env.REACT_APP_AWS_KINESIS_WEBRTC_SECRET_ACCESSKEY,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
    })

  const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    .getIceServerConfig({ ChannelARN: channelARN, }).promise()

  const iceServers = []

  iceServers.push({ urls: `stun:stun.kinesisvideo.us-east-1.amazonaws.com:443`, })

  getIceServerConfigResponse.IceServerList.forEach(iceServer =>
    iceServers.push({
      urls: iceServer.Uris,
      username: iceServer.Username,
      credential: iceServer.Password,
    }))

  // Set signaling channel endpoint & config to viewer (patient) state object
  returnVal.signalingClient = new SignalingClient({
    channelARN,
    clientId: "1234",
    channelEndpoint: endpointsByProtocol.WSS,
    role: "VIEWER",
    region: process.env.REACT_APP_AWS_KINESIS_WEBRTC_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_KINESIS_WEBRTC_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_KINESIS_WEBRTC_SECRET_ACCESSKEY,
    },
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
  })
  
  const configuration = {
    iceServers,
    iceTransportPolicy: "all",
  }
  returnVal.peerConnection = new RTCPeerConnection(configuration)
  returnVal.dataChannel = returnVal.peerConnection.createDataChannel("kvsDataChannel")
  return returnVal

}

