import React, { Fragment } from "react"
import { FastField, Formik } from "formik"
import XRegExp from "xregexp"
import { object, string, number, array } from "yup"

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField"
import { SmokingRooms } from "@material-ui/icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import AwsImageComponent from "../AwsImageComponent/AwsImageComponent"

import updateConsult from "./query"

export default class PatientDetails extends React.Component {
  constructor() {
    super()

    this.state = {
      imgUrl: "",
      showImg: false,
      showEditForm: false
    }
  }

  submit = async (values) => {
    const { record, fetchMyConsults } = this.props
    const { patientname, age } = values;
    const OtherPatientName = patientname;
    const OtherPatientNameArr = OtherPatientName.toLowerCase().split(" ")
    const othersFirstName = OtherPatientNameArr[0]
    const othersLastName = OtherPatientNameArr[1]
    let consultDetails = {
      id: record.id,
      patientname: OtherPatientName,
      firstname: othersFirstName,
      lastname: othersLastName,
      age: age
    }
    // console.log("consultDetails ", consultDetails)
    try {
      const response = await updateConsult(consultDetails)
      await fetchMyConsults()
      this.setState({ showEditForm: false })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { record } = this.props
    const { showEditForm } = this.state;

    if (!record) {
      return (
        <div>
          <CircularProgress />
          <p>We're getting your things ready...</p>
        </div>
      )
    }

    const dateObj = new Date(record.createdAt)

    return (
      <div
        style={{
          minHeight: "60vh",
          color: "black",
          overflowX: "auto",
        }}
      >
        {showEditForm ? <div>
          <h1 style={{ display: "flex", color: "#3fcef3", marginBottom: 10, fontSize: "x-large" }}>
            Edit Consult Info <span style={{ display: "flex", alignItems: "center" }}>
              <Paper style={{ cursor: "pointer", backgroundColor: "#3fcef3", marginLeft: 5, display: "flex", padding: 5, color: "white" }} onClick={() => { this.setState({ showEditForm: false }) }}>
                <CancelIcon />
              </Paper></span>
          </h1>
          <Formik
            initialValues={{
              patientname: record.patientname || "",
              age: record.age
            }}
            onSubmit={this.submit}
            validationSchema={getValidationSchema()}
          >

            {(props) => (
              <form>
                <FastField
                  component={CustomTextComponent}
                  name="patientname"
                  label="Full name? eg: John Doe"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  // className={classes.field}
                  variant="outlined"
                  margin="normal"
                />
                <FastField
                  component={CustomNumberComponent}
                  name="age"
                  label="What is your age?"
                  inputProps={{
                    min: 0,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  // className={classes.field}
                  variant="outlined"
                  margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" onClick={props.handleSubmit}
                  disabled={props.isSubmitting ? true : false}>
                  {props.isSubmitting ? "Saving.." : "Save"}
                </Button>
              </form>
            )
            }
          </Formik>
        </div> :
          <div>
            <h1 style={{ display: "flex", color: "#3fcef3", marginBottom: 10, fontSize: "x-large" }}>
              Consult Info <span style={{ display: "flex", alignItems: "center" }}>
                <Paper style={{ cursor: "pointer", backgroundColor: "#3fcef3", marginLeft: 5, display: "flex", padding: 5, color: "white" }} onClick={() => { this.setState({ showEditForm: true }) }}>
                  <EditIcon />
                </Paper></span>
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span>{record.patientname ? record.patientname : "no name"}</span> /{" "}
                {record.age ? record.age : "no age"} / {record.gender}
              </div>
            </div>

            <div
              style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgMjEuMjI0di0xMi43NzJjMS4xMDYtLjU5NCAxLjY3NC0xLjc2MiAxLjY3NC0zLjEwNCAwLTEuODc0LTEuNDczLTMuNDExLTMuMzItMy41MTUtLjc2OC0xLjA2OC0yLjIyMS0xLjgzMy0zLjczOS0xLjgzMy0uOTcxIDAtMS43OC4zMjItMi41ODIuOTY0LTEuMjkyLS40MjItMi43NDctLjE0My0zLjc5NS43OTItMi4xNTUtLjM0Mi00LjIzOCAxLjI0NC00LjIzOCAzLjUwMSAwIDEuMzk2LjgxOSAyLjU4MSAyIDMuMTV2MTIuODE3YzAgLjY2NC0uMzM2IDEuMjAzLTEgMS4yMDN2MS41NzNoMTZ2LTEuNTczYy0uNjY0IDAtMS0uNTM5LTEtMS4yMDN6bS05LS4yMjRoLTJ2LTEyLjcxOWMuNjcyLjQyMiAxLjUxNi40MDYgMiAuMjY3djEyLjQ1MnptNy4xNTQtMTMuNzA3Yy0uNjI3IDAtMS4xODQtLjI5Ni0xLjUzOS0uNzU2bC0uNDUxLjMxNmMtMS41MjIgMS4xNzgtLjExMyAzLjMwMy0uMDEgNC4zMjQuMTAyLjk3Ny0uNTA1IDEuNzEyLTEuNDU4IDEuNzEyLS45MyAwLTEuNDc1LS43ODYtMS40MDEtMS43MTIuMDc4LS45NzggMS41NjItMi45MTgtLjA4My00LjQzOGwtLjYyMy0uNTY4Yy0uNDI1LjQ5Ny0xLjA1Ni44MTMtMS43NjEuODEzLS42MDUgMC0xLjE1NS0uMjMxLTEuNTY4LS42MTEtLjM1NC40ODctLjkyNy44MDQtMS41NzUuODA0LTIuNzE2IDAtMi44MTctMy44ODkgMC0zLjg4OS4zOTggMCAuNzcuMTIgMS4wNzcuMzI2LjM4NC0uNzUxIDEuMTYzLTEuMjY2IDIuMDY1LTEuMjY2LjU5MSAwIDEuMTMxLjIyMiAxLjU0LjU4Ni40OTQtLjgxNCAxLjM5LTEuMzU5IDIuNDEyLTEuMzU5IDEuMjU1IDAgMi4zMi44MiAyLjY4NSAxLjk1NS4yMTMtLjA4Mi40NDUtLjEyNi42ODktLjEyNiAxLjA3MiAwIDEuOTQzLjg3MSAxLjk0MyAxLjk0NHMtLjg2OSAxLjk0NS0xLjk0MiAxLjk0NXptNy44NDYgNS4xNTljMCAyLjUzOS0xLjc5MSA1Ljc1LTUgNi45NjN2LTIuMTZjMy4xNTQtMS44MyAzLjk2OS02LjI1NSAxLjU1My02LjI1NWgtMS41NTN2LTJoMS45MTJjMi4xNDQgMCAzLjA4OCAxLjUzNCAzLjA4OCAzLjQ1MnoiLz48L3N2Zz4="
                alt=""
              />
              <span style={{ marginLeft: 5 }}>
                {record.drinks_per_week ? record.drinks_per_week : 0} / week
          </span>

              <SmokingRooms style={{ marginLeft: 15, fontSize: 30 }} />
              <span style={{ marginLeft: 5 }}>
                {record.cigrattes_per_day ? record.cigrattes_per_day : 0} / day
          </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Created: </span>
              <span>
                {record.createdAt
                  ? `${dateObj.getMonth() +
                  1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
                  : null}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Allergy: </span>
              <span>{record.allergy ? record.allergy : "No info available"}</span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Medication: </span>
              <span>
                {record.medication ? record.medication : "No info available"}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>History of medical issues: </span>
              <span>
                {record.medical_issues
                  ? record.medical_issues
                  : "No info available"}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Current medical issue: </span>
              <span>
                {record.currentMedicalIssue
                  ? record.currentMedicalIssue
                  : "No info available"}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Surgeries: </span>
              <span>
                {record.surgeries ? record.surgeries : "No info available"}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Email: </span>
              <span>{record.email ? record.email : "No info available"}</span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Phone number: </span>
              <span>
                {record.phone_number
                  ? `+1${record.phone_number}`
                  : "No info available"}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              <span style={{ color: "#636e72" }}>Consult Status: </span>
              <span>
                {record.status}
              </span>
            </div>

            <div style={{ marginTop: 15 }}>
              {record.url1 ? (
                <AwsImageComponent
                  src={record.url1}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                      showImg: true,
                    })
                  }}
                />
              ) : null}
              {record.url2 ? (
                <AwsImageComponent
                  src={record.url2}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                      showImg: true,
                    })
                  }}
                />
              ) : null}
              {record.url3 ? (
                <AwsImageComponent
                  src={record.url3}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                      showImg: true,
                    })
                  }}
                />
              ) : null}
              {record.url4 ? (
                <AwsImageComponent
                  src={record.url4}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                      showImg: true,
                    })
                  }}
                />
              ) : null}
            </div>

            <div
              style={{
                height: "100%",
                width: this.state.imgUrl ? "100%" : "0%",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: 101,
                backgroundColor: "#2a2a2a",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.showImg && (
                <span
                  style={{
                    position: "absolute",
                    right: 15,
                    top: 15,
                    color: "white",
                    fontSize: 25,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      imgUrl: "",
                      showImg: false,
                    })
                  }
                >
                  X
                </span>
              )}
              {this.state.showImg && (
                <img
                  src={this.state.imgUrl}
                  alt=""
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                />
              )}
            </div>
          </div>}
      </div>
    )
  }
}

const CustomNumberComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      type="number"
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
    />
  )
}

const CustomTextComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <TextField
      id={field.name}
      name={field.name}
      type="text"
      helperText={touched[field.name] && errors[field.name]}
      error={touched[field.name] && errors[field.name] && true}
      label={props.label}
      {...field}
      {...props}
    />
  )
}

const getValidationSchema = () => {
  const regexp = XRegExp(
    "^[\\p{L}]([-.']?[ ]?[\\p{L}]+)*( [\\p{L}]([-.']?[ ]?[\\p{L}]+)*)+$"
  )

  let validationSchema = object({
    patientname: string("What is their full name? Eg: John Doe")
      .required("Name is requied")
      .matches(regexp, { message: "Please enter a valid full name" }),
    age: number("How old are you?")
      .required("Age is required")
      .min(0, "Age must be a positive number")
      .max(120, "Come on! Really?")
  })
  return validationSchema
}