import Appsync from "../config"
import { v4 as uuid } from "uuid"
import { Auth } from "aws-amplify"
import gql from "graphql-tag"

const client = Appsync()

const mutationCreateChatText = gql`
  mutation Create(
    $senderId: String!
    $receiverId: String!
    $senderRole: String!
    $consultId: String!
    $message: String
    $createdAt: String!
    $receiverRole: String!
    $seen: Boolean
  ) {
    createChat(
      senderRole: $senderRole
      createdAt: $createdAt
      receiverRole: $receiverRole
      senderId: $senderId
      consultId: $consultId
      receiverId: $receiverId
      message: $message
      seen: $seen
    ) {
      createdAt
      senderRole
      senderId
      message
    }
  }
`

export const createChatTextMsgMutation = async (
  message,
  consultId,
  receiverId,
  patientname,
  seenMessage
) => {
  const senderId = await Auth.currentSession().then(res => {
    return res.idToken.payload["sub"]
  })

  let seen = seenMessage || false

  const chatDetails = {
    senderId, // string
    senderRole: "patient",
    consultId,
    createdAt: new Date().toISOString(),
    message,
    receiverId,
    receiverRole: "doctor",
    seen,
    // name: patientname,
  }

  const updatedConsultChat = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateChatText,
      variables: chatDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsultChat
}

const mutationCreateChatImage = gql`
  mutation Create(
    $senderId: String!
    $receiverId: String!
    $senderRole: String!
    $consultId: String!
    $createdAt: String!
    $receiverRole: String!
    $seen: Boolean
    $file: S3ObjectInput
    $url: String
    $name: String
  ) {
    createChat(
      senderRole: $senderRole
      createdAt: $createdAt
      receiverRole: $receiverRole
      senderId: $senderId
      consultId: $consultId
      receiverId: $receiverId
      file: $file
      url: $url
      seen: $seen
      name: $name
    ) {
      createdAt
      senderRole
      senderId
      url
      file {
        region
        bucket
        key
      }
    }
  }
`

export const createChatImgMsgMutation = async (
  pictures,
  consultId,
  receiverId,
  patientname
) => {
  const { name, type: mimeType } = pictures[0]
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name)
  const key = [uuid(), extension].filter(x => !!x).join(".")

  const bucket = process.env.REACT_APP_BUCKET_NAME
  const url = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key}`
  const senderId = await Auth.currentSession().then(res => {
    return res.idToken.payload["sub"]
  })

  let seen = false

  const chatDetails = {
    senderId, // string
    senderRole: "patient",
    consultId,
    createdAt: new Date().toISOString(),
    receiverId,
    receiverRole: "doctor",
    seen,
    url,
    file: {
      region: "us-east-1",
      bucket,
      key: `public/${key}`,
      mimeType,
      localUri: pictures[0],
    },
    // name: patientname,
  }

  const updatedConsultChat = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateChatImage,
      variables: chatDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsultChat
}
