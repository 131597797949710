import { Auth } from "aws-amplify"
import AWSAppSyncClient from "aws-appsync"

export default () =>
  new AWSAppSyncClient({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    region: process.env.REACT_APP_REGION,
    disableOffline: true,
    auth: {
      type: process.env.REACT_APP_AUTHENTICATION_TYPE,
      apiKey: process.env.REACT_APP_APIKEY,
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
  })
