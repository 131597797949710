import React from "react"
import "./loader.css"

export default class Loader extends React.Component {
  render() {
    return (
      <div id="loading">
        <p id="loading-text">Loading</p>

        <div id="loading-anim" />

        <div id="loading-quote" />
      </div>
    )
  }
}
