import React, { Component } from "react"
import { Dashboard } from "@uppy/react"
import Uppy from "@uppy/core"
import Webcam from "@uppy/webcam"
import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "@uppy/webcam/dist/style.css"

import { withStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import ArrowRight from "@material-ui/icons/ArrowRight"
import ArrowLeft from "@material-ui/icons/ArrowLeft"
import Button from "@material-ui/core/Button"

const styles = theme => ({
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0px`,
  },
  btn: {
    color: "#36c2ea",
  },
  imageUpload: {
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  linearColorPrimary: {
    backgroundColor: "#45b2d2",
  },
  linearBarColorPrimary: {
    backgroundColor: "#36c2ea",
  },
})

class UppyDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disableNextBtn: true,
    }

    this.uppy = new Uppy({
      id: props.uppyId,
      autoProceed: false,
      restrictions: {
        maxFileSize: 52428800,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".gif", ".png", ".jpeg", "tiff"],
      },
      meta: {},
    }).use(Webcam, {
      id: "Webcam",
      countdown: false,
      modes: ["picture"],
      facingMode: "user",
      mirror: true,
      locale: {
        strings: {
          smile: "Smile!",
          takePicture: "Take a picture",
          allowAccessTitle: "Please allow access to your camera",
          allowAccessDescription:
            "In order to take pictures with your camera, please allow camera access for this site.",
        },
      },
    })

    this.uppy.on("file-added", file => {
      const finalImages = [...props.selectedImages, file]
      props.setSelectedImages(finalImages)
      this.setState({ disableNextBtn: false })
    })

    this.uppy.on("file-removed", file => {
      const { selectedImages, step } = this.props
      const finalImages = selectedImages.filter(
        (image, index) => index != step - 2
      )
      props.setSelectedImages(finalImages)
      this.setState({ disableNextBtn: true })
    })
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  render() {
    const {
      label,
      selectedImages,
      step,
      isSubmitting,
      submit,
      onContinuation,
      classes,
      totalFiles
    } = this.props
    const { disableNextBtn } = this.state

    return (
      <>
        <Dashboard
          uppy={this.uppy}
          plugins={["Webcam"]}
          metaFields={[{ id: "name", name: "Name", placeholder: "File name" }]}
          proudlyDisplayPoweredByUppy={false}
          hideUploadButton={true}
          width={"100%"}
          height={350}
          note={`${label}. \n\ Supported formats: .jpg, .gif, .png, .jpeg, tiff. Maximum Image size allowed is 10MB.`}
          metaFields={[]}
          locale={{
            strings: {
              dropPasteImport: label,
            },
          }}
        />

        {step != 1 && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.formActions}>
              <Button
                type="button"
                // onClick={() => this.setState({ step: step - 1 })}
                disabled={true}
                color="primary"
                className={classes.btn}
              >
                {""}
                {/* <ArrowLeft /> Back */}
              </Button>
              {selectedImages && selectedImages.length === totalFiles ? (
                <Button
                  type="button"
                  onClick={() => submit()}
                  disabled={isSubmitting}
                  color="primary"
                  className={classes.btn}
                  size="large"
                >
                  Submit <ArrowRight />
                </Button>
              ) : (
                <Button
                  type="button"
                  color="primary"
                  className={classes.continueBtn}
                  onClick={() => onContinuation()}
                  size="large"
                  disabled={disableNextBtn}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </>
    )
  }
}

export default withStyles(styles)(UppyDashboard)
