import React, { Component, Fragment } from "react"
import { Field } from "formik"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  field: {
    // margin: `${theme.spacing.unit * 2}px 0px`
  },
  formActions: {
    display: "flex",
    paddingBottom: 10,
  },
  divider: {
    // margin: `${theme.spacing.unit * 2}px 0px`
  },
  continueBtn: {
    marginLeft: "auto",
    color: "#36c2ea",
  },
  memberIdForm: {
    display: "flex",
    flexDirection: "column",
    margin: 15,
  },
  text: {
    marginTop: 10,
    marginBottom: 15,
  },
})

class MemeberIdInput extends Component {
  render() {
    const {
      classes,
      memberId,
      setMemberId,
      onContinuation,
      consultFor,
    } = this.props
    const btnDistabled = memberId && memberId != "" ? false : true
    // console.log(btnDistabled)
    return (
      <Fragment>
        <div className={classes.memberIdForm}>
          <Typography gutterBottom className={classes.text}>
            Please enter {consultFor === "others" ? "their" : "your"} insurance
            member id and then click continue to upload insurance details.
          </Typography>

          <TextField
            id="outlined-full-width"
            label="Insurance member id"
            // placeholder="Placeholder"
            // helperText="Full width!"
            // fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            required
            onChange={setMemberId}
            value={memberId}
          />
        </div>
        {/* <Divider className={classes.divider}/> */}
        <div className={classes.formActions}>
          <Button
            type="button"
            color="primary"
            className={classes.continueBtn}
            onClick={() => onContinuation()}
            size="large"
            disabled={btnDistabled}
          >
            Continue
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(MemeberIdInput)
