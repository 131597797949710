import React, { Component } from "react"
import { Redirect } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"

const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing.unit,
  },
})

class VideoCallList extends Component {
  render() {
    const {
      classes,
      allConsults,
      handleConsultUpdate,
      match,
      practiceDetails,
      queryValues,
      isFromDermio,
      isPracticeValid,
    } = this.props

    const selectedConsult = allConsults.find(
      consult => consult.id === match.params.consultId
    )

    if (!selectedConsult) {
      return (
        <Redirect
          to={`/patient/dashboard${
            isFromDermio
              ? "?from=dermio"
              : `?practiceId=${practiceDetails && practiceDetails.id}`
          }`}
        />
      )
    }

    return (
      <div>
        <ExpansionPanel elevation={5} defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Typography variant="caption">Important!</Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails
            className={classes.details}
          ></ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

export default withStyles(styles)(VideoCallList)
