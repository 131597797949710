import React from "react"
import ReactDOM from "react-dom"
import { createHashHistory } from "history"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import App from "./App"

import "./assets/css/dashboard.css"
require("dotenv").config()

const hist = createHashHistory()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* If patient hits /patient route on the rare case, an empty page will be shown */}
      {/* Trying to redirect from there is critically affecting the whole app*/}
      {/* So, don't add any new routes here until a bug fix can be found*/}
      {/* {Check commit} */}
      <Route path="/patient" component={App} />
      <Redirect from="/" to="/patient/dashboard" />
    </Switch>
  </Router>,
  document.getElementById("root")
)
