import React from "react"
import { Auth, Analytics } from "aws-amplify"
import { ConfirmSignUp } from "aws-amplify-react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"

import transformPhone from "../../utils/transformPhone"

import dermioLogo from "../../assets/img/dermio-logo.svg"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey["A700"],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  success: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "#009688",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
})

class CustomSignUp extends ConfirmSignUp {
  state = {
    error: "",
    success: "",
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: "",
      success: "",
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const PASSWORD = "mD@19911"
    const { signUpOtp } = this.inputs
    const { authData } = this.props

    const phNumber = transformPhone(authData.user.username)

    Auth.confirmSignUp(phNumber, signUpOtp.trim(), {
      forceAliasCreation: true,
    })
      .then(data => {
        this.setState({
          success:
            "Great! Your number is verified. Please wait while I log you in!",
          error: "",
        })

        Auth.signIn(phNumber, PASSWORD)
          .then(user => {
            Auth.setPreferredMFA(user, "SMS")
              .then(res => {
                this.changeState("signedIn", res)
                this.setState({
                  error: "",
                  success: "",
                })
              })
              .catch(err => {
                console.error(err)
                this.setState({
                  error: "Something went wrong. Please try again!",
                  success: "",
                })
              })
          })
          .catch(err => {
            console.error(err)
          })
      })
      .catch(err => {
        console.error(err)
        if (err.code === "ExpiredCodeException") {
          this.setState({
            error: "Your 6-digit code has expired. Please try again!",
            success: "",
          })
        } else {
          this.setState({
            error: "Please enter the correct 6-digit verification code",
            success: "",
          })
        }
      })
  }

  render() {
    const { classes, authState } = this.props
    if (authState === "confirmSignUp") {
      return (
        <div className={classes.main}>
          <Card className={classes.card} id="confirmSignUp">
            <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className={classes.hint}>VERIFY YOUR ACCOUNT</div>
              {this.state.error && (
                <div className={classes.error}>{this.state.error}</div>
              )}
              {this.state.success && (
                <div className={classes.success}>{this.state.success}</div>
              )}
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="signUpOtp"
                    name="signUpOtp"
                    label="6-digit verification code"
                    margin="normal"
                    type="text"
                    helperText="Enter the 6-digit verification code sent to your phone"
                    fullWidth={true}
                    required
                    error={this.state.error ? true : false}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className={classes.button}
                  fullWidth
                  onChange={this.handleInputChange}
                  onClick={() => {
                    const { authData } = this.props

                    const phNumber = transformPhone(authData.user.username)
                    Auth.resendSignUp(phNumber)
                      .then(res => {
                        return this.setState({
                          success:
                            "Please enter the new code sent to your phone!",
                          error: "",
                        })
                      })
                      .catch(err => {
                        console.error(err)
                      })
                  }}
                >
                  Resend code
                </Button>
                <Button
                  id="signUpSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  onChange={this.handleInputChange}
                >
                  Submit
                </Button>
              </CardActions>
            </form>
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomSignUp)
