import Amplify, { Analytics } from "aws-amplify"
import Storage from "@aws-amplify/storage"

export default () => {
  const REACT_APP_IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID
  const REACT_APP_AMPLIFY_REGION = process.env.REACT_APP_AMPLIFY_REGION
  const REACT_APP_USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID
  const REACT_APP_USERPOOL_WEB_CLIENT_ID =
    process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID
  const REACT_APP_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME
  const REACT_APP_BUCKET_REGION = process.env.REACT_APP_BUCKET_REGION
  const REACT_APP_AMAZON_PINPOINT_ID = process.env.REACT_APP_AMAZON_PINPOINT_ID
  const REACT_APP_AMAZON_PINPOINT_REGION =
    process.env.REACT_APP_AMAZON_PINPOINT_REGION

  Amplify.configure({
    Auth: {
      identityPoolId: REACT_APP_IDENTITY_POOL_ID,
      region: REACT_APP_AMPLIFY_REGION,
      userPoolId: REACT_APP_USERPOOL_ID,
      userPoolWebClientId: REACT_APP_USERPOOL_WEB_CLIENT_ID,
    },
    Storage: {
      bucket: REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_BUCKET_REGION,
    },
  })

  Storage.configure({
    bucket: REACT_APP_BUCKET_NAME,
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_BUCKET_REGION,
    userPoolId: REACT_APP_USERPOOL_ID,
    userPoolWebClientId: REACT_APP_USERPOOL_WEB_CLIENT_ID,
  })

  const analyticsConfig = {
    AWSPinpoint: {
      appId: REACT_APP_AMAZON_PINPOINT_ID,
      region: REACT_APP_AMAZON_PINPOINT_REGION,
      mandatorySignIn: false,
    },
  }

  if (process.env.NODE_ENV !== "development") {
    Analytics.configure(analyticsConfig)
  }
}
