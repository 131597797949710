import React, { useState, useEffect, Fragment } from "react"
import { Redirect } from "react-router-dom"

import moment from "moment"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import Chip from "@material-ui/core/Chip"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import ModalDialog from "../Modal/ModalDialog"
import Snackbar from "@material-ui/core/Snackbar"
import Slide from "@material-ui/core/Slide"

import fetchDoctorAppointments from "../../queries/fetchDoctorAppointments"
import fetchDoctorAvailability from "../../queries/fetchDoctorAvailability"
import createVideoCallSchedule from "../../queries/createVideoCallSchedule"
import fetchDoctorUnavailability from "../../queries/fetchDoctorUnavailability"
import getCurrentTimezoneName from "../../utils/getCurrentTimezoneName"

const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing.unit,
  },
})

const ScheduleVideoCall = props => {
  const [loading, setLoading] = useState(true)
  const [doctorAvailabilities, setDoctorAvailabilites] = useState([])
  const [selectedDayAvailability, setSelectedDayAvailability] = useState([])
  const [date, changeDate] = useState()
  const [time, setTime] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const [allDoctorAppointments, setAllDoctorAppointments] = useState([])
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [doctorUnavailabilities, setDoctorUnavailabilites] = useState([])

  const {
    classes,
    allConsults,
    handleConsultUpdate,
    match,
    practiceDetails,
    queryValues,
    isFromDermio,
    isPracticeValid,
  } = props

  const selectedConsult = allConsults.find(
    consult => consult.id === match.params.consultId
  )

  useEffect(() => {
    const { match, allConsults } = props
    const selectedConsult = allConsults.find(
      consult => consult.id === match.params.consultId
    )
    if (selectedConsult) {
      // Fetch Doctor availability days
      fetchDoctorAvailability({ doctorId: selectedConsult.doctorId })
        .then(response => {
          const {
            data: { allAvailabilities },
          } = response

          if (
            allAvailabilities &&
            allAvailabilities.items &&
            allAvailabilities.items.length
          ) {
            // console.log(allAvailabilities.items)

            // Fetch Doctor unavailability days
            fetchDoctorUnavailability({ doctorId: selectedConsult.doctorId })
              .then(response => {
                const {
                  data: { allUnavailabilities },
                } = response

                if (
                  allUnavailabilities &&
                  allUnavailabilities.items &&
                  allUnavailabilities.items.length
                ) {
                  // console.log(allUnavailabilities.items)
                  setDoctorUnavailabilites(allUnavailabilities.items)
                  setDoctorAvailabilites(allAvailabilities.items)
                  setLoading(false)
                  onDateChange(
                    moment(),
                    allAvailabilities.items,
                    allUnavailabilities.items
                  )
                } else {
                  setDoctorAvailabilites(
                    allAvailabilities.items,
                    allUnavailabilities.items
                  )
                  setLoading(false)
                  onDateChange(
                    moment(),
                    allAvailabilities.items,
                    allUnavailabilities.items
                  )
                }
              })
              .catch(console.log)
          } else {
            setLoading(false)
          }
        })
        .catch(console.log)
    }
  }, [])

  const onDateChange = (date, availabilities, unavailabilities) => {
    // console.log(date)
    // console.log(availabilities)
    // console.log(unavailabilities)
    // get selected date "Day" name and fetch particular day data from Doctor availability array.
    changeDate(date)
    const selectedDay = moment(date).format("dddd")
    let availabilityOnSelectedDay = availabilities.filter(
      availability => availability.day === selectedDay
    )
    let unavailabilityDateCheck = unavailabilities.filter(
      unavailability =>
        moment(unavailability.datetime).format("L") === moment(date).format("L")
    )

    if (unavailabilityDateCheck && unavailabilityDateCheck.length) {
      availabilityOnSelectedDay = []
      // availabilityOnSelectedDay = availabilityOnSelectedDay.map((availability)=>{
      //   const availabilityNew = availability;
      //   console.log("availability.slots ",availability.slots)
      //   availabilityNew.slots = availability.slots.filter((slot)=>{
      //     let isSlotAvailable = unavailabilityDateCheck.map((unavailability)=>{
      //       console.log(slot.from);
      //       console.log(unavailability.slots);
      //       return slot.from != unavailability.slots.from;
      //     });
      //     console.log(isSlotAvailable)
      //     if(isSlotAvailable && isSlotAvailable.length && isSlotAvailable[0]){
      //       return slot;
      //     }
      //   });
      //   return availabilityNew;
      // });

      // console.log("new ",availabilityOnSelectedDay)

      // if(availabilityOnSelectedDay && availabilityOnSelectedDay.length && availabilityOnSelectedDay[0].slots && availabilityOnSelectedDay[0].slots.length ){
      //   availabilityOnSelectedDay = availabilityOnSelectedDay;
      // }else{
      //   availabilityOnSelectedDay = [];
      // }
    }
    getDoctorAppoinments(
      moment(date).format("DD/MM/YYYY"),
      availabilityOnSelectedDay
    )
  }

  const getDoctorAppoinments = (date, availabilityOnSelectedDay) => {
    // Fetch doctor Appoinments
    fetchDoctorAppointments({
      doctorId: selectedConsult.doctorId,
      scheduledDateString: date,
    })
      .then(response => {
        const {
          data: { allVideoCalls },
        } = response
        // console.log(allVideoCalls)
        if (
          allVideoCalls &&
          allVideoCalls.items &&
          allVideoCalls.items.length
        ) {
          setAllDoctorAppointments(allVideoCalls.items)
          setSelectedDayAvailability(availabilityOnSelectedDay)
        } else {
          setAllDoctorAppointments([])
          setSelectedDayAvailability(availabilityOnSelectedDay)
        }
      })
      .catch(console.log)
  }

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />
  }

  const getSlots = (from, to) => {
    let fromTime = moment(from).subtract(15, "minutes")
    const toTime = moment(to).subtract(15, "minutes")
    const slots = []
    // Loop throught between from and to time
    //creating new array of 15 minutes slots
    for (
      fromTime;
      moment(fromTime).unix() < moment(toTime).unix();
      fromTime = moment(fromTime).add(15, "minutes")
    ) {
      slots.push(moment(fromTime).add(15, "minutes"))
    }
    // console.log(slots)
    return (
      <ul>
        <li>
          {" "}
          {moment(from).format("LT")}-{moment(to).format("LT")}
        </li>
        {slots.map(slot => {
          const isTimeTaken = allDoctorAppointments.filter(
            appoinment =>
              moment(appoinment.scheduledDateTime).format("LT") ===
              moment(slot).format("LT")
          )
          // console.log("isTimeTaken ",isTimeTaken)
          if (isTimeTaken && isTimeTaken.length) {
            return (
              <Chip
                key={slot}
                style={{ margin: 5 }}
                label={moment(slot).format("LT")}
              />
            )
          } else {
            return (
              <Chip
                key={slot}
                style={{ margin: 5 }}
                color="primary"
                label={moment(slot).format("LT")}
                onClick={() => {
                  setTime(moment(slot))
                  setModalOpen(true)
                }}
              />
            )
          }
        })}
      </ul>
    )
  }

  const handleConfirmation = () => {
    // Concatinate selected date with selected time.
    const hours = moment(time).hours()
    const minutes = moment(time).minutes()
    const finalDate = moment(date).set({ hours, minutes })

    // console.log(moment(finalDate).format("LLLL"))
    // console.log("selectedConsult ",selectedConsult)
    const variables = {
      patientId: selectedConsult.patientId,
      consultId: selectedConsult.id,
      doctorId: selectedConsult.doctorId,
      scheduledDateTime: finalDate,
      scheduledDateString: moment(finalDate).format("DD/MM/YYYY"),
      consultName: selectedConsult.patientname,
    }
    createVideoCallSchedule(variables)
      .then(result => {
        if (result.data.createVideoCall.id) {
          setModalOpen(false)
          setSnackbarOpen(true)
          // localStorage.setItem("isVideocallConsult", false)
        }
      })
      .catch(console.log)
  }

  if (!selectedConsult) {
    return (
      <Redirect
        to={`/patient/dashboard${
          isFromDermio
            ? "?from=dermio"
            : `?practiceId=${practiceDetails && practiceDetails.id}`
        }`}
      />
    )
  }

  if (loading) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="caption">
            Searching for available video call list. Please wait......
          </Typography>
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary,
            }}
          />
        </CardContent>
      </Card>
    )
  }

  if (doctorAvailabilities && doctorAvailabilities.length === 0) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="caption">
            Doctor has to update availability status for videocall.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardHeader
          title="Schedule VideoCall"
          subheader="Please select the date and available slot"
        />
        <CardContent>
          <Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  orientation="landscape"
                  variant="static"
                  openTo="date"
                  disablePast={true}
                  value={date}
                  onChange={date => {
                    onDateChange(
                      date,
                      doctorAvailabilities,
                      doctorUnavailabilities
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
              <Card className={classes.card} style={{ maxWidth: "410px" }}>
                <CardHeader
                  title="Doctor availability"
                  subheader={`Please select from the available slots - ${getCurrentTimezoneName()}`}
                />
                <CardContent>
                  {selectedDayAvailability && selectedDayAvailability.length ? (
                    selectedDayAvailability[0].slots.map(slot => {
                      // <li> {moment(slot.from).format("LT")}-{moment(slot.to).format("LT")}</li>
                      return getSlots(slot.from, slot.to)
                    })
                  ) : (
                    <ul>
                      <li>Doctor is not available on the selected day.</li>
                    </ul>
                  )}
                </CardContent>
              </Card>
            </div>
          </Fragment>
        </CardContent>
      </Card>
      <ModalDialog
        open={isModalOpen}
        handleClose={() => {
          setModalOpen(false)
        }}
        handleConfirmation={handleConfirmation}
        alertMsg={"Confirm videocall date and time"}
        additionalContent={`${moment(date).format("LL")} ${moment(time).format(
          "LT"
        )}`}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => {
          props.history.push(`/patient/consults/${selectedConsult.id}`)
          setSnackbarOpen(false)
        }}
        TransitionComponent={SlideTransition}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            Consult time confirmed. Waiting for Doctor's confirmation!
          </span>
        }
      />
    </Fragment>
  )
}

export default withStyles(styles)(ScheduleVideoCall)
