import Appsync from "../config"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query fetchDoctor($doctorId: ID!) {
    doctor: Doctor(id: $doctorId) {
      id
      name
      firstName
      lastName
      phone
      email
      bio
      enabled
      file
      licensedStates
      onVacation
      startVacationDate
      endVacationDate
      videocallFee
      disableVideoCall
      isAssistant
	    assistantTitle
    }
  }
`

const fetchDoctor = async variables => {
  // console.log("variables ", variables)
  const result = await client.hydrated().then(function (cl) {
    return cl
      .query({ query, variables, fetchPolicy: "network-only" })
      .then(function logData(data) {
        return data
      })
  })
  return result
}

export default fetchDoctor
